import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

export function RedStar(props) {
  return <span style={{color: 'red'}} {...props}>*</span>
}

export function AppHint ({children, text}) {
  return (
    <Tooltip title={text}>
      {children || <QuestionCircleFilled style={{color: "rgba(0, 0, 0, 0.35)", fontSize: '.9em', margin: '0px 5px'}} />}
    </Tooltip>
  )
}

// export default {RedStar}