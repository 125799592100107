import React from 'react';
import { Row, Col, Tooltip, message } from 'antd';
import {version} from '../../../package.json';
import CONFIG from '../service/config'
import { 
  GlobalOutlined, 
  MailOutlined,
  PhoneOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  FacebookOutlined
 } from '@ant-design/icons';


export function AppFooter(props) {

  const handleWindow = (name, value) => {
    console.log(name)
    if (name === 'web') {
      window.open(`https://${value}`)
    }else if (name === 'mail') {
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`)
    }else if (name === 'phone') {
      handlePhoneClip(value)
    }else if (name === 'instaOne') {
      window.open(`https://www.instagram.com/${value}/`)
    }else if (name === 'instaTwo') {
      window.open(`https://www.instagram.com/${value}/`)
    }else if (name === 'whatApps') {
      window.open(`https://api.whatsapp.com/send?phone=62${value}`)
    }else {
      window.open(`https://www.facebook.com/KoleseLoyola`)
    }
  }

  const handlePhoneClip = data => {
    let copyText = document.getElementById("phoneLoyola");
    copyText.select();
    copyText.setSelectionRange(0, 99999); 
    document.execCommand("copy");
    message.info('Copied phone Number: '+ data)
  }

  return (
    <div className="app-footer">
      <div className="container-footer"> 
        <Row>
          <Col span={8} xs={24} sm={8}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <span style={{ fontWeight: 'bold', marginRight: 5}}>
                Anda butuh bantuan pengisian pendaftaran PPDB Online?
                </span>
                <br />
                <span style={{ fontWeight: 'bold', marginRight: 5}}>Hubungi kami di WA berikut: </span>
                <Tooltip placement='bottom' title='0812-2623-5080'>
                  <WhatsAppOutlined 
                    onClick={() => handleWindow('whatApps', '812-2623-5080')} 
                    style={{ marginRight: '10px', marginLeft: '5px'}} 
                  />
                  <span 
                    onClick={() => handleWindow('whatApps', '812-2623-5080')}
                    style={{ cursor: 'pointer'}}
                  >
                    <b>
                      Customer Service
                    </b>
                  </span>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col span={8} xs={24} sm={8}>
          </Col>
          <Col span={8} xs={24} sm={8}>
            <Row>
              <Col span={24} style={{ textAlign: "end", lineHeight: '2'}}>
                <span style={{ fontWeight: 'bold', fontSize: '16px'}}>
                  Informasi Lebih Lengkap : &nbsp;&nbsp;&nbsp;
                </span>
                <Tooltip placement="bottom" title='www.loyola-smg.sch.id'>
                  <GlobalOutlined 
                    onClick={() => handleWindow('web', 'www.loyola-smg.sch.id')} 
                    style={{ marginRight: '10px', cursor: 'pointer'}} 
                  />
                </Tooltip>
                <Tooltip placement="bottom" title='koleseloyola@loyola-smg.sch.id'>
                  <MailOutlined 
                    onClick={() => handleWindow('mail', 'koleseloyola@loyola-smg.sch.id')} 
                    style={{ marginRight: '10px', cursor: 'pointer'}} 
                  />
                </Tooltip>
                <Tooltip placement="bottom" title='024 354 8431 / 354 6945 ext.555'>
                  <input 
                    id="phoneLoyola"
                    defaultValue='0243548431 / 3546945 ext.555'
                    type='text'
                    style={{ position: 'absolute', left: '-999em'}}
                  />
                  <PhoneOutlined
                    onClick={() => handleWindow('phone', '0243548431 / 3546945 ext.555')} 
                    style={{ marginRight: '10px', cursor: 'pointer'}} 
                  />
                </Tooltip>
                <Tooltip placement="bottom" title='@yayasan.loyola'>
                  <InstagramOutlined
                  onClick={() => handleWindow('instaOne', 'yayasan.loyola')} 
                  style={{ marginRight: '10px', cursor: 'pointer'}} 
                  />
                </Tooltip>
                <Tooltip placement="bottom" title='@smakoleseloyola'>
                  <InstagramOutlined 
                    onClick={() => handleWindow('instaTwo', 'smakoleseloyola')} 
                    style={{ marginRight: '10px', cursor: 'pointer'}} 
                  />
                </Tooltip>
                <Tooltip placement="bottom" title='0812 2623 5080'>
                  <WhatsAppOutlined 
                    onClick={() => handleWindow('whatApps', '81226235080')} 
                    style={{ marginRight: '10px', cursor: 'pointer'}} 
                  />
                </Tooltip>
                <Tooltip placement="bottom" title='Kolese Loyola'>
                  <FacebookOutlined 
                    onClick={() => handleWindow('facebook', 'KoleseLoyola')}
                    style={{ cursor: 'pointer'}} 
                  />
                </Tooltip>
              </Col>
              <Col span={24} style={{ textAlign: 'right'}}>
                <span style={{ fontWeight: 'bold'}}>
                  Jam Operasional: 07.30 - 12.30
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className='footerDivider' />
      <div className="container-footer">
        <Row>
          <Col span={8}>
          </Col>
          <Col span={8} style={{textAlign: 'center', alignItems: 'center', alignSelf: 'center' }}>
            <span>&copy; 2020 Kolese Loyola. {CONFIG.isServer !== 'Production' && CONFIG.isServer }</span>
          </Col>
          <Col span={8} style={{ alignItems: 'center', textAlign: 'right', alignSelf: 'center' }}>
            <span>Build {version}</span>
          </Col>
        </Row>
      </div>
    </div>
  )
}

// export default {RedStar}