import { confirmDialog } from "./alert";

export function reloadPage(err) {
  if(err.response){
    var data = err.response.data
    confirmDialog(
      {
        icon: 'error', 
        text: 'Terjadi Kesalahan pada server', 
        buttons: {
        confirm: {
          text: "Reload",
          value: true,
          visible: true,
          className: 'app-btn primary'
        },
        cancel: {
          text: "Gagal",
          value: false,
          visible: true,
          className: 'app-btn danger'
        }
      }}).then(() => {
      console.log('errornya adalah ',data.error)
      if(data.error == "Unauthorized"){
        window.location.reload(false);
      }
    })
  }
}