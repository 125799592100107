import React from 'react'
import { Row, Col, Card } from 'antd';

function Simulasi(props) {

  let isPotBiayaUps = props.dataSimulasi.potBiaya || props.dataSimulasi.potBiayaUps || props.dataSimulasi.potBiayaUs ? true : false
  let isUpsSetelahPot = props.dataSimulasi.potBiaya || props.dataSimulasi.upsSetelahPot || props.dataSimulasi.usSetelahPot ? true : false

  const dataSimulasi = {
    isPotBiayaUps: isPotBiayaUps,
    isUpsSetelahPot: isUpsSetelahPot,
    standarUps: props.dataSimulasi.standarUps !== '-' ? 'Rp. ' + props.dataSimulasi.standarUps + ',-' : '-',
    standarUs: props.dataSimulasi.standarUs !== '-' ? 'Rp. ' + props.dataSimulasi.standarUs + ',-' : '-',
    potBiayaUps: props.dataSimulasi.potBiayaUps ? 'Rp. ' + props.dataSimulasi.potBiayaUps + ',-' : '-',
    potBiayaUs: props.dataSimulasi.potBiayaUs ? 'Rp. ' + props.dataSimulasi.potBiayaUs + ',-' : '-',
    upsSetelahPot: props.dataSimulasi.upsSetelahPot ?  'Rp. ' + props.dataSimulasi.upsSetelahPot + ',-' : '-',
    usSetelahPot: props.dataSimulasi.usSetelahPot ? 'Rp. ' + props.dataSimulasi.usSetelahPot + ',-' : '-',
    diskonBeasiswa: props.dataSimulasi.diskonBeasiswa !== '-' ? 'Rp. ' + props.dataSimulasi.diskonBeasiswa + ',-' : '-',
    diskonBeasiswaUs: props.dataSimulasi.diskonBeasiswaUs !== '-' ? 'Rp. ' + props.dataSimulasi.diskonBeasiswaUs + ',-' : '-',
    diskonTambahan: props.dataSimulasi.diskonTambahan !== '-' ? 'Rp. ' + props.dataSimulasi.diskonTambahan + ',-' : '-',
    diskonTambahanUs: props.dataSimulasi.diskonTambahanUs !== '-' ? 'Rp. ' + props.dataSimulasi.diskonTambahanUs + ',-' : '-',
    upsFinal: props.dataSimulasi.upsFinal !== '-' ? 'Rp. ' + props.dataSimulasi.upsFinal + ',-' : '-',
    usFinal: props.dataSimulasi.usFinal !== '-' ? 'Rp. ' + props.dataSimulasi.usFinal + ',-' : '-',
    upsKontan: props.dataSimulasi.upsKontan ? 'Rp. ' + props.dataSimulasi.upsKontan + ',-' : '-',
    usKontan: props.dataSimulasi.usKontan ? 'Rp. ' + props.dataSimulasi.usKontan + ',-' : '-',
    
    nameDiskonALL: props.dataSimulasi.potBiaya ? props.dataSimulasi.potBiaya : '-',
    nameDiskonBeasiswa: props.dataSimulasi.beasiswa ? props.dataSimulasi.beasiswa : '-',
    namaDiskonTambahan : props.dataSimulasi.namaDiskonTambahan ? props.dataSimulasi.namaDiskonTambahan : '-'
  }
  const dataPotongan = props.dataSimulasi.dataPotonganBiaya ? props.dataSimulasi.dataPotonganBiaya : [] 
  
  const IsMobileView = () => {
    return (
      <Row>
        <Col span={24}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            Nilai Komitmen Standar
          </span>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                UPS
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.standarUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                US
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.standarUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        {/* {
          dataSimulasi.isPotBiayaUps && 
          <Row>
            <Col span={24}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.nameDiskonALL}
              </span>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span className='text-header-finance'>
                    UPS
                  </span>
                </Col>
                <Col span={18} style={{textAlign: 'end'}}>
                  <span 
                    className='text-header-finance' 
                    style={{ fontWeight: 'normal'}}
                  >
                    {dataSimulasi.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span className='text-header-finance'>
                    US
                  </span>
                </Col>
                <Col span={18} style={{textAlign: 'end'}}>
                  <span 
                    className='text-header-finance' 
                    style={{ fontWeight: 'normal'}}
                  >
                    {dataSimulasi.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        {
          dataSimulasi.isUpsSetelahPot && 
          <Row style={{marginTop: 30}}>
            <Col span={24}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                Nilai Komitmen ( setelah {dataSimulasi.nameDiskonALL} )
              </span>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span className='text-header-finance'>
                    UPS
                  </span>
                </Col>
                <Col span={18} style={{textAlign: 'end'}}>
                  <span 
                    className='text-header-finance' 
                    style={{ fontWeight: 'normal', color: '#072A6E'}}
                  >
                    {dataSimulasi.upsSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <span className='text-header-finance'>
                    US
                  </span>
                </Col>
                <Col span={18} style={{textAlign: 'end'}}>
                  <span 
                    className='text-header-finance' 
                    style={{ fontWeight: 'normal', color: '#072A6E'}}
                  >
                    {dataSimulasi.usSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        } */}
        <Col span={24} style={{marginTop: 30}}>
          <span 
            className='text-header-finance' 
            style={{ color: '#000000'}}
            >
            Potongan : 
          </span>
        </Col>
        {dataPotongan.length > 0 ? 
          (
            dataPotongan.map((res,index) => (
              (dataPotongan.length == index + 1) ?
              <>
                <Col span={24} style={{marginTop: 20}}>
                  <span 
                    className='text-header-finance' 
                    style={{ fontWeight: 'normal', color: '#072A6E'}}
                  >
                    {res.potBiaya}:
                  </span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <span className='text-header-finance'>
                        UPS
                      </span>
                    </Col>
                    <Col span={18} style={{textAlign: 'end'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', color: '#072A6E'}}
                      >
                        {res.potBiayaUps !== 0 && 'Rp. '} 
                        {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUps !== 0 && ',-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <span className='text-header-finance'>
                        US
                      </span>
                    </Col>
                    <Col span={18} style={{textAlign: 'end'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', color: '#072A6E'}}
                      >
                        {res.potBiayaUs !== 0 && 'Rp. '} 
                        {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUs !== 0 && ',-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </>:
              <>
                <Col span={24}>
                  <span 
                    className='text-header-finance' 
                    style={{ fontWeight: 'normal'}}
                  >
                    • {res.potBiaya}:
                  </span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <span className='text-header-finance'>
                        UPS
                      </span>
                    </Col>
                    <Col span={18} style={{textAlign: 'end'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {res.potBiayaUps !== 0 && 'Rp. '} 
                        {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUps !== 0 && ',-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <span className='text-header-finance'>
                        US
                      </span>
                    </Col>
                    <Col span={18} style={{textAlign: 'end'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {res.potBiayaUs !== 0 && 'Rp. '} 
                        {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUs !== 0 && ',-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </>
            ))
          )
          : 
          (
            <Col span={24}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                • Tidak ada potongan
              </span>
            </Col>
          )
        }
        <Col span={24} style={{marginTop: 30}}>
          <span 
            className='text-header-finance' 
            style={{ color: '#000000'}}
          >
            Beasiswa :
          </span>
        </Col>
        <Col span={24}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            • Beasiswa Utama: {dataSimulasi.nameDiskonBeasiswa}
          </span>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                UPS
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.diskonBeasiswa.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                US
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.diskonBeasiswaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            • Beasiswa Tambahan: {dataSimulasi.namaDiskonTambahan}
          </span>
          </Col>
          <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                UPS
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.diskonTambahan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
          </Col>
          <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                US
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                {dataSimulasi.diskonTambahanUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{marginTop: 30}}>
          <span 
            className='text-header-finance' 
            style={{ color: '#000000'}}
          >
            Nilai Komitmen ( Apabila Beasiswa Disetujui )
          </span>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                UPS
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ textAlign: 'right', color: '#000000' }}
              >
                {dataSimulasi.upsFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                US
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ textAlign: 'right', color: '#000000' }}
              >
                {dataSimulasi.usFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{marginTop: 30}}>
          <span 
            className='text-diskon' 
          >
            "Apabila pembayaran UPS dilunasi di awal, maka siswa mendapatkan diskon tambahan sebesar 5%"
          </span>
        </Col>
        {/* <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                UPS
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal', color: '#072A6E'}}
              >
                {dataSimulasi.upsKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col> */}
        {/* <Col span={24}>
          <Row>
            <Col span={6}>
              <span className='text-header-finance'>
                US
              </span>
            </Col>
            <Col span={18} style={{textAlign: 'end'}}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal', color: '#072A6E'}}
              >
                {dataSimulasi.usKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
              </span>
            </Col>
          </Row>
        </Col> */}
      </Row>
    )
  }

  const IsDesktopView = () => {
    return(
      <Row>
        <Col span={12}>
        </Col>
        <Col span={6} style={{ textAlign: 'center' }}>
          <span className='text-header-finance'>
            UPS
          </span>
        </Col>
        <Col span={6} style={{ textAlign: 'center' }}>
          <span className='text-header-finance'>
            US
          </span>
        </Col>
        <Col span={12}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            Nilai Komitmen Standar
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            {dataSimulasi.standarUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            {dataSimulasi.standarUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        {/* {
          dataSimulasi.isPotBiayaUps && 
          <Col span={24}>
            <Row>
              <Col span={12}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {dataSimulasi.nameDiskonALL}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {dataSimulasi.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {dataSimulasi.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
            </Row>
          </Col>
        }
        {
          dataSimulasi.isUpsSetelahPot && 
          <Col span={24}>
            <Row>
              <Col span={12} style={{ margin: '20px 0px'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal', color: '#072A6E', margin: '20px, 0'}}
                >
                  Nilai Komitmen ( setelah {dataSimulasi.nameDiskonALL} )
                </span>
              </Col>
              <Col span={6} style={{ margin: '20px 0px', textAlign: 'right'  }}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal', color: '#072A6E'}}
                >
                  {dataSimulasi.upsSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{ margin: '20px 0px', textAlign: 'right'  }}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal', color: '#072A6E'}}
                >
                  {dataSimulasi.usSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
            </Row>
          </Col>
        } */}
        <Col span={24}>
          <span 
            className='text-header-finance' 
            style={{ color: '#000000'}}
          >
            Potongan : 
          </span>
        </Col>
        {dataPotongan.length > 0 ? 
          (
            dataPotongan.map((res,index) => (
              (dataPotongan.length == index + 1) ?
                  <>
                  <Col span={24} style={{margin: '20px 0px'}}>
                    <Row>
                      <Col span={12}>
                        <span 
                          className='text-header-finance' 
                          style={{ fontWeight: 'normal', color: '#072A6E'}}
                        >
                          {res.potBiaya}:
                        </span>
                      </Col>
                      <Col span={6} style={{textAlign: 'right'}}>
                        <span 
                          className='text-header-finance' 
                          style={{ fontWeight: 'normal', color: '#072A6E'}}
                        >
                          {res.potBiayaUps !== 0 && 'Rp. '} 
                          {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                          {res.potBiayaUps !== 0 && ',-'}
                        </span>
                      </Col>
                      <Col span={6} style={{textAlign: 'right'}}>
                        <span 
                          className='text-header-finance' 
                          style={{ fontWeight: 'normal', margin: '20px 0px', color: '#072A6E'}}
                        >
                          {res.potBiayaUs !== 0 && 'Rp. '} 
                          {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                          {res.potBiayaUs !== 0 && ',-'}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  </> :
                  <>
                    <Col span={12}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        • {res.potBiaya}:
                      </span>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {res.potBiayaUps !== 0 && 'Rp. '} 
                        {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUps !== 0 && ',-'}
                      </span>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {res.potBiayaUs !== 0 && 'Rp. '} 
                        {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUs !== 0 && ',-'}
                      </span>
                    </Col>
                  </>
            ))
          )
          : 
          (
            <Col span={24}>
              <span 
                className='text-header-finance' 
                style={{ fontWeight: 'normal'}}
              >
                • Tidak ada potongan
              </span>
            </Col>
          )
        }
        <Col span={12}>
          <span 
            className='text-header-finance' 
            style={{ color: '#000000'}}
          >
            Beasiswa :
          </span>
        </Col>
        <Col span={6}>
        </Col>
        <Col span={6}>
        </Col>
        <Col span={12}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            • Beasiswa Utama: {dataSimulasi.nameDiskonBeasiswa}
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            {dataSimulasi.diskonBeasiswa.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            {dataSimulasi.diskonBeasiswaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={12}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            • Beasiswa Tambahan: {dataSimulasi.namaDiskonTambahan}
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            {dataSimulasi.diskonTambahan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal'}}
          >
            {dataSimulasi.diskonTambahanUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={12} style={{ margin: '20px 0px' }}>
          <span 
            className='text-header-finance' 
            style={{ color: '#000000'}}
          >
            Nilai Komitmen ( Apabila Beasiswa Disetujui )
          </span>
        </Col>
        <Col span={6} style={{ margin: '20px 0px', textAlign: 'right' }}>
          <span 
            className='text-header-finance' 
            style={{ textAlign: 'right', color: '#000000' }}
          >
            {dataSimulasi.upsFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={6} style={{ margin: '20px 0px', textAlign: 'right' }}>
          <span 
            className='text-header-finance' 
            style={{ textAlign: 'right', color: '#000000' }}
          >
            {dataSimulasi.usFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={24}>
          <span 
            className='text-diskon'
          >
            "Apabila pembayaran UPS dilunasi di awal, maka siswa mendapatkan diskon tambahan sebesar 5%"
          </span>
        </Col>
        {/* <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal', color: '#072A6E'}}
          >
            {dataSimulasi.upsKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col>
        <Col span={6} style={{textAlign: 'right'}}>
          <span 
            className='text-header-finance' 
            style={{ fontWeight: 'normal', color: '#072A6E'}}
          >
            {dataSimulasi.usKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </span>
        </Col> */}
      </Row>
    )
  }

  return(
    <Col span={24}>
      <Card className="app-card-info-user" style={{height: '100%'}}>
        {
          props.responsive && props.responsive.grid.xs ?
            <IsMobileView/> : <IsDesktopView/>
        }
      </Card>
    </Col>
  )

}

export default Simulasi



