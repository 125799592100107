/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input, Col, Row, Form, Typography, Card } from 'antd';
import { debounce } from 'lodash';

const {Text, Title} = Typography;

export default function DataBakatMinat(props) {
  const [form] = Form.useForm();
  const {onChange, initValue, formId, formTitle, isValidating} = props;
  let debouncedFn;

  useEffect(() => {
    if(!!initValue) {
      form.setFieldsValue(initValue.value);
      onChange(formId, initValue)
    }
  }, [initValue]);

  useEffect(() => {
    if (!!isValidating) {
      form.validateFields().then(_ => onChange(formId, {valid: true}))
      .catch(_ => onChange(formId, {valid: false}))
    }
  }, [isValidating])


  const handleChange = (ev) => {
    if (!debouncedFn) {
      debouncedFn = debounce(async () => {
        form.validateFields().then(_ => onChange(formId, {valid: true, value: form.getFieldsValue()}))
        .catch(_ => onChange(formId, {valid: false, value: form.getFieldsValue()}))
      }, 300)
    }
    debouncedFn()
  }  

  return (
    <Card>
      <Form form={form} onValuesChange={handleChange}>
      {/* <Title level={4}>Bakat dan Minat</Title> */}
        <Row gutter={20}>
        <Col span={24} style={{borderBottom: '1px solid rgb(217, 217, 217)', marginBottom: '1em'}}><Title level={4}>{formTitle}</Title></Col>
          <Col span={24}>
            <Text>Apakah siswa bisa memainkan alat musik? Jika Ya, silahkan sebutkan alat musiknya!</Text>
            <Form.Item name="bakatAlatMusik" >
              <Input.TextArea className="input-modal" placeholder="Contoh: Gitar, Suling, Biola" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Apakah siswa mempunyai bakat seni? Jika Ya, silahkan dijelaskan!</Text>
            <Form.Item name="bakatSeni">
              <Input.TextArea className="input-modal" placeholder="Contoh: Memiliki bakat menari jaipong" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Apakah siswa mempunyai bakat olahraga? Jika Ya, silahkan dijelaskan!</Text>
            <Form.Item name="bakatOlahraga" >
              <Input.TextArea className="input-modal" placeholder="Contoh: Jago bermain sepak bola" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Apakah siswa mempunyai bakat photography / videography / editing video? Jika Ya, silahkan dijelaskan!</Text>
            <Form.Item
              name="bakatFoto"
              style={{marginBottom: '0px'}}
            >
              <Input.TextArea className="input-modal" placeholder="Contoh: Mampu menggunakan Photoshop" />
            </Form.Item>
          </Col>
        </Row>
    
      </Form>
    </Card>
  )
}
