/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState } from 'react'
import { Typography,  Row, Col, Card, Button} from 'antd';
import axios from 'axios';
import CONFIG from '../../../service/config'
import { dialog } from '../../../functions/alert';
import Downloader from 'js-file-downloader';

const { Text, Title } = Typography

function InfoCard(props) {
  // console.log('data siswa : ',props)
  const [isLoading, setLoading] = useState(false)

  const downloadKartu = async() => {
    setLoading(true)
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    }
    await axios.get(CONFIG.BASE_URL + '/api/penerimaan/cetakKartu/' +JSON.parse(sessionStorage.getItem('siswaId')), config).then(async (res) => {
      if(res.data){
        var {uri = ''} = res.data
          await new Downloader({url: uri}).then(file => {
            setLoading(false)
        }).catch(err => {
          console.log(err)
          dialog({icon: 'error', text: err.response ? err.response.data.message : err.message})
          setLoading(false)
        })
      }
    }).catch(err => {
      console.log(err)
      dialog({icon: 'error', text: err.response ? err.response.data.message : err.message});
      setLoading(false)
    } );
  } 

  return(
    <Col span={24} style={props.style}>
      <Card className="app-card-info-user" style={{height: '100%'}}>
        <Row style={{borderBottom: '1px solid #d9d9d9'}} align='middle'>
          <Col xs={24} md={16} lg={16}><Title level={4}>Informasi Akun</Title></Col>
          <Col xs={24} md={8} lg={8} style={{textAlign: 'right', marginBottom: 10}}>
            <Button
              htmlType="submit"
              className="app-btn secondary"
              onClick={() => {
                downloadKartu()
              }}
              disabled={props.disable}
              loading={isLoading}
            >
              CETAK KARTU PPDB
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: '1em', width: '100%', alignItems: 'center'}}>
          <Col span={24} lg={8}>
            <Text className="text-subtitle">
              Nama : {props.user.namaLengkapSiswa}
            </Text>
          </Col> 
          <Col span={24} lg={8}>
            <Text className="text-subtitle">
              E-mail : {props.user.username}
            </Text>
          </Col> 
          <Col span={24} lg={8}>
            <Text className="text-subtitle">
              No. Registrasi : {props.user.noRegistrasi ? props.user.noRegistrasi : '-'}
            </Text>
          </Col>                            
        </Row>
      </Card>
    </Col>
  )

}

export default InfoCard