/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, PageHeader, message } from 'antd';
import InputData from './Cards/inputData';
import VerifikasiData from './Cards/verifikasiData';
import JadwalWawancara from './Cards/jadwalWawancara';
import Auth from '../../service/auth';
import { dialog } from '../../functions/alert';
import CONFIG from './../../service/config';
import axios from 'axios';
import { AppLoading } from '../../component/loading';
import AppCache from '../../service/cache';
import InfoCard from './Cards/infoCard';
import AppNotice from '../../component/app-notice';
import AdminConfig from '../../service/admin-config';

const httpConfig = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
};

async function ajukanTahapAwal() {
  return new Promise((resolve, reject) => {
    const siswaId = sessionStorage.getItem('siswaId');
    axios.post(CONFIG.BASE_URL + '/api/dashboardSiswa/ajukanTahapAwal?siswaId='+siswaId, {}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  });
} 


function Pendaftaran(props) {
  const isLogin = Auth.isLogin();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const siswaId = sessionStorage.getItem('siswaId');
  const [isLoading, setIsLoading] = useState(true);
  const [isPindahJalur, setIsPindahJalur] = useState(false)
  const [isError, setIsError] = useState(false);
  const [statusTahapAwal, setStatusTahapAwal] = useState({});


  useEffect(() => {
    document.title = "PPDB SMA Kolese Loyola | Loyola"
    initialConfig()
  }, []) 

  const initialConfig = () => {
    AdminConfig.getDataConfig().then((res)=>{
      if (isLogin){
        if(user.daftarUlang) {
          props.history.push('/pendaftaran/daftar-ulang');
        } else if(user.statusEksekutif) {
          props.history.push('/pendaftaran/finance');
        }
        getStatusTahapAwal(user.userId)
      } else {
        if(!AppCache.get('signature').fileName) {props.history.push('/pendaftaran')};
        setIsLoading(false);
      }
    }).catch(err => {
      if(err){
        console.log('AdminConfig Error : ',err)
        setIsError(true)
      }
    })
  }

  useEffect(()=>{
    const isDisabled = JSON.parse(sessionStorage.getItem('adminConfig')) || {};
    console.log('jalur : ',props.jalurPenerimaan)
    if(props.jalurPenerimaan == 'Prestasi' && !isDisabled.prestasiLogin){ //login dan sistem login ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.prestasiLoginInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      props.history.push("/login")
    }else if(props.jalurPenerimaan != 'Prestasi' && !isDisabled.tesLogin){ //login dan sistem login ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.tesLoginInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      props.history.push("/login")
    }else if(props.jalurPenerimaan == 'Prestasi' && !isDisabled.prestasiDaftar && !user){ //belum login dan sistem daftar ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.prestasiDaftarInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      props.history.push("/pendaftaran")
    }else if(props.jalurPenerimaan != 'Prestasi' && !isDisabled.tesDaftar && !user){ //belum login dan sistem daftar ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.tesDaftarInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      props.history.push("/pendaftaran")
    }
  },[sessionStorage.getItem('adminConfig')])

  const handlePindahJalur = () => {
    console.log("ganti tes")
    setIsPindahJalur(true);
    pindahJalur(JSON.parse(sessionStorage.getItem('user')).userId)
  }

  const handleAjukanTahapAwal = async () => {
    try {
      await ajukanTahapAwal();
      dialog({title: 'Berhasil mengajukan tahap awal', icon: 'success'});
      initialConfig()
    } catch(err) {
      dialog({title: 'Gagal Mengajukan tahap awal', icon: 'error', text: err.response && err.response.data && err.response.data.message})
    }
  }
  
  async function getStatusTahapAwal(siswaId) {
    setIsLoading(true);
    setIsError(false);
    try {
      const tahapAwalRes = await axios.get(CONFIG.BASE_URL + '/api/dashboardSiswa/tahapAwal?siswaId=' + siswaId, httpConfig)
      const  data = tahapAwalRes.data.Data
      console.log({tahapAwalRes, data})
      if (!!data) {
        sessionStorage.setItem('statusPendaftaran', JSON.stringify(data));
        setStatusTahapAwal(data);
      }
    } catch  (err) {
      dialog({icon: 'error', title: 'Terjadi Kesalahan Pada Server'})
      setIsError(true);
    }
    setIsLoading(false);
  } 

  
  function pindahJalur(siswaId) {
    axios.put(CONFIG.BASE_URL + '/api/ubahJalur/PrestasiToTest?siswaId=' + siswaId, {}, httpConfig)
      .then(() => {
        dialog({icon: 'success', text: 'Selamat Anda telah berpindah jalur dari Jalur Prestasi ke Jalur Tes!'}).then(res=>{
          Auth.logout();
          props.history.replace("/login")
        })
      })
      .catch(err => {
        if(err){
          setIsPindahJalur(false);
          dialog({icon: 'error', title: 'Gagal Pindah Jalur', text: err.response && err.response.data && err.response.data.message })
        }
      });
  } 

  return (
    <div className="container" style={{height: '100%'}}>
    {isLoading ? (
      <div style={{padding: '5em 0em'}}>
        <AppLoading text="Memuat data siswa"/>  
      </div>
    ) : !isError ? (
      <>
        <PageHeader
          className="site-page-header"
          title={`${props.jalurPenerimaan != 'Prestasi' ? 'Jalur Tes' : 'Jalur Prestasi'}`}
        />
        <Row gutter={[30, 30]} style={{alignItems: 'stretch'}}>
          {user && <InfoCard user={user}/>}
          {!statusTahapAwal.statusAdministrasi ? (  
            <InputData 
              dataStatus={statusTahapAwal} 
              jalurPenerimaan={props.jalurPenerimaan}
              onStatusChange={_ => {
                initialConfig()
              }}
              onAjukanTahapAwal={e => handleAjukanTahapAwal()} />
          ) : (
            <VerifikasiData 
              status={statusTahapAwal}
              jalurPenerimaan={props.jalurPenerimaan}
              isPindahJalur={isPindahJalur}
              onPindahJalur={()=> handlePindahJalur()}
              noteVerifikator={statusTahapAwal.noteVerifikator} //catatan perifikator
            />
          )}
          
          {/* tahap ke dua */}
          {(statusTahapAwal && (statusTahapAwal.statusAdministrasi || {}).statusId === 25 ||
            (statusTahapAwal.statusAdministrasi || {}).statusId === 6) ? '' : <JadwalWawancara
            isAjukan={statusTahapAwal.ajukanTahapAwal}
            status={statusTahapAwal}
            jalurPenerimaan={props.jalurPenerimaan}
          />}
        </Row>
      </>
    ) : (
      <AppNotice 
        heading="Gagal memuat data siswa" 
        message="Harap dicoba kembali dalam beberapa saat" 
        btnmessage="Muat ulang" 
        onClickBtn={() => 
          initialConfig()
        } />
    )}
    </div>
    
  )
}

export default withRouter(Pendaftaran)
