import React, { useState, useEffect } from 'react'
import { Modal, Typography, Form, Button, Row, Col, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import  CONFIG  from '../../../service/config';
import UploadComponent from '../../../component/upload-component';
import AppCache from '../../../service/cache';
import { dialog } from '../../../functions/alert';
import axios from 'axios';

const { Title, Text } = Typography

function saveRaport(data) {
  return new Promise((resolve, reject) => {
    let body = {...data};
    const siswaId = sessionStorage.getItem('siswaId');
    console.log(body)
    axios.post(CONFIG.BASE_URL +'/api/calonSiswa/uploadRapor/dataBerkas/'+ siswaId, body).then(res => {
      resolve(res);
    }).catch(err => reject(err) );
  })
} 

const ModalUploadRaport = props => {
  const [value, setValue] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const uploadUrl = CONFIG.BASE_URL + '/api/calonSiswa/uploadRapor/';

  useEffect(() => {
    const initValue = AppCache.get('uploadRapor');
    if (!!initValue)
      form.setFieldsValue(initValue);
    return () => {
      AppCache.set('uploadRapor', form.getFieldsValue());
    }
  }, [])

  const handleFormChange = () => {
    AppCache.set('uploadRapor', form.getFieldsValue());
    setValue(form.getFieldsValue());
  }

  const handleSumbit = async () => {
    setIsLoading(true);
    const formValue = form.getFieldsValue();
    const body = {};
    Object.keys(formValue).forEach(key => {
      body[key] = [
        {uploadBerkas: formValue[key].fileName}
      ]
    });
    
    await saveRaport(body).then(() => {
      dialog({
        title: 'Rapor berhasil di-upload',
        icon: 'success',
      })
      props.onFinish();
    }).catch(err => {
      dialog({
        title: 'Upload raport gagal',
        icon: 'error',
        text: err.response ? err.response.data.message : err.message,
      })
    })
    setIsLoading(false);
  }

  const progress = ((Object.values(value).filter(e => !!e)).length / 4) * 100;

  return(
    <Modal
      destroyOnClose={true}
      visible={props.visible}
      className="form-modal"
      footer={null}
      closable={true}
      onCancel={e => props.onClose(progress)}
      centered
      width="1000px"
    >
      <Form form={form} onValuesChange={handleFormChange}>
        <Row style={{borderBottom: '1px solid #d9d9d9',}}>
          <Col span={18}><Title level={4}>Upload Rapor</Title></Col>
        </Row>
        <Row>
          <Col span={24} style={{margin: "20px 0em", fontSize: '1em'}}>
            <Card className="app-card paper">
              <Text strong={true}>Panduan Upload :</Text>
              <ol>
                <li>Scan menggunakan scanner</li>
                <li>Pastikan format hasil scan sudah dalam bentuk PDF atau JPG berukuran kurang dari 5 MB</li>
                <li>Klik tombol Upload File untuk mengunggah file ke sistem</li>
                <li>Klik tombol Upload Rapor ketika semua file yang diunggah sudah sesuai</li>
              </ol>
            </Card>
          </Col>

          <Col span={24} style={{marginBottom: "20px"}}>
            <Card className="app-card paper">
              <Row gutter={[0, 10]} style={{marginBottom: '0px'}}>
                <Col span={24} style={{}}>
                  <Text className='text-subtitle'>Kelas 7 <Text style={{color: 'red', fontSize: '1.2em', bottom: '1em'}}>*</Text></Text>
                </Col>
                <Col span={24} style={{marginBottom: '10px'}}>
                  <Form.Item  name="berkasRaporKelas7Sem1"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: 'Rapor harus diinput'}]}>
                    <UploadComponent fluidBtn
                      key="raportkelas7smt1"
                      accept=".jpg,.jpeg,.pdf"
                      maxSize={5}
                      buttonLabel="Semester 1"
                      title="Upload File Semester 1"
                      url={uploadUrl} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item  name="berkasRaporKelas7Sem2"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: 'Rapor harus diinput'}]}>
                    <UploadComponent fluidBtn
                      key="raportkelas7smt2"
                      accept=".jpg,.jpeg,.pdf"
                      maxSize={5}
                      buttonLabel="Semester 2"
                      title="Upload File Semester 2"
                      url={uploadUrl} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} style={{marginBottom: "20px"}}>
            <Card className="app-card paper">
              <Row gutter={[0, 10]} style={{marginBottom: '0px'}}>
                <Col span={24}>
                  <Text className='text-subtitle'>Kelas 8 <Text style={{color: 'red', fontSize: '1.2em', bottom: '1em'}}>*</Text></Text>
                </Col>
                <Col style={{marginBottom: '10px'}} span={24}>
                  <Form.Item  name="berkasRaporKelas8Sem1"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: 'Rapor harus diinput'}]}>
                    <UploadComponent fluidBtn
                      key="raportkelas8smt1"
                      accept=".jpg,.jpeg,.pdf"
                      maxSize={5}
                      buttonLabel="Semester 1"
                      title="Upload File Semester 1"
                      url={uploadUrl} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item  name="berkasRaporKelas8Sem2"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: 'Rapor harus diinput'}]}>
                    <UploadComponent fluidBtn
                      key="raportkelas8smt2"
                      accept=".jpg,.jpeg,.pdf"
                      maxSize={5}
                      buttonLabel="Semester 2"
                      title="Upload File Semester 2"
                      url={uploadUrl} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} style={{textAlign: "right"}}
            rules={[{required: true, message: 'Rapor Harus diinput'}]}>
            <Button className="app-btn primary" 
              disabled={isLoading}
              onClick={_ => {
                form.validateFields().then(() => {
                  handleSumbit();
                }).catch(info => {
                  console.log('Validate Failed:', info);
                });  
              }}
            >
              {isLoading && <LoadingOutlined />}
              Upload Rapor
            </Button>
          </Col>
        </Row>
      </Form>
        {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
        {/* <pre>{JSON.stringify(fileList, '', 2)}</pre> */}
    </Modal>
  )

}



export default ModalUploadRaport