import React from 'react';
import { Modal, Row, Col, Table } from 'antd'

function UIModal(props) {
  
  const columns = [
    {
      title: 'Tingkat',
      dataIndex: 'tingkat',
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {},
        };
        if (index === 0) {
          obj.props.rowSpan = 3;
        }
        // These two are merged into above cell
        if (index === 1) {
          obj.props.rowSpan = 0;
        }
        if (index === 2) {
          obj.props.rowSpan = 0;
        }
        if (index === 3) {
          obj.props.rowSpan = 3;
        }
        // These two are merged into above cell
        if (index === 4) {
          obj.props.rowSpan = 0;
        }
        if (index === 5) {
          obj.props.rowSpan = 0;
        }
        if (index === 6) {
          obj.props.rowSpan = 3;
        }
        // These two are merged into above cell
        if (index === 7) {
          obj.props.rowSpan = 0;
        }
        if (index === 8) {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
    {
      title: 'Juara',
      dataIndex: 'juara',
    },
    {
      title: 'Individu',
      dataIndex: 'diskonIndividu',
    },
    {
      title: 'Kelompok',
      dataIndex: 'diskonBeregu',
    },
  ]

  return (
    <Modal
      visible={props.visible}
      closeIcon={<div className="icon-cancel" />}
      footer={null}
      width={700}
      centered
      className='modal-style'
      onCancel={props.onCancel}
      destroyOnClose={true}
    >
        <Row gutter={[ 16, 16]}>
          <Col span={24}>
            <span className='head-modal'>
              {props.title}
            </span>
          </Col>
          <Col span={24}>
            <div className='divider-finance' />
          </Col>
          {props.bodyText !== 'tabel' ? (
            <Col span={24}>
              <span className='body-text-modal'>
                {props.bodyText}
              </span>
            </Col>
          ) : (
            <Col span={24}>
              <Table 
                columns={columns}
                dataSource={props.dataTabel}
                scroll={{x: props.responsive && props.responsive.grid.xs ? props.responsive.width : 0}}
              />
            </Col>
          )}
        </Row>
    </Modal>
  )
}

export default UIModal