import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import { Layout, Row, Input, Button, Form, Col, Typography, Spin, PageHeader } from 'antd';
import { AppLoading } from '../../component/loading';
import Peraturan from './component/peraturan';
import HasilUjian from './component/hasil';
import Pertanyaan from './component/pertanyaan';
import siswaServices from '../../service/siswaServices';
import Dummy from '../../dummy';
import * as dummyData from './dummy-data';
import { dialog } from '../../functions/alert';
import Jadwal from './component/jadwal';
import http from '../../service/http-common';
import UjianSelesai from './component/ujian-selesai';
import appCache from '../../service/cache';

const { Title } = Typography;

const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
};

const getJadwal = () => {
  return new Promise ((resolve, reject) => {
  })
}

const getSoal = () => {
  return new Promise ((resolve, reject) => {
    http.get('api/ujianAkademik/getSoal', config).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}


const UjianAkademik = props => {
  const [jadwal, setJadwal] = useState([])
  // ? 1 = Peraturan. 2 = Ujian, 3 = Selesai ujian, 4 = Hasil Ujian
  const [screen, setScreen] = useState(0)
  const [listSoal, setListSoal] = useState(null)
  const [isSend, setIsSend] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [matpelStart, setMatpelStart] = useState('')
  const [isLoadingStartUjian, setLoadingStartUjian] = useState(false)
  const [isUjianFinish, setIsUjianFinish] = useState(false)

  useEffect(() => {
    if(isLoading){
      setLoading(false)
      const cacheUjian = appCache.get('ujianAkademik');
      const user = JSON.parse(sessionStorage.getItem('user'));
      if (cacheUjian.subjectId) {
        startUjian(cacheUjian.subjectId, user.userId);
      } else {
        getJadwal();
      }
    }
  }, [])
  
  const getJadwal = (configuration) => {
    let setting = configuration ? configuration : config
    const user = JSON.parse(sessionStorage.getItem('user'));
    http.get('api/getRules?siswaId='+ user.userId, setting).then(res => {
      console.log(res);
      setJadwal(res.data);
      setScreen(1);
    }).catch(err => {
      if(err){
        setting = {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        }
        if(err.response && err.response.status == 401){
          getJadwal(setting)
          window.location.reload();
        }else{
          let pesan = err && err.response && err.response.data && err.response.data.message ? err.response.data.message || '' : 'Terjadi Kesalahan Pada Server, Coba Mulai Lagi'
          dialog({icon:'error', text: pesan}).then(retry => {
            if(retry){
              getJadwal(setting)
            }
          })
        }
      }
    })
    // setJadwal(dummyData.dummyJadwalUjian);
  }

  const handleStart = (subjectId) => {
    setLoadingStartUjian(true)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const body = {
      siswaId: user.userId,
      matpelId: subjectId
    }
    http.post(`api/ujianAkademik/startUjian`, body, config).then(res => {
      let nameMatpel = res.data && res.data.matpel ? res.data.matpel : ''
      setMatpelStart(nameMatpel)
      appCache.set('ujianAkademik', {jawaban: null, subjectId: subjectId})
      startUjian(subjectId, user.userId);
      setLoadingStartUjian(false)
    }).catch(err => {
      if(err){
        setLoadingStartUjian(false)
        let pesan = err && err.response.data && err.response.data.message ? err.response.data.message : 'Terjadi Kesalahan Pada Server, Coba Mulai Lagi'
        dialog({icon:'error', text: pesan}).then(retry => {
          if(retry){
            handleStart(subjectId)
          }
        })
      }
    })
  }

  const startUjian = (subjectId, userId) => {
    http.get(`api/ujianAkademik/getSoalByMatpel?siswaId=${userId}&matpelId=${subjectId}`, config).then(res => {
      appCache.set('ujianAkademik', {subjectId: subjectId})
      setListSoal({
        ...res.data,
      })
      setIsUjianFinish(false)
      setScreen(2)
    }).catch(err => {
      if(err){
        dialog({icon:'error', text:err && err.response.data && err.response.data.message ? err.response.data.message : 'Terjadi Kesalahan Pada Server, Coba Mulai Lagi'}).then(retry => {
          if(retry){
            handleStart(subjectId)
          }
        })
      }
    })
  }

  const handleFinish = (e) => {
    appCache.set('ujianAkademik', {jawaban: null, subjectId: 0});
    setIsUjianFinish(true)
    setScreen(3)
  }

  const onBack = () => {
    getJadwal()
    window.location.reload()
  }

  return(
    <div className="container">
      <PageHeader
        className="site-page-header"
        title={screen === 4 ? `Hasil Ujian Akademik` : `Ujian Akademik`}
      />
      <div>
        {screen === 1 ? (
          <Jadwal data={jadwal} onStart={id => handleStart(id)} isLoadingStartUjian={isLoadingStartUjian}/>
        ) : screen === 2 && listSoal ?  ( 
          <Pertanyaan 
            dataSoal={listSoal} 
            isSend={isSend}
            onFinish={(e)=>{
              handleFinish(e);
            }}
            isFinished={isUjianFinish}
          /> 
        ) : screen === 3 ? (
          <UjianSelesai matpel={matpelStart} onBack={onBack} />
        ) : screen === 4 ? (
          <HasilUjian/>
        ) : (
          <AppLoading/>
        )}
      </div>
      {/* <div style={{color: '#f0f2f5'}}>{ 'duration selesai' + time}</div> */}
    </div>
  )
}

export default withRouter(UjianAkademik) 