import React from 'react'
import { Col } from 'antd';

function Rapor(props) {

  return(
    <>
      <Col span={24}>
        <span className='text-header-finance'>
          Beasiswa Prestasi Rapor
        </span>
      </Col>
      <Col span={24}>
        <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
          Total nilai rapor (5 Matpel) / Semester
        </span>
      </Col>
      <Col span={24}>
        <ol className='ol-finance' style={{listStyle: 'none'}}>
          <li className='li-finance'>
            <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
              Jumlah Nilai 5 Matpel semester 1 : <b>{props.nilai.semester1}</b>
            </span>
          </li>
          <li className='li-finance'>
            <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
              Jumlah Nilai 5 Matpel semester 2 : <b>{props.nilai.semester2}</b>
            </span>
          </li>
          <li className='li-finance'>
            <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
              Jumlah Nilai 5 Matpel semester 3 : <b>{props.nilai.semester3}</b>
            </span>
          </li>
          <li className='li-finance'>
            <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
              Jumlah Nilai 5 Matpel semester 4 : <b>{props.nilai.semester4}</b>
            </span>
          </li>
        </ol>
      </Col>
      <Col span={24} style={{ marginTop: '30px'}}>
        <span className='text-diskon'>
          * Potensi Diskon: {props.diskon}
        </span>
      </Col>
    </>
  )

}

export default Rapor