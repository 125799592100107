const dataAkademik = {
  "nilai": {
    value: [
        {
            "matpelId": {
                "matpelId": 3
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 100,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 5
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99,
            "kkmKelas8": 1
        },
        {
            "matpelId": {
                "matpelId": 6
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 7
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 8
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem2": 99,
            "kelas8Sem1": 99
        },
        {
            "matpelId": {
                "matpelId": 9
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 10
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas7Sem2": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 11
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 12
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 13
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 11,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 14
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 15
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 1
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 2
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        },
        {
            "matpelId": {
                "matpelId": 4
            },
            "kkmKelas7": 1,
            "kelas7Sem1": 99,
            "kelas7Sem2": 99,
            "kkmKelas8": 1,
            "kelas8Sem1": 99,
            "kelas8Sem2": 99
        }
    ],
    valid: true
  },
  "penilaianSikap": {
    value: [
        {
            "matpelId": {
                "matpelId": 16
            },
            "kelas7Sem1": "B",
            "kelas7Sem2": "C",
            "kelas8Sem1": "A",
            "kelas8Sem2": "B"
        },
        {
            "matpelId": {
                "matpelId": 17
            },
            "kelas7Sem1": "A",
            "kelas7Sem2": "D",
            "kelas8Sem1": "D",
            "kelas8Sem2": "B"
        },
        {
            "matpelId": {
                "matpelId": 18
            },
            "kelas7Sem1": "A",
            "kelas7Sem2": "E",
            "kelas8Sem1": "C",
            "kelas8Sem2": "B"
        }
    ],
    valid: true
  },
  "kehadiran": {
    value: [
        {
            "kehadiranId": {
                "kehadiranId": 1
            },
            "kelas7Sem1": 1,
            "kelas7Sem2": 1,
            "kelas8Sem1": "1",
            "kelas8Sem2": 1
        },
        {
            "kehadiranId": {
                "kehadiranId": 2
            },
            "kelas7Sem1": 1,
            "kelas7Sem2": 1,
            "kelas8Sem1": "1",
            "kelas8Sem2": 1
        },
        {
            "kehadiranId": {
                "kehadiranId": 3
            },
            "kelas7Sem1": 1,
            "kelas7Sem2": 1,
            "kelas8Sem1": "1",
            "kelas8Sem2": 1
        }
    ],
    valid: true
  },
}

export default dataAkademik;