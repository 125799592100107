import React from "react";
import { Modal, Typography } from "antd";

const { Title } = Typography;

const ModalKetentuan = (props) => {
  return (
    <Modal
      destroyOnClose={true}
      visible={props.visible}
      className="form-modal"
      footer={null}
      closable={true}
      onCancel={props.onClose}
      centered
      width="600px"
    >
      <Title level={4}>Ketentuan</Title>
      <ul>
        {props.jalurPenerimaan == "Prestasi" && (
          <li>
            Jumlah nilai mata pelajaran Bahasa Indonesia, Bahasa Inggris, IPA,
            IPS, dan Matematika setiap semester yang ditentukan oleh sekolah.
          </li>
        )}
        <li>
          Nilai seluruh mata pelajaran harus tuntas sesuai dengan KKM yang
          ditentukan oleh sekolah
        </li>
        <li>Rapor yang digunakan adalah kelas 7 dan 8 (semester 1 dan 2)</li>
      </ul>
    </Modal>
  );
};

export default ModalKetentuan;
