/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { Layout, Row, Input, Card, Button, Form, Col, Typography, message} from 'antd';
import { LockOutlined, LoadingOutlined } from '@ant-design/icons';
import Logo from '../../../assets/logo-loyola-300x300.svg'
import ModalRegister from './modal'
import Auth from '../../service/auth';
import AppCache from '../../service/cache';
import { useForm } from 'antd/lib/form/Form';
import { dialog } from '../../functions/alert';
import AdminConfig from '../../service/admin-config';
import CONFIG from '../../service/config';

const { Content } = Layout;
const { Text, Title } = Typography


function Login (props) {
  const history = useHistory();
  const [form] = useForm();
  const [isModalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initialConfig()
  }, []); 

  useEffect(() => {
    document.title = props.name + " | Loyola"
  }, [props.name])

  useEffect(() => {
    document.title = props.name + " | Loyola"
  }, [props.name])

  const initialConfig = () => {
    AdminConfig.getDataConfig().then((res)=>{
      console.log('AdminConfig Success : ',res)
      if (!!Auth.isLogin()) {
        history.push('/pendaftaran')
      }
      setIsLoading(false)
    }).catch(err => {
      console.log('AdminConfig Error : ',err)
    })
  }

  const redirectPage = (res) => {
    const {data : {jadwal, jalurPenerimaan, statusEksekutif, daftarUlang, ujianAkamedik}} = res;
    let isDisabled = sessionStorage.getItem('adminConfig') && JSON.parse(sessionStorage.getItem('adminConfig'))
    let redirectUrl; 
    AppCache.set('jadwal', jadwal)
    sessionStorage.setItem('jadwal', JSON.stringify(jadwal));
    let messageInfo = jalurPenerimaan == "Prestasi" ? isDisabled.prestasiLoginInfo : isDisabled.tesLoginInfo
    // DAFTAR ULANG
    if (!!daftarUlang) {
      redirectUrl = '/pendaftaran/daftar-ulang';
    } else 
    if (!!statusEksekutif) {
      if((jalurPenerimaan === "Prestasi" && isDisabled.prestasiLogin) || (jalurPenerimaan != "Prestasi" && isDisabled.tesLogin)){
        redirectUrl = '/pendaftaran/finance';
      }else{
        dialog({title: 'Pemberitahuan', text: `${messageInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
        initialConfig()
      }
    // UJIAN AKADEMIK
    } else {
      if(!!ujianAkamedik){
        redirectUrl = 'pendaftaran/ujian-akademik';
      } else if(jalurPenerimaan === "Prestasi") {
        if(!isDisabled.prestasiLogin){
          dialog({title: 'Pemberitahuan', text: `${messageInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
          initialConfig()
        }else{
          redirectUrl = '/pendaftaran/jalur-prestasi';
        }
      } else {
        if(!isDisabled.tesLogin){
          dialog({title: 'Pemberitahuan', text: `${messageInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
          initialConfig()
        }else{
          redirectUrl = '/pendaftaran/jalur-tes-online';
        }
      }
    }
    history.push(redirectUrl);
  }

  
  const handleLogin = async () => {
    setIsLoading(true);
    // redirectPage()
    await Auth.login(form.getFieldsValue()).then((res) => {
      if(sessionStorage.getItem('token')){
        redirectPage(res)
      }
    }).catch(err => {
      if(err.response || err ==='isAdmin'){ message.error('Username/Password salah'); }
      else if(err.response || err.disableLogin){
        dialog({title: 'Pemberitahuan', text: err.isLoginMessage || '', icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      }
      else{ message.error('Terjadi Kesalahan Pada Server'); }
      setIsLoading(false);
    });
  }

  const handleModalRegis = () => {
    setModalShow(!isModalShow)
  }

  const handleDaftar = () => {
    history.push('/pendaftaran')
  }

  const handleForgotPass = () => {
    props.history.push('/lupa-password');
  }

  return(
    <Layout>
    <Content className="site-layout-login contentContainer">
      <div className="container">
        <Row gutter={[40, 40]} style={{alignContent: "start", margin: 'auto'}}>
          <Col span={24} xl={{span: 18, offset: 3}} lg={24}>
            <img src={Logo} alt="logo" onClick={()=> props.history.push("/")} />
          </Col>
          <Col span={24} xl={{span: 9, offset: 3}} lg={24} style={{marginBottom: '2em'}}>
            
            <Text className="title-login">
              Selamat Datang di PPDB<br />
              SMA Kolese Loyola <br />
              Semarang
            </Text>
            <div align="left" style={{marginTop: "2em"}}>
              <Text className="label-login" style={{marginRight: "20px"}}>Ingin mendaftar?</Text>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="app-btn primary"
                onClick={handleDaftar}
              >
                KLIK DISINI
              </Button>
            </div>
          </Col>

          <Col span={24} xl={{span: 9}} lg={24}>
            <Card className="card-site-login">
              <Form form={form} >
                  <Row gutter={[0, 16]}>
                    <Col style={{textAlign: 'center'}} span={24}>
                      <Title style={{color: "#fff", marginBottom: '1em'}}>LOGIN</Title>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{marginBottom:0}}
                        name="username"
                        rules={[
                          { required: true, message: 'Harap masukkan email!' }
                        ]}
                      >
                        <Input
                          className="form-group-login"
                          prefix="@"
                          type="text"
                          placeholder="E-mail"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{marginBottom:0}}
                        name="password"
                        rules={[{ required: true, message: 'Harap masukkan password!' }]}
                      >
                        <Input.Password
                          className="form-group-login"
                          prefix={<LockOutlined />}
                          name="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{textAlign: 'right',marginBottom:20}}>
                      <span 
                        style={{ 
                          fontSize: 16,
                          color: "#ffffff", 
                          cursor: "pointer" 
                        }} 
                        onClick={() => handleForgotPass()}
                      >
                        Lupa password?
                      </span>
                    </Col>
                    <Col span={24}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        className="app-btn lg block primary"
                        disabled={isLoading}
                        onClick={() => {
                          if (isLoading) { return 0; }
                          form.validateFields().then(value => {
                            handleLogin();
                          }).catch(err => {
                            console.log(err);
                          })
                        }}
                      >
                        {isLoading && <LoadingOutlined />}
                        MASUK KE AKUN
                      </Button>
                    </Col>
                  </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        

      </div>
      <ModalRegister
          visible={isModalShow}
          buttonCancel={handleModalRegis}
        />
    </Content>
    </Layout>
  )

}

export default withRouter(Login)
