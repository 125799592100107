import React, { useState} from 'react';
import { Card } from 'antd'

function UICardFinance(props) {
  
  return (
    <Card
      bordered={false}
      className='card-finance'
      onClick={ () => props.showPopUp()}
    >
      <div className='grandient-image' >
        <div className='container-text'>
          <span className='text-card-top'>
              {props.text}
          </span>
          <br></br>
          <span className='text-card-bottom'>
            Klik untuk melihat lebih detail
          </span>
        </div>
        <div className='image-style'
          style={{ backgroundImage: `url(${props.image})` }}
        >
        </div>
      </div>
    </Card>
  )
}

export default UICardFinance;