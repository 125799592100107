/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Typography, Row, Col, Steps, Card } from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';
import  CONFIG  from '../../../../service/config';
import { AppLoading } from '../../../../component/loading';
import Auth from '../../../../service/auth';
import AppCache from '../../../../service/cache';
import BiodataDiri from './data-diri';
import DataWali from './data-ortu';
import { dialog } from '../../../../functions/alert';
import { errorByStatus } from '../../../../constant/error-message';
import { compare } from '../../../../functions/object';
import { useHistory } from 'react-router-dom';

const { Text } = Typography
const { Step } = Steps
const keyDataOrtu = ['dataAyah', 'dataIbu', 'dataWali'];


function saveDataDiri(data) {
  return new Promise((resolve, reject) => {
    const body = { ...data };
    axios.post(CONFIG.BASE_URL_NOAUTH +'/api/isiDataDiridanKeluarga', body).then(res => {
      resolve(res);
    }).catch(err => reject(err) );
  })
} 

function revisiDataDiri(siswaId, data, isRevisiOther) {
  return new Promise((resolve, reject) => {
    const body = { ...data };
    axios.put(CONFIG.BASE_URL +'/api/revisiIsiDataDiridanKeluarga?siswaId='+siswaId+'&isRevisiOther='+isRevisiOther, body).then(res => {
      resolve(res);
    }).catch(err => reject(err) );
  })
} 

function loadDataDiri(userId) {
  return new Promise((resolve, reject) => {
    axios.get(CONFIG.BASE_URL + '/api/calonSiswa/dataDiriSiswa?siswaId=' + userId).then(res => {
      resolve(res);
    }).catch(err => reject(err))
  })
}


const defaultSteps = [
  {
    title: "Data Diri",
    key: 'dataDiri',
    Content: BiodataDiri,
    code: '0'
  },
  {
    title: "Data Ayah",
    key: 'dataAyah',
    Content: DataWali,
    code: '1'
  },
  {
    title: "Data Ibu",
    key: 'dataIbu',
    Content: DataWali,
    code: '2'
  },
  {
    title: "Data Wali",
    key: 'dataWali',
    Content: DataWali,
    code: '3'
  },
];

function ModalBiodata(props) {
    const history = useHistory();
    const [recent, setRecent] = useState(0)
    const [steps, setSteps] = useState(defaultSteps);
    const [catatan, setCatatan] = useState();
    const [formValue, setFormValue] = useState({
      dataDiri: {},
      dataAyah: {},
      dataIbu: {},
      dataWali: {}
    });
    const [initValue, setInitValue] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [localMasterData, setLocalMasterData] = useState({});
    const {isRevisi, masterData = {}} = props

    useEffect(() => {
      if (!!isSubmitted) {
        saveData();
      }
    }, [isSubmitted])

    useEffect(() => {
      loadData();
    }, [isRevisi])

    useEffect(() => {
      if (!!masterData && !compare(localMasterData, masterData)) {
        setLocalMasterData(masterData);
      }
    }, [masterData])


    const next = (key, value) => {
      saveValue(key, value)
      const current = recent + 1;
      setRecent(current)
      window.scrollTo(0, 0)
    }
    
    const prev = (key, value) => {
      saveValue(key, value)
      const current = recent - 1;
      setRecent(current);
      setIsSubmitted(0)
      window.scrollTo(0, 0)
    }

    const saveValue = (key, value) => {
      let newValue = {...formValue}
      newValue[key] = Object.assign(newValue[key] || {}, value)
      AppCache.set('biodata', newValue);
      if (!compare(newValue, formValue)) {
        setFormValue(newValue);
        setInitValue(newValue);
      }
    }

    const handleFinish = (key, value) => {
      saveValue(key, value)
      setTimeout(_ => {
        setIsSubmitted(isSubmitted + 1)
      }, 300)
    }

    const loadData = async () => {
      let savedValue = {};
      const siswaId = sessionStorage.getItem('siswaId')
      if (isRevisi) {
        try {
          const dataDiriRes = await loadDataDiri(siswaId);
          const {data: {dataDiri}, catatan} = dataDiriRes.data;
          let dataWali = dataDiriRes.data.data.dataWali;
          // dataDiri.kakakLoyola = null;
          const {kakakMasih, kakakPernah} = dataDiri.kakakLoyola || {};
          dataWali = dataWali.map(data => {
            return {
              ...data,
              fileSlipGaji: {},
              fileNpwp: data.fileNpwp ? {fileName: data.fileNpwp} : null,
              filePbb: data.filePbb ? {fileName: data.filePbb} : null,
              fileKtp: data.fileKtp ? {fileName: data.fileKtp} : null,
              fileSpt: data.fileSpt ? {fileName: data.fileSpt} : null,
            }
          });
          savedValue = {
            dataDiri: {
              ...dataDiri,
              kakakLoyola: {
                kakakMasih: !!kakakMasih ?  kakakMasih : [],
                kakakPernah: !!kakakPernah ? kakakPernah : [] 
              },
              fotoSiswa: dataDiri.fotoSiswa ? {fileName: dataDiri.fotoSiswa} : null,
              suratPermandian: dataDiri.suratPermandian ? {fileName: dataDiri.suratPermandian} : null,
              akteKelahiran: dataDiri.akteKelahiran ? {fileName: dataDiri.akteKelahiran} : null,
              kartuKeluarga: dataDiri.kartuKeluarga ? {fileName: dataDiri.kartuKeluarga} : null,
            },
            dataAyah: dataWali.find(data => data.kodeOrtu === 1) || null,
            dataIbu: dataWali.find(data => data.kodeOrtu === 2) || null,
            dataWali: dataWali.find(data => data.kodeOrtu === 3) || null,
          };
          const newSteps = steps.filter(e => !!savedValue[e.key])
          setSteps(newSteps);
          setCatatan(catatan);
        } catch (err) {console.log('Failed to load data! ', err)}
      } else {
        savedValue = AppCache.get('biodata');
      }
      savedValue = {
        ...formValue,
        ...savedValue,
      }
      setInitValue(savedValue);
      setFormValue(savedValue);
    }

    const saveData = async () => {
      // Setting-up body data diri
      const ttd = AppCache.get('signature')
      const siswaId = sessionStorage.getItem('siswaId')
      try { await validateDataOrtu(); } 
      catch (err) {
        dialog({
          title: 'Data Orang Tua / Wali belum diisi!',
          text: 'Anda harus mengisi paling tidak 1 Data Orang Tua atau Wali beserta penghasilan',
          icon: 'warning'
        })
        return; 
      }
      setIsLoading(true);
      // console.log('formData', formValue)
      const {dataDiri} = formValue;
      let dataDiriBody = {
        'dataDiri': [{
          ...dataDiri,
          jalurPenerimaan: props.jalurPenerimaan,
          fotoSiswa: !!dataDiri.fotoSiswa ? dataDiri.fotoSiswa.fileName : '',
          suratPermandian: !!dataDiri.suratPermandian ? dataDiri.suratPermandian.fileName : '',
          fileTtd: !isRevisi ? ttd.fileName : '',
          akteKelahiran: !!dataDiri.akteKelahiran ? dataDiri.akteKelahiran.fileName : '',
          kartuKeluarga: !!dataDiri.kartuKeluarga ? dataDiri.kartuKeluarga.fileName : '',
        }],
        'dataWali': []
      };

      for (let i = 1; i < steps.length; i++) {
        const dataOrtu = {...formValue[steps[i].key]};
        if(!!dataOrtu.namaWali) {
          dataDiriBody.dataWali.push({
            ...dataOrtu,
            kodeOrtu: steps[i].code,
            fileNpwp: !!dataOrtu.fileNpwp ? dataOrtu.fileNpwp.fileName : '',
            fileSlipGaji: !!dataOrtu.fileSlipGaji ? dataOrtu.fileSlipGaji.fileName : '',
            filePbb: !!dataOrtu.filePbb ? dataOrtu.filePbb.fileName : '',
            fileSpt: !!dataOrtu.fileSpt ? dataOrtu.fileSpt.fileName : '',
            fileKtp: !!dataOrtu.fileKtp ? dataOrtu.fileKtp.fileName : '',
          });
        }
      }
      try {
        let dataDiriRes = isRevisi ? await revisiDataDiri(siswaId, dataDiriBody, props.isRevisiOther) : await saveDataDiri(dataDiriBody);
        if (dataDiriRes.status === 200 && !('Error' in dataDiriRes.data)) {
          if(!isRevisi) {
            dialog({
              title: 'Data diri berhasil disimpan', 
              text: 'Anda dapat melakukan login dengan E-mail & Password yang telah didaftarkan',
              icon: 'success'
            });
          } else {
            dialog({
              title: 'Data diri berhasil direvisi', 
              text: 'Data akan segera diverifikasi',
              icon: 'success'
            });
          }
        // eslint-disable-next-line no-throw-literal
        } else { throw {...dataDiriRes, message: dataDiriRes.data.Error, status: 400}; }
        if (!isRevisi) {
          await Auth.login({
            username: formValue.dataDiri.email,
            password: formValue.dataDiri.password,
          });
        }
        setRecent(0)
        props.onFinish();
      } catch (err) {
        const {response, request, message, status} = err;
        let errMessage = !!response ? (!!response.data.message ? response.data.message : message) : message; 
        if (typeof errMessage !== 'string') {
          errMessage = errorByStatus[status] || 'Terjadi kesalahan';
        }
        const {responseURL} = request;
        console.log(request)
        const isLoginRequest = responseURL.split('/')[responseURL.split('/').length-1] === 'login'
        console.log({isLoginRequest}, responseURL.split('/')[responseURL.split('/').length-1])
        errMessage = typeof errMessage === 'string' ? errMessage : 'Terjadi kesalahan';
        console.log('Failed to save data diri! ', errMessage)
        if (isLoginRequest) {
          Auth.logout();
          dialog({
            title: 'Gagal melakukan auto login',
            text: 'Harap melakukan login manual',
            icon: 'error'
          }).then(_ => history.push('/login') );
        } else {
          dialog({
            title: 'Gagal menyimpan data diri siswa',
            text: errMessage,
            icon: 'error'
          });
        } 
      }
      setIsLoading(false);
    }

    const validateDataOrtu = () => {
      return new Promise((resolve, reject) => {
        let isValid = {
          hasParent: false,
          parentHasSalary: false
        }
        const values = {...formValue};
        keyDataOrtu.forEach(key => {
          const dataOrtu = values[key] || {};
          // Check parent data
          let parentValues = Object.values(dataOrtu);
          parentValues = parentValues.filter(e => !!e);
          isValid.hasParent = isValid.hasParent || parentValues.length > 0;
          // Check parent salary
          isValid.parentHasSalary = isValid.parentHasSalary || ('penghasilan' in dataOrtu && (typeof dataOrtu.penghasilan === 'number' && dataOrtu.penghasilan >= 0))
        });
        if (isValid.hasParent && isValid.parentHasSalary) {
          resolve(true)
        } else {
          reject(isValid);
        }
      });
    }

    const handleAddMasterData = (key, item) => {
      const newMasterData = { ...localMasterData }
      newMasterData[key] = [
        ...newMasterData[key],
        item
      ];
      setLocalMasterData(newMasterData);
    }
    
    const Content = steps[recent].Content;
    const {key, title} = steps[recent];
    const hasDataWali = !!formValue.dataWali && Object.values(formValue.dataWali).length > 0; 

    return(
      <Modal
        destroyOnClose={true}
        visible={props.visible}
        className="form-modal"
        footer={null}
        closable={true}
        onCancel={() => {props.onClose(); }}
        centered
        width="1000px"
      >
        {!isLoading ? (
          <Row gutter={15} style={{marginTop: 20}}>
            <Col lg={6} md={24}>
              <Steps direction="vertical" current={recent} >
                {steps.map(item => (
                  <Step key={item.title} title={item.title}/>
                ))}
              </Steps>
            </Col>
            <Col lg={18} md={24}>
              <div className="steps-content">
                {/* {steps[recent].content} */}
                <Content 
                  onAddMasterData={handleAddMasterData}
                  masterData={localMasterData}
                  initValue={initValue[key]}
                  isLoading={isLoading}
                  isRevisi={isRevisi}
                  title={title}
                  isLast={steps.length === recent + 1}
                  index={recent}
                  contentKey={key}
                  key={'form-content-'+key}
                  onNext={next} 
                  onPrev={prev}
                  onChange={saveValue}
                  hasDataWali={hasDataWali}
                  onDone={(value) => {
                    handleFinish(key, value);
                  }}
                  bodyValues={formValue}
                />
              </div>
            </Col>
            {isRevisi && <Col style={{marginTop: '1em'}} span={24}>
              <Card className="app-card paper">
                <Text strong>Catatan {steps[recent].title}</Text>
                <div>
                  <Text>{(!!catatan ? catatan[steps[recent].key] : false) || 'Tidak ada catatan ...'}</Text>
                </div>
              </Card>
            </Col>}
          </Row>
        ) : (
          <AppLoading text="Menyimpan Data" style={{padding: '8em 0em'}} />
        )}
          {/* <pre>{JSON.stringify(formValue, '', 2)}</pre> */}
        </Modal>
    )
}

ModalBiodata.propTypes = {
  jalurPenerimaan: PropTypes.string,
  onFinish: PropTypes.func,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.string,
}

export default ModalBiodata