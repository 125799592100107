import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import {useForm} from 'antd/lib/form/Form';
import { LockOutlined } from '@ant-design/icons';
import { Layout, Row, Input, Button, Form, Col, Typography, Spin, PageHeader } from 'antd';
import CONFIG_INITIAL_STATE from '../../service/config';
import axios from 'axios';
import swal from "sweetalert"

const CreateNewPassword = props => {
    const { Title } = Typography;
    const [form] = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const username = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).username
    const userID = JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).userId
    
    useEffect(() => {
        document.title = "Ubah Password | Loyola";
        console.log(username)
        console.log(userID)
    }, [username, userID])

    const handleBack = () => {
        props.history.goBack();
    };

    const alert = (_config) => {
        return new Promise( async(resolve) => {
            const result = await swal({
                ..._config
            });
            resolve(result)
        })
    };

    const config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
    };

    const handleFinish = () => {
        setIsLoading(true);
        form.validateFields()
            .then((values) =>{
                let body = {
                    "username" : username,
                    "type" : 2,
                    "password" : values.newPassword
                };

                handleUpdatePassword(body, config, userID)
                    .then(r => { 
                        setIsLoading(false);
                        alert({icon: 'success',
                            // title: 'Berhasil',
                            text: "Password Anda berhasil diubah.",
                        })
                        console.log(r.data.status)
                        handleBack()
                    })
                    .catch(err => {
                        setIsLoading(false);
                        alert({icon: 'error',
                            // title: 'Gagal',
                            text: "Terjadi Kesalahan pada Server",
                        })
                        console.log(err);
                });
            })
            .catch(err=>{
                console.error(err);
                setIsLoading(false);
                alert({icon: 'error',
                    title: 'Gagal',
                    text: "Terdapat Kesalahan pada Pengisian Form",
            });
        });
    }

    const handleUpdatePassword = (data, config, userID) => {
        let api = `/api/changePassword?userId=${userID}`
        return new Promise((resolve,reject ) => {
            axios.put(CONFIG_INITIAL_STATE.BASE_URL + api, data, config)
                .then(res => {
                    resolve(res);
                }).catch(e =>{
                    reject(e);
            });
        });
    };

    return(
        <Layout.Content className='site-layout-forgotpass'>
            <PageHeader
            style={{marginLeft: '-20px'}}
            className="site-page-header"
            onBack={() => handleBack()}
            title="Ubah Password"
            />
            <Row style={{marginTop:50 , marginLeft:10}}>
                <Col xs={24} lg={12} md={12} sm={24}>
                    <Form form={form} onFinish={handleFinish}>
                        <Row>
                            <Form.Item
                                name="newPassword"
                                rules={[{ required: true, message: 'Harap masukkan password baru!' }]}
                                hasFeedback
                            >
                                <Input.Password
                                    disabled={isLoading}
                                    style={{ marginBottom: 8}}
                                    className="form-group-forgotpass"
                                    prefix={<LockOutlined />}
                                    name="password"
                                    type="password"
                                    placeholder="Password baru"
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                name="confirmNewPassword"
                                dependencies={['newPassword']}
                                rules={[
                                    { 
                                        required: true, message: 'Harap masukkan ulang password baru!' 
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                          if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                          }
                            
                                          return Promise.reject('Password yang Anda masukkan tidak sama!');
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    disabled={isLoading}
                                    style={{ marginBottom: 8 }}
                                    className="form-group-forgotpass"
                                    prefix={<LockOutlined />}
                                    name="password"
                                    type="password"
                                    placeholder="Ulangi password baru"
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Row>
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        className="forgotpass-form-button"
                                    >
                                        KIRIM
                                    </Button>
                                    <Spin
                                        style={{ marginLeft: 20, paddingTop: 15 }}
                                        spinning={isLoading}
                                        centered
                                        size="large"
                                    >
                                    </Spin>
                                </Row>
                            </Form.Item>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Layout.Content>
    )
}

export default withRouter(CreateNewPassword)