import React, { useEffect, useState } from "react";
import { Layout, Typography, Select, Col, Row, Button } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import Auth from "../../service/auth";
import Logo from '../../../assets/logo-loyola-300x300.svg'
import { MenuOutlined } from '@ant-design/icons'

const { Option } = Select
const { Header, Content } = Layout
const { Text } = Typography

const HeaderLayout = (props) => {
    let location = useLocation();
    const [user, setUser] = useState();
    const [showMenu, setMenu] = useState(false);
    const mobileView = window.innerWidth <= 640
    const handleLogout = () => {
      Auth.logout();
      props.history.push("/login")
    }

    const updateUser = () => {
      setUser(JSON.parse(sessionStorage.getItem('user')));
    }
    const handleLogin = () => {
      props.history.push("/login")
    }
    
    useEffect(() => {
      updateUser();
      document.addEventListener('sessionChanged', () => {
        updateUser();
      }, false);
    }, [])

    return !['/login', '/lupa-password', '/ubah-password', '/login-admin'].includes(location.pathname) ? (
      <Layout>
        <Header className="header-loyola">
          <div className="container">
            <Row className="wrapper" style={{height: '100%'}}>
              <Col className="logo" style={{display: 'flex', alignItems: 'center', height: '100%'}} flex={'auto'}>
                <img src={Logo} alt="logo" style={{height: !mobileView ? "50px" : '30px', cursor: 'pointer'}} onClick={_ => props.history.push('/pendaftaran')} />
                {
                  mobileView ? <Col flex='auto'/> :
                  <Col flex='auto' style={{lineHeight: !mobileView ? "30px" : '20px', marginLeft: !mobileView ? '20px' : '10px'}}>
                    <Text className="title" style={{fontSize: !mobileView ? '24px' : '16px', marginLeft: 0}}>Halo{user && ', '+user.namaLengkapSiswa+' '+(user.noRegistrasi ? `(${user.noRegistrasi})` : `(-)`)}</Text>
                  </Col>
                }
                {
                  mobileView &&
                  <Button onClick={()=> setMenu(!showMenu)}>
                    <MenuOutlined/>
                  </Button>
                }
                { !mobileView && !!user &&
                  <Select
                    className="menu" 
                    style={{color: '#072A6F', cursor: 'pointer', marginRight:20}}
                    bordered={false}
                    defaultValue="Pengaturan"
                    onSelect={() => props.history.push('/ubah-password')}
                  >
                    <Option> Ubah Password </Option>
                  </Select>
                }
                {
                  !mobileView &&
                  <Text 
                    className="menu" 
                    style={{color: '#eb5757', cursor: 'pointer'}}
                    onClick={() => !!user  ? handleLogout(): handleLogin()}
                  >
                    { !!user  ? 'Logout' : 'Login' }
                  </Text>
                }
              </Col>
            </Row>
            {
                mobileView && showMenu ?
                <Row style={{backgroundColor: '#f5f6fa', textAlign: 'right', width: '100%'}}>
                  {!!user && 
                    <Col span={24}>
                      <Select
                        className="menu" 
                        style={{color: '#072A6F', cursor: 'pointer', marginRight:10, fontSize: '16px'}}
                        bordered={false}
                        defaultValue="Pengaturan"
                        onSelect={() => props.history.push('/ubah-password')}
                      >
                        <Option> Ubah Password </Option>
                      </Select>
                    </Col>
                  }
                  <Col span={24}>
                    <Text 
                      className="menu" 
                      style={{color: '#eb5757', cursor: 'pointer', marginRight:20, fontSize: '16px'}}
                      onClick={() => !!user  ? handleLogout(): handleLogin()}
                    >
                      { !!user  ? 'Logout' : 'Login' }
                    </Text>
                  </Col>
                </Row> : null
            }


          </div>
        </Header>
        
        <Content style={{marginTop: '100px'}}>
          <div className="container" style={{height: '100%'}}>
            {props.children}
          </div>
        </Content>
      </Layout>
    ) : (<></>);
}

export default withRouter(HeaderLayout)