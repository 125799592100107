const GlobalFunction = {
  searchEncode(key) {
    return key
    .replace(/%/g, "%25")
    .replace(/`/g, "%60")
    .replace(/#/g, "%23")
    .replace(/\^/g, "%5E")
    .replace(/&/g, "%26")
    .replace(/\+/g, "%2B")
    .replace(/\\/g, "%5C")
    .replace(/\|/g, "%7C")
    .replace(/}/g, "%7D")
    .replace(/]/g, "%5D")
    .replace(/{/g, "%7B")
    .replace(/\[/g, "%5B")
  },


  timeConversion(millisec) {
    const time = {};
    const seconds = Math.floor(millisec / 1000);
    time.days = Math.floor(seconds / 86400);
    time.hours = Math.floor(seconds / 3600 % 60);
    time.minutes = Math.floor(seconds / 60 % 60);
    time.seconds = seconds % 60;

    return time;
  },


}

 
 
 export { GlobalFunction };