/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { Typography, Button, Row, Col, Card, Popover} from 'antd';
import { ModalDataAkademik, ModalKetentuan, ModalBiodata, ModalUploadRaport } from '../Modals';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { confirmDialog2, dialog } from '../../../functions/alert';
import CONFIG from '../../../service/config';
import Auth from "../../../service/auth";
import Downloader from 'js-file-downloader';

const { Text, Title } = Typography
const formPernyataanMsg = `Form Pernyataan ini wajib di-download dan ditanda tangan oleh calon siswa/i dan orang tua. Form Pernyataan yang sudah ditandatangani oleh calon siswa/i dan orang tua wajib dikirimkan ke sekolah bersama berkas fisik (rapor, dsb.).`;
// const repayMessage = 'Transaksi anda sebelumnya sedang diproses. Dengan mengklik Ya, maka anda akan membatalkan pembayaran sebelumnya dan membuat pembayaran baru. Klik Ya jika setuju. Jika anda telah melakukan pembayaran, refresh halaman ini.';
const repayMessage = "Jika Anda sudah melunasi pembayaran, klik Ya untuk mengecek status pembayaran.\n Jika Anda belum melakukan pembayaran, klik Ya untuk membuat transaksi pembayaran baru dan membatalkan transaksi pembayaran sebelumnya."
const infoPembayaran = (
  <div style={{padding: '1em'}}>
    <Title level={4}>Pembayaran Melalui M-Banking/Perbankan</Title>
    <ol>
      <li>Lakukan pembayaran dengan kode pembayaran terbaru</li>
      <li>Pastikan Anda sudah menerima e-mail transaksi berhasil dari midtrans</li>
      <li>Refresh/Reload kembali halaman ini untuk update status pembayaran</li>
      <li>Klik kembali tombol BAYAR, jika <br />
        - Anda sudah melunasi pembayaran dan akan mengecek status pembayaran, atau <br />
        - Anda belum melakukan pembayaran dan akan membuat transaksi pembayaran baru <br />&nbsp;&nbsp;dimana transaksi pembayaran sebelumnya akan dibatalkan
      </li>
      <li>Jika berhasil melakukan pembayaran maka tombol BAYAR akan berubah menjadi LUNAS</li>
    </ol>
  </div>
);

const MemoizedModalBiodata = React.memo(ModalBiodata); 

const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
};

let orderId = ''

function generateToken(userId) {
  return new Promise((resolve, reject) => {
    Axios.post(CONFIG.BASE_URL + '/api/getTokenPembayaran?siswaId=' + userId)
    .then(res => resolve(res)).catch(err => reject(err));
  })
}

function insertOrder(siswaId, orderId) {
  return new Promise((resolve, reject) => {
    Axios.put(CONFIG.BASE_URL+`/api/insert/OrderId`,{siswaId: siswaId, orderId: orderId}, config).then(res => {
      resolve(res)
    }).catch(err => reject(err))
  })
}

export function loadMasterDataAkademik () {
  const listDataKey = ['listMatpel', 'listEkskul', 'listPenilaian', 'listKehadiran'];
  return new Promise((resolve, reject) => {
    Axios.all([
      Axios.get(CONFIG.BASE_URL + '/api/calonSiswa/matpel',config),
      Axios.get(CONFIG.BASE_URL + '/api/calonSiswa/ekstrakurikuler',config),
      Axios.get(CONFIG.BASE_URL + '/api/calonSiswa/penilaianSikap',config),
      Axios.get(CONFIG.BASE_URL + '/api/calonSiswa/jenisKehadiran',config),
    ]).then((data) => {
      let newMasterData = {}; 
      listDataKey.forEach((key, index) => {
        newMasterData[key] = data[index].status === 200 ? data[index].data.data : [];
      })
      resolve(newMasterData);
    })
    .catch(err => reject(err))
  })
}

export async function loadMasterDataDiri() {
  const listDataKey = ["agama", "suku", "pendidikanTerakhir"];
  return new Promise((resolve, reject) => {
    Axios.all([
      Axios.get(CONFIG.BASE_URL_NOAUTH + '/api/noAuth/masterAgama'),
      Axios.get(CONFIG.BASE_URL_NOAUTH + '/api/noAuth/masterSuku'),
      // Axios.get(CONFIG.BASE_URL_NOAUTH + '/api/noAuth/masterSMP'),
      Axios.get(CONFIG.BASE_URL_NOAUTH + '/api/noAuth/lov/pendidikanTerakhir')
    ]).then(data => {
      let newMasterData = {}; 
      listDataKey.forEach((key, index) => {
        newMasterData[key] = data[index].status === 200 ? data[index].data.data : [];
      })
      resolve(newMasterData);
    }).catch(err => reject(err))
  })
}

function InputData(props) {
  const [isModalShow, setIsModalShow] = useState({});
  const [modalStatus, setModalStatus] = useState({
    dataDiri: {isDone: false, progress: 0},
    dataAkademik: {isDone: false, progress: 0},
    transkripNilai: {isDone: false, progress: 0},
    pembayaran: {
      isDone: false,
      isPending: false
    }
  });
  const [isPaymentPending, setIsPaymentPending] = useState(false);
  const [masterData, setMasterData] = useState({});
  const [isDisabled, setIsDisabled] = useState();
  

  useEffect(() => {
    if (Object.values(props.dataStatus || {}).length > 0) {
      const {dataDiri, dataAkademik, transkipNilai, pembayaranRegistrasi, isPending} = props.dataStatus;
      // let currentPending = isPending || parseInt(sessionStorage.getItem('isPending')); 
      if (dataDiri && !dataAkademik) {
        loadMasterDataAkademik().then(masterData => {
          updateMasterData('dataAkademik', masterData)
        }).catch(err => {})
      }
      setModalStatus({
        ...modalStatus,
        dataDiri: {...modalStatus.dataDiri, isDone: dataDiri},
        dataAkademik: {...modalStatus.dataAkademik, isDone: dataAkademik},
        transkripNilai: {...modalStatus.transkripNilai, isDone: transkipNilai},
        pembayaran: { 
          ...modalStatus.pembayaran, 
          isDone: pembayaranRegistrasi,
          isPending: isPending
        },
      });
      setPending(isPending);
    } else {
      loadMasterDataDiri().then(masterData => {
        updateMasterData('dataDiri', masterData)
      }).catch(err => {
        dialog({title: 'Gagal memuat master data', icon: 'error'});
      })
    }
  }, [props.dataStatus])

  useEffect(()=>{
    const isDisabled = sessionStorage.getItem('adminConfig') && JSON.parse(sessionStorage.getItem('adminConfig'))
    setIsDisabled(isDisabled)
  },[sessionStorage.getItem('adminConfig')])

  const [isLoading, setIsLoading] = useState(false)

  const toggleModal = (key) => {
    let newIsShow = {};
    newIsShow[key] = (!isModalShow[key]);
    setIsModalShow({
      ...isModalShow,
      ...newIsShow
    });
  }

  const updateModalStatus = (key, progress = 0, isDone) => {
    return new Promise((resolve, reject) => {
      let newStatus = { ...modalStatus };
      newStatus[key] = {
        isDone: isDone || newStatus[key].isDone,
        progress: progress
      };
      setModalStatus(newStatus);
      resolve(newStatus);
    })
  }

  const updateMasterData = (key, data) => {
    let newData = {};
    newData[key] = data;
    setMasterData({
      ...masterData,
      ...newData
    });
  }

  const bayar = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const siswaId = sessionStorage.getItem('siswaId');
        const orderDetail = await generateToken(siswaId);
        orderId = orderDetail.data.orderId ? orderDetail.data.orderId : null
        if (!orderDetail.data.token) {
          resolve(['success', orderId])
        } else {
          await snap.pay(orderDetail.data.token, {
            onSuccess: (result) => {
              resolve(['success', orderId]);
            },
            onPending: (result) => {
              resolve(['pending', orderId, result]);
            },
            onError: (result) => {
              resolve([false, orderId, result]);
              result.status_code === 406 ? resolve([true, orderId]) : reject({status: result.status_code, message: result.status_message[0]});
            },
            onClose: () => {
              resolve([false, orderId]);
            }
          });
        }
      } catch (err) { reject(err); }
    })
  }

  const setPending = (status) => {
    console.log('isPending', status)
    sessionStorage.setItem('isPending', status);
    setIsPaymentPending(status);
  }

  const handleAjukanTahapAwal = async () => {
    props.onAjukanTahapAwal();
  }
 
  const InputStatus = ({modalKey, hidePercentage}) => { 
    return modalStatus[modalKey].isDone ? (
      <CheckCircleFilled style={{fontSize: '.8em', color: '#27ae60', marginLeft: '.5em'}} />
    ) : ( hidePercentage ||`(${modalStatus[modalKey].progress}%)`);
  }

  const handleBayar = async () => {
    setIsLoading('payment');
    const siswaId = sessionStorage.getItem('siswaId');
    await bayar().then(([paymentStatus, orderId, result]) => {
      if (paymentStatus === 'success') {
        dialog({closeOnClickOutside: false, title: 'Pembayaran Berhasil', icon: 'success'}).then(async (res) => {
          if(res){
            updateModalStatus('pembayaran', 100, true);
            props.onStatusChange();
          }
        });
        updateModalStatus('pembayaran', 100, true);
      } else if (paymentStatus === 'pending') {
        setPending(1);
        const msg = result['payment_type'] === 'gopay' ? 
        {title: 'Anda belum melakukan pembayaran dengan GoPay!', text: 'Klik tombol BAYAR untuk membuat transaksi pembayaran baru'} :
        {title: 'Silahkan lakukan pembayaran!', text: 'Informasi detail pembayaran dapat dilihat pada e-mail Anda'}
        dialog({closeOnClickOutside: false, icon: 'info', ...msg}).then(async (res) => {
          if(res){
            props.onStatusChange();
          }
        });
      } else {
        setPending(0);
      }
    }).catch(err => {
      if(err){
        dialog({title: 'Gagal memuat detail pembayaran', icon: 'error', text: err.response ? err.response.data.message : err.message});
      }
    })
    setIsLoading(false)
  } 

  const downloadForm = () => {
    setIsLoading('download')
    Axios.get(CONFIG.BASE_URL + '/api/downloadFormPernyataan?filePath=Form+Pernyataan+PPDB.pdf', config).then(res => {
      if(res.data){
        setIsLoading(false)
        const {url = ''} = res.data;
        dialog({title: 'Download Form Pernyataan', text: `Perhatian! ${formPernyataanMsg}`, icon: 'info', className: 'alert-justify'}).then(res => {
          if (res) {
            new Downloader({url: url}).then(file => {
            }).catch(err => {console.log(err)})
            // var win = window.open(url, '_blank');
            // win.focus();
            // fileDownload('Form Pernyataan PPDB.pdf', url);
          }
        });
      }
    }).catch(err => {
      if(err){
        setIsLoading(false)
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  return(
    <Col xs={24} md={24} lg={12}>
      <Card className="app-card" style={{height: '100%'}}>
        <Row style={{borderBottom: '1px solid #d9d9d9'}}>
          <Col span={16}><Title level={4}>Tahap Awal</Title></Col>
          <Col span={8}>
            <Button
              block
              type="link"
              onClick={() => toggleModal('ketentuan')}
            >
              KETENTUAN
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: '1em', width: '100%', alignItems: 'center'}}>
          <Col xs={24} sm={16}>
          <Text className="text-subtitle">
            Pengisian Biodata Diri <InputStatus modalKey="dataDiri" />
          </Text>
          </Col>
          <Col xs={24} sm={8} style={{textAlign: 'right'}}>
            <Button
              className="app-btn primary"
              style={{width: '100%'}}
              disabled={modalStatus.dataDiri.isDone}
              onClick={() => toggleModal('dataDiri')}
            >
              {/* { modalStatus.dataDiri.isDone ? 'EDIT' : 'ISI' } BIODATA */}
              ISI BIODATA
            </Button>
          </Col>                               
        </Row>
        <Row style={{marginTop: '1em', width: '100%', alignItems: 'center'}}>
          <Col xs={24} sm={16}>
            <Text className="text-subtitle">
              Pengisian Data Akademik <InputStatus modalKey="dataAkademik" />
            </Text>
          </Col>
          <Col xs={24} sm={8} style={{textAlign: 'right'}}>
            <Button
              className="app-btn primary"
              style={{width: '100%'}}
              disabled={!modalStatus.dataDiri.isDone || modalStatus.dataAkademik.isDone}
              onClick={() => toggleModal('dataAkademik')}
            >
              {/* { modalStatus.dataAkademik.isDone ? 'EDIT' : 'ISI' } NILAI */}
              ISI NILAI
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: '1em', width: '100%', alignItems: 'center'}}>
          <Col xs={24} sm={16}>
            <Text className="text-subtitle">
              Upload Scan Rapor Asli <InputStatus modalKey="transkripNilai" />
            </Text>
          </Col>
          <Col xs={24} sm={8} style={{textAlign: 'right'}}>
            <Button
              className="app-btn primary"
              style={{width: '100%'}}
              disabled={!modalStatus.dataAkademik.isDone || modalStatus.transkripNilai.isDone}
              onClick={() => toggleModal('transkripNilai')}
            >
              UPLOAD RAPOR
            </Button>
          </Col>              
        </Row>
        <Row style={{marginTop: '1em', width: '100%', alignItems: 'center'}}>
          <Col xs={24} sm={16}>
            <Text className="text-subtitle">
              Pembayaran Biaya Pendaftaran <InputStatus modalKey="pembayaran" hidePercentage={true} />
            </Text><br/>
            <Text style={{fontStyle: 'italic'}}>Jika melakukan transfer selain e-wallet 
              <Popover content={infoPembayaran}><Button type="text" style={{color: 'blue'}}>klik disini</Button></Popover>
            </Text>
          </Col>
          <Col xs={24} sm={8} style={{textAlign: 'right'}}>
            <Button
              className="app-btn primary"
              style={{width: '100%'}}
              onClick={() => {
                if (!!isPaymentPending) {
                  confirmDialog2({title: 'Peringatan !!!', subtitle: 'Membuat Transaksi Baru', text: repayMessage, icon: 'warning'})
                  .then(_ => {handleBayar();}).catch(_ => {})
                } else {
                  handleBayar();
                }
              }}
              disabled={!modalStatus.transkripNilai.isDone || modalStatus.pembayaran.isDone || isLoading === 'payment' || (props.jalurPenerimaan == 'Prestasi' && isDisabled && !isDisabled.prestasiBayar) || (props.jalurPenerimaan != 'Prestasi' && isDisabled && !isDisabled.tesBayar)}
            >
              {isLoading === 'payment' && <LoadingOutlined />}
              {!!modalStatus.pembayaran.isDone ? 'LUNAS' : 'BAYAR'}
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: '1em', width: '100%', alignItems: 'center'}}>
          <Col xs={24} sm={16}>
            <Text className="text-subtitle">
              Download Form Pernyataan
            </Text><br />
            <Text style={{fontStyle: 'italic'}}>Form Pernyataan wajib di-download</Text>
          </Col>
          <Col xs={24} sm={8} style={{textAlign: 'right'}}>
            <Button
              disabled={!Auth.isLogin() || isLoading === 'download' || !modalStatus.dataDiri.isDone}
              // type="link"
              className="app-btn primary"
              style={{width: '100%'}}
              onClick={() => downloadForm()}
            >
              {isLoading === 'download' && <LoadingOutlined />}
              DOWNLOAD
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: '1.5em', width: '100%', alignItems: 'center'}}>
          <Col span={24} style={{textAlign: 'right'}}>
            <Button
              className="app-btn primary"
              style={{width: '100%'}}
              onClick={handleAjukanTahapAwal}
              disabled={!modalStatus.pembayaran.isDone || (props.jalurPenerimaan == 'Prestasi' && isDisabled && !isDisabled.prestasiAjukanTahapAwal) || (props.jalurPenerimaan != 'Prestasi' && isDisabled && !isDisabled.tesAjukanTahapAwal)}
            >
              AJUKAN TAHAP AWAL
            </Button>
          </Col>                               
        </Row>
      </Card>
      <div>
      {/* masterData leng : {Object.keys(masterData.dataAkademik || {})} */}
        {!modalStatus.dataDiri.isDone && <MemoizedModalBiodata 
          visible={isModalShow.dataDiri}
          onClose={(percentage) => {toggleModal('dataDiri'); updateModalStatus('dataDiri', percentage, false)}}
          masterData={masterData.dataDiri}
          onFinish={() => {
            // updateModalStatus('dataDiri', 100, true); 
            toggleModal('dataDiri');
            window.location.reload();
            loadMasterDataAkademik().then(newMasterData => {
              updateMasterData('dataAkademik', newMasterData)
            }).catch(err => {
              dialog({title: 'Gagal memuat masterData', text: err.response ? err.response.data.message : err.message, icon: 'error'});
            })
          }}
          jalurPenerimaan={props.jalurPenerimaan}
        />}
       <ModalDataAkademik
          visible={isModalShow.dataAkademik}
          masterData={masterData.dataAkademik}
          onClose={(percentage) => {toggleModal('dataAkademik'); updateModalStatus('dataAkademik', percentage, false)}}
          onFinish={() => {
            // updateModalStatus('dataAkademik', 100, true); 
            props.onStatusChange()
            toggleModal('dataAkademik');
          }}
        />
        <ModalKetentuan 
          visible={isModalShow.ketentuan}
          onClose={() => toggleModal('ketentuan')}
          jalurPenerimaan={props.jalurPenerimaan}
        />
        <ModalUploadRaport
          visible={isModalShow.transkripNilai}
          onClose={(percentage) => {toggleModal('transkripNilai'); updateModalStatus('transkripNilai', percentage, false);}}
          onFinish={() => {
            // updateModalStatus('transkripNilai', 100, true);
            props.onStatusChange()
            toggleModal('transkripNilai');
          }}
        />
      </div>  
      {/* <pre>{JSON.stringify(modalStatus, '', 2)}</pre> */}
    </Col>
  )

}

export default InputData