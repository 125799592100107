import React, { useState, useEffect } from "react";
import { Route, Redirect, HashRouter, Switch } from "react-router-dom";
import "./app/css/global.css";
import HeaderLayout from './app/layouts/HeaderLayout'
import Login from './app/views/Login'
import ForgotPassword from './app/views/ForgotPassword/lupa-password'
import ResetPassword from './app/views/ForgotPassword/buat-password-baru'
import Dashboard from './app/views/Dashboard'
import Auth from "./app/service/auth";
import Pendaftaran from "./app/views/Pendaftaran";
import DaftarUlang from "./app/views/DaftarUlang";
import { AppFooter } from "./app/component/app-footer";
import { Layout } from "antd";
import UjianAkademik from "./app/views/UjianAkademik";
import Finance from './app/views/Finance'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    var isMobile = false
    var isLandscape = false
    let grid = {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false
    }
    let responsive = {}
    
    if( width < 991 ){
      isMobile = true
    }
    if( width < 576 ){
      isLandscape = false
    }
    if( width > 576 ){
      isLandscape = true
    }
    //grid
    if( width < 768){
      grid.xs = true
    }
    if( width >= 768){
      grid.sm = true
    }
    if( width >= 992){
      grid.md = true
    }
    if( width >= 1200){
      grid.lg = true
    }
    if( width >= 1600){
      grid.xl = true
    }
    
    responsive = {
      width,
      height,
      isMobile: isMobile,
      isLandscape: isLandscape,
      grid: grid
    }
    return responsive;
}

function PrivateRoute({ component: Component, render, path, ...rest }) {
  return (
    <Route
      path={path}
      render={({ location }) =>
        Auth.isLogin() ? ( render ? render() : <Component  /> ) : 
        ( <Redirect to={{ pathname: "/", state: { from: location } }} /> )
      }
      {...rest}
    />
  );
}

function Routes() {
  const [, setIsLogin] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
  useEffect(() => {
    setIsLogin(Auth.isLogin())
  }, []) 

  const handleLogin = () => {
    setIsLogin(true);
  }


  return (
    <Layout>
      <HashRouter>
        <React.Fragment>
          {/* <HeaderLayout userName={sessionStorage.getItem("userName")} logout={logout} />  */}
          {/* <Route exact path="/" component={DaftarRoute} /> */}
          <Route exact path="/" render={props => <Login name="Login" onLogin={handleLogin} {...props}/>} /> 
          <Route exact path="/login" render={props => <Login name="Login" onLogin={handleLogin} {...props}/>} />
          <Route exact path="/pendaftaran/" component={DaftarRoute} />
          <Route exact path="/ujian-akademik" component={DaftarRoute} />
          <Route exact path="/pendaftaran/:jalur" component={() => DaftarRoute(windowDimensions)} /> 
          <Route exact path="/lupa-password" component={ForgotPassword} />
          <Route exact path="/ubah-password" component={ResetPassword} />
          {/* <Route path='**' exact component={() => (<div>NOT FOUND</div>)} /> */}
        </React.Fragment>
      </HashRouter>
      <AppFooter />
    </Layout>
  )

}

export default Routes;


function DaftarRoute(responsive) {
  return (
    <HeaderLayout userName={sessionStorage.getItem("userName")}>
      <Switch>
        {/* <Route exact path={`/`} render={props => <Dashboard name="Dashboard" {...props} />}  /> */}
        <Route exact path={`/pendaftaran/`} render={props => <Dashboard name="Dashboard" {...props} />}  />
        <Route exact path={`/pendaftaran/jalur-tes-online`} render={props => <Pendaftaran name="Tes Online"  jalurPenerimaan="Jalur Tes" {...props} />}  />
        <Route exact path={`/pendaftaran/jalur-prestasi`} render={props => <Pendaftaran name="Prestasi" jalurPenerimaan="Prestasi"  {...props} />} />
        <PrivateRoute exact path={`/pendaftaran/daftar-ulang`} component={DaftarUlang} />
        <PrivateRoute exact path={`/pendaftaran/finance`} render={props => <Finance name="PPDB SMA Kolese Loyola" {...props} responsive={responsive}/>} />
        {/* <Route exact path={`/daftarulang/jalur-prestasi`} render={props => <DaftarUlang name="Daftar Ulang"  jalurPenerimaan="Jalur Prestasi" {...props} />} /> */}
        <PrivateRoute exact path={`/pendaftaran/ujian-akademik`} render={props => <UjianAkademik name="Ujian Akademik" {...props} />} />
      </Switch>
    </HeaderLayout>
  )
}