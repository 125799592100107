export default class AppCache {

  static set(key, value) {
    const appCache = JSON.parse(localStorage.getItem('appCache') || '{}');
    appCache[key] = {
      ...appCache[key],
      ...value
    }
    localStorage.setItem('appCache', JSON.stringify(appCache));
  }

  static get(key) {
    const appCache = JSON.parse(localStorage.getItem('appCache') || '{}');
    return appCache[key] || {};
  }

  static clear() {
    localStorage.removeItem('appCache');
  }
}