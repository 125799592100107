import React, { useState } from 'react'
import { Modal, Typography, Form, Button, Input, Row, Col, Steps } from 'antd'


const { Title, Text } = Typography
const { Step } = Steps

const AturNama = () => {
  return (
    <Form>
      <Row gutter={10}>
        <Col span={24}>
          <Text>Nama Lengkap</Text>
          <Form.Item
            name="namaDepan"
            rules={[{
              required: true,
              message: 'Harap masukkan nama depan!'
            }]}
          >
            <Input className="input-modal" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>Nama Panggilan</Text>
          <Form.Item
            name="namaBelakang"
            rules={[{
              required: true,
              message: 'Harap masukkan nama belakang!'
            }]}
          >
            <Input className="input-modal" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const AturKontak = () => {
    return (
      <Form>
        <Row gutter={10}>
          <Col span={24}>
            <Text>Nomor Hp</Text>
            <Form.Item
              name="nomorHp"
              rules={[{
                required: true,
                message: 'Harap masukkan nomor hp!'
              }]}
            >
              <Input className="input-modal" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>E-mail</Text>
            <Form.Item
              name="email"
              rules={[{
                required: true,
                message: 'Harap masukkan E-mail!'
              }]}
            >
              <Input className="input-modal" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}

const BuatPass = () => {
    return (
      <Form>
        <Row gutter={10}>
          <Col span={24}>
            <Text>Password</Text>
            <Form.Item
              name="password"
              rules={[{
                required: true,
                message: 'Harap masukkan password!'
              }]}
            >
              <Input.Password className="input-modal" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Ulangi Password</Text>
            <Form.Item
              name="retypePass"
              rules={[{
                required: true,
                message: 'Harap masukkan ulang password!'
              }]}
            >
              <Input.Password className="input-modal" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
}

const steps = [
  {
    title: "Atur nama",
    content: <AturNama />
  },
  {
    title: "Atur kontak",
    content: <AturKontak />
  },
  {
    title: "Buat password",
    content: <BuatPass />
  }
];
  

const ModalRegister = props => {

  const [recent, setRecent] = useState(0)

  const next = () => {
    const current = recent + 1;
    // console.log(current)
    setRecent(current)
  }
  
  const prev = () => {
    const current = recent - 1;
    // console.log(current)
    setRecent(current)
  }

    

    return(
        <Modal
            destroyOnClose={true}
            visible={props.visible}
            className="form-modal-middle"
            footer={null}
            closable={true}
            onCancel={props.buttonCancel}
            centered
            width="800px"
        >
          <Title level={3}>
              Buat Akun Baru
          </Title>
          <Row gutter={15} style={{marginTop: 20}}>
              <Col span={8}>
                <Steps direction="vertical" current={recent}>
                  {steps.map(item => (
                    <Step key={item.title} title={item.title}/>
                  ))}
                </Steps>
              </Col>
              <Col span={16}>
                <div className="steps-content">{steps[recent].content}</div>
              </Col>
          </Row>
          <div className="steps-action">
            {recent > 0 && (
              <Button style={{ marginRight: 8 }} className="form-button-white" onClick={() => prev()}>
                Sebelumnya
              </Button>
            )}
            {recent < steps.length - 1 && (
              <Button className="app-button primary lg" onClick={() => next()}>
                Selanjutnya
              </Button>
            )}
            {recent === steps.length - 1 && (
              <Button
                type="primary"
                className="form-button"
                onClick={() => { props.buttonCancel(); setRecent(0) }}
              >
                Selesai
              </Button>
            )}
            
          </div>
        </Modal>
    )

}



export default ModalRegister