/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { PageHeader, Card, Tabs, Button, Checkbox, Spin, Row, Col, Space } from 'antd'
import { useState } from 'react'
import { DataDiri, DataOrtuWali, DataBakatMinat, DataKesehatan, DataAktivitas, UploadBerkas } from './components'
import { dummyData } from './dummy-data'
import AppCache from '../../service/cache'
import { debounce } from 'lodash'
import { confirmDialog, dialog } from '../../functions/alert'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import Axios from 'axios'
import CONFIG from '../../service/config';
import InfoCard from '../Pendaftaran/Cards/infoCard'
import Title from 'antd/lib/typography/Title'
import Text from 'antd/lib/typography/Text'
import { GlobalFunction } from '../../global/function'
import DaftarUlangService from '../../service/daftar-ulang-service'
import AdminConfig from '../../service/admin-config'
import Auth from '../../service/auth'
import { useHistory } from 'react-router-dom'

const tabList = [
  {
    title: "Data Diri",
    tabId: "dataDiri",
    Content: DataDiri
  },
  {
    title: "Data Orangtua/Wali",
    tabId: "dataOrtuWali",
    Content: DataOrtuWali
  },
  {
    title: "Pengumpulan Berkas",
    tabId: "berkas",
    Content: UploadBerkas
  },
  {
    title: "Data Kesehatan Siswa",
    tabId: "dataKesehatan",
    Content: DataKesehatan
  },
  {
    title: "Data Aktivitas Siswa",
    tabId: "dataAktivitas",
    Content: DataAktivitas
  },
  {
    title: "Bakat dan Minat Siswa",
    tabId: "dataBakatMinat",
    Content: DataBakatMinat
  },
];

const defaultValue = {
  dataDiri: {},
  dataBakatMinat: {},
  dataKesehatan: {},
  dataAktivitas: {},
  dataOrtuWali: {},
  berkas: {}
};

export default function DaftarUlang(props) {
  const history = useHistory();
  const [formValue, setFormValue] = useState(defaultValue);
  const [initValue, setInitValue] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isValidating, setIsValidating] = useState(0);
  const [validatingTarget, setValidatingTarget] = useState(null);
  const [isAgree, setIsAgree] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [statusDaftarUlang, setStatusDaftarUlang] = useState({status: false, bisaDaftarUlang: false, message: ''});
  const [showForm, setShowForm] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [timer, setTimer] = useState();
  const userData = JSON.parse(sessionStorage.getItem('user'));
  let debouncedFn;

  let jadwalKeys = [
    {key: 'hariTanggal', label: 'Hari, Tanggal'},
    {key: 'waktuMulaiPengumpulan', label: 'Waktu Mulai Pengumpulan'},
    {key: 'batasAkhirPengumpulan', label: 'Batas Akhir Pengumpulan'},
    {key: 'media', label: 'Media'},
  ];

  useEffect(() => {
    document.title = "PPDB SMA Kolese Loyola | Loyola"
    initialConfig()
  }, [])

  const initialConfig = () => {
    AdminConfig.getDataConfig().then((res)=>{
      if (Auth.isLogin()){
        const cachedData = Object.assign(defaultValue, AppCache.get('dataDaftarUlang'));
        setFormValue(cachedData);
        loadStatus();
        loadNoPPdb();
      } else {
        history.push('/login')
      }
    }).catch(err => {
      if(err.response){
        console.log('AdminConfig Error : ',err.response.data.message)
      }
    })
  }

  useEffect(()=>{
    const isDisabled = JSON.parse(sessionStorage.getItem('adminConfig')) || {};
    const userData = JSON.parse(sessionStorage.getItem('user')) || {};
    console.log('jalur : ',props.jalurPenerimaan)
    if(userData.jalurPenerimaan == 'Prestasi' && !isDisabled.prestasiLogin){ //login dan sistem login ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.prestasiLoginInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      history.push('/login')
    }else if(userData.jalurPenerimaan != 'Prestasi' && !isDisabled.tesLogin){ //login dan sistem login ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.tesLoginInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      history.push('/login')
    }
  },[sessionStorage.getItem('adminConfig')])

  useEffect(() => {
    const {jadwal} = statusDaftarUlang;
    if(jadwal) {
      const timeStampCountdown = new Date(jadwal.timestampCountdownDaftarUlang);
      if (timeStampCountdown && Date.now() < timeStampCountdown) {
        updateTimer(timeStampCountdown)
      } else {
        setIsStarted(true);
      }
    }
    return () => {
      clearInterval(timer);
    }
  }, [statusDaftarUlang])

  const updateTimer = (timeStampCountdown) => {
    clearInterval(timer)
    const timeLeft = timeStampCountdown;
    setTime(GlobalFunction.timeConversion(timeLeft - Date.now()));
    const newTimer = setInterval(() => {
      if (timeLeft - Date.now() <= 0) {
        clearInterval(timer);
        setIsStarted(true);
      } else {
        setTime(GlobalFunction.timeConversion(timeLeft - Date.now()));
      }
    }, 1000)
    setTimer(newTimer);
  }

  const loadStatus = async () => {
    setIsLoading(true);
    const siswaId = sessionStorage.getItem('siswaId');
    await DaftarUlangService.getStatusDaftarUlang(siswaId).then(res => {
      const {data} = res.data;
      setStatusDaftarUlang(data);
    }).catch(err => {
      const {message, response = {}} = err;
      const errMessage = response.data ? response.data.message : message 
      dialog({title: 'Gagal memuat status Daftar Ulang', text: errMessage, icon: 'error'})
    })
    setIsLoading(false);
  }

  const loadNoPPdb = () => {
    const siswaId = sessionStorage.getItem('siswaId');
    DaftarUlangService.getNoPpdb(siswaId).then(res => {
      const {noPpdb} = res.data;
      const cachedData = Object.assign(defaultValue, AppCache.get('dataDaftarUlang'));
      cachedData.dataDiri = {
        ...cachedData.dataDiri,
        value: cachedData.dataDiri.value ? {
          ...cachedData.dataDiri.value,
          noPpdb: noPpdb
        } : { noPpdb: noPpdb }
      };
      setIsLoading(false);
      setInitValue(cachedData);
    }).catch(err => {
      const {message, response = {}} = err;
      const errMessage = response.data ? response.data.message : message 
      dialog({title: 'Gagal memuat Nomor PPDB', text: errMessage, icon: 'error'});
    })
  }


  const saveData = async () => {
    setIsLoading(true);
    const {dataDiri: {value: {minatJurusan, noPpdb, ...datadiriVal}}, dataBakatMinat, dataKesehatan, dataAktivitas, dataOrtuWali, berkas} = formValue;
    const siswaId = sessionStorage.getItem('siswaId');
    const validDataOrtu = dataOrtuWali.value.value.filter(ortuWali =>  Object.values(ortuWali).length > 1);
    let body = {
      ...formValue,
      minatJurusan: minatJurusan,
      noPpdb: noPpdb,
      dataDiri: datadiriVal,
      dataBakatMinat: dataBakatMinat.value,
      dataKesehatan: dataKesehatan.value,
      dataAktivitas: dataAktivitas.value,
      dataOrtuWali: validDataOrtu, 
      berkas: berkas.value
    };
    // console.log(dataOrtuWali.value.value);

    const {fotoBantuOrtu, fotoKeluargaDepanRumah, fotoKeluargaRuangKeluarga} = body.dataAktivitas;

    body.dataAktivitas = {
      ...body.dataAktivitas,
      fotoBantuOrtu: fotoBantuOrtu.fileName,
      fotoKeluargaDepanRumah: fotoKeluargaDepanRumah.fileName,
      fotoKeluargaRuangKeluarga: fotoKeluargaRuangKeluarga.fileName
    }

    const {scanSkl, scanRaporSemester5, scanRaporSemester6, scanIjazah, pasFotoSmp} = body.berkas;

    body.berkas = {
      ...body.dataAktivitas,
      scanSkl: scanSkl ? scanSkl.fileName : '' , 
      scanRaporSemester5: scanRaporSemester5 ? scanRaporSemester5.fileName : '' , 
      scanRaporSemester6: scanRaporSemester6 ? scanRaporSemester6.fileName : '' , 
      scanIjazah: scanIjazah ? scanIjazah.fileName : '' , 
      pasFotoSmp: pasFotoSmp ? pasFotoSmp.fileName : '' 
    }
    
    try {
      await DaftarUlangService.saveDaftarUlang(siswaId, body);
      dialog({text: 'Data Daftar Ulang berhasil disimpan', icon: 'success'});
      setShowForm(false);
      setIsLoading(false);
      loadStatus();
    } catch (err) {
      const {message, response = {}} = err;
      const errMessage = response.data ? response.data.message : message 
      dialog({title: 'Gagal menyimpan Data Daftar Ulang', text: errMessage, icon: 'error'});
      setIsLoading(false);
    }
  }

  const handleChange = (key, value) => {
    const newValue = { ...formValue }
    newValue[key] = Object.assign(newValue[key] || {}, value)
    AppCache.set('dataDaftarUlang', newValue);
    setFormValue(newValue);
    
    if(isValidating && !validatingTarget) {
      if (!debouncedFn) {
        debouncedFn = debounce(async () => {
          validateData(newValue).then(val => {
            confirmDialog({title: 'Simpan data daftar ulang?', text: 'Apakah Anda yakin ingin menyimpan data daftar ulang Anda? \n Data yang telah disimpan tidak dapat diubah kembali.'}).then(_ => {
              saveData();
            }).catch(err => {})
          }).catch(err => {
            // Handling invalid form and listing invalid form
            let fields = '';
            err.forEach((key, i) => {
              const tabItem = tabList.find(e => e.tabId === key);
              fields += tabItem.title + (err.length > i+1 ? ', ' : '');
            })
            dialog({
              title: 'Form belum lengkap',
              text: `Form belum terisi dengan benar, harap periksa kembali \n (${fields})`,
              icon: 'error'
            });
          });
          setIsValidating(0);
        }, 1000)
      }
      debouncedFn()
    } else if(isValidating && validatingTarget) {
      const formId = tabList[activeTab].tabId;
      const isValid = !!formValue[formId].valid;
      if (isValid) {
        setActiveTab(activeTab + 1);
        setIsValidating(0);
      }
      setIsValidating(0);
      setValidatingTarget(null);
    }
  }

  const validateData = (data) => {
    return new Promise((resolve, reject) => {
      let keys = Object.keys(data);
      keys = keys.filter(e => {
        return !data[e].valid;
      });
      if (keys.length > 0) { reject(keys); }
      else { resolve(true); }
    })
  }

  const autofill = () => {
    setInitValue({...formValue, ...dummyData});
  }

  const handleSave = () => {
    setIsValidating(isValidating+1)
  }

  const handleNext = () => {
    const formId = tabList[activeTab].tabId;
    setIsValidating(isValidating+1);
    setValidatingTarget(formId);
  }

  const handlePrev = () => {
    setActiveTab(activeTab - 1);
  }

  const handleStart = () => {
    setShowForm(true);
  }
  
  let {jadwal, statusAdministrasi, bisaDaftarUlang, tahunAjaran = '2021 / 2022', isLate} = statusDaftarUlang;
  statusAdministrasi = !statusAdministrasi || (!bisaDaftarUlang && !statusDaftarUlang.statusDaftarUlang && !isLate ) ? {
    statusDescription: 'Jadwal Daftar Ulang belum tersedia.',
    statusMessage: 'Mohon menunggu informasi lebih lanjut. Cek e-mail dan akun PPDB Anda secara berkala.'
  } : statusAdministrasi;
  const isLocalLink = jadwal ? [
    'https://ppdb.yayasanloyola.org/#/',
    'https://ppdb.yayasanloyola.org/',
    'https://ppdb.yayasanloyola.org',
    'ppdb.yayasanloyola.org',
  ].includes(jadwal.link || '') : false;
  jadwalKeys = jadwal && jadwal.media.toLowerCase() === 'online' ? (
    isLocalLink ? jadwalKeys : [
        ...jadwalKeys,
        {key: 'link', label: 'Link'},
      ]
  ) : [
    ...jadwalKeys,
    {key: 'lokasi', label: 'Lokasi'},
  ];
  // const tahunAjaran = '2020 / 2021';
  
  return (
    <div className="container" style={{paddingBottom: '80px'}}>
      <Spin spinning={isLoading}>
        <PageHeader
          className="site-page-header"
          title={userData.jalurPenerimaan === "Jalur Tes" ? 'Jalur Tes' : 'Jalur Prestasi'}
        />
        {userData && <InfoCard user={userData} style={{marginBottom: '30px'}}/>}
        <Card style={{minHeight: '400px'}}>
          <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: '1em'}}>
            <Title level={4}>Daftar Ulang</Title>
          </div>
          {showForm ? (
            <div>
              <Tabs type="card"  className="app-tab-card app-tabs nodisable" activeKey={`${activeTab}`} onTabClick={e => {setActiveTab(e)}} style={{marginTop: 0}}>
                {tabList.map(({title, tabId, Content}, index) => (
                  <Tabs.TabPane
                    tab={<span> {activeTab > index ? <CheckCircleOutlined style={{color: '#52c41a'}} /> : ''} {title}</span>}
                    forceRender={true}
                    key={index}
                    tabKey={index} 
                    disabled={true} 
                    style={{cursor: 'default', color: '#000000'}}
                  >
                    <Content 
                      contentIndex={index}
                      curentIndex={activeTab}
                      onChange={handleChange}
                      initValue={initValue[tabId]}
                      formTitle={title}
                      isValidating={!!validatingTarget ? (validatingTarget === tabId ? isValidating : null) : isValidating}
                      formId={tabId}
                      userData={userData}
                      handleNext={handleNext}
                      handlePrev={handlePrev}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>
              {activeTab >= (tabList.length - 1)  && (
                <div style={{padding: '1em 1.5em 0em'}}>
                  <Checkbox checked={isAgree} style={{fontSize: '1.1em'}} onClick={(ev) => setIsAgree(!isAgree)}>
                    Saya menyatakan mendaftar sebagai calon siswa baru SMA  Kolese Loyola Semarang tahun  pelajaran  {tahunAjaran}.
                  </Checkbox>
                </div>
              )}
              <div className="actions" style={{margin: '1em 0px 0px', display: 'flex', justifyContent: 'right'}}>
              {CONFIG.isServer !== 'Production' && (
                <Button className="app-btn" onClick={_ => autofill()} style={{ padding: '.75em 5em'}}>Auto-fill</Button>
              )}
                {activeTab > 0 && (
                  <Button className="app-btn primary" onClick={_ => handlePrev()} style={{ padding: '.75em 1.5em', marginLeft: '1em'}}>
                    Sebelumnya
                  </Button>
                )}
                {activeTab < (tabList.length - 1) && (
                  <Button className="app-btn primary" onClick={handleNext} disabled={!!isValidating} style={{ padding: '.75em 1.5em', marginLeft: '1em'}}>
                    {(!!isLoading || !!isValidating) && <LoadingOutlined />}
                    Selanjutnya
                  </Button>
                )}
                {activeTab >= (tabList.length - 1)  && (
                  <Button className="app-btn primary" onClick={_ => handleSave()} style={{ padding: '.75em 5em', marginLeft: '1em'}} disabled={!!isValidating || !isAgree}>
                    {(!!isLoading || !!isValidating) && <LoadingOutlined />}
                    Kirim
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div style={{padding: '1em 0px 0px'}}>
              {!jadwal || statusDaftarUlang.statusDaftarUlang ? (
                <div style={{textAlign: 'center', padding: '4em 0px 5em'}}>
                  <Title level={4}>{statusAdministrasi.statusDescription}</Title>
                  <Text style={{fontSize: '1.2em'}}>{statusAdministrasi.statusMessage}</Text>
                </div>
              ) : (
                <div style={{}}>
                  <Title level={4}>Jadwal Pelaksanaan Daftar Ulang</Title>
                  <table className="layout-table" style={{fontSize: '1.1em'}}>
                    {jadwalKeys.map(({key, label}) => {
                      return (
                      <tr>
                        <td span={6}>{label}</td>
                        <td style={{fontWeight: 'bold', fontSize: '1.2em', padding: '0px 1em'}}>:</td>
                        {key !== 'link' ? 
                          <td>{jadwal[key]}</td> : 
                          <td><a href={jadwal[key]} target="_blank">{jadwal[key]}</a></td>
                        }
                      </tr>
                    )})}
                  </table>
                  {(jadwal.media === 'Online' || jadwal.media === 'online') && isLocalLink ? (
                    <Space className="justify-between" style={{marginTop: '1em'}}>
                      <div style={{fontSize: '1.1em'}}>
                        <div>{isStarted ? 'Daftar Ulang sedang berlangsung' : 'Daftar Ulang dimulai dalam :'}</div>
                        {time && ( 
                          <div style={{fontWeight: 'bold', color: '#072a6f', fontSize: '1.2em'}}>
                            {time.days + ' Hari '}
                            {time.hours + ' Jam '}
                            {time.minutes + ' Menit '}
                            {time.seconds + ' Detik '}
                          </div>
                        )}
                      </div>
                      <Button className="app-btn primary" style={{padding: '.5em 3em'}} onClick={handleStart} disabled={!isStarted}>
                        Daftar
                      </Button>
                    </Space>
                  ) : ''}
                </div>
              )}
            </div>
          )}
          {/* <pre style={{margin: '2em 0px 0em', padding: '1em', background: '#eaeaea'}}>FormValue : {JSON.stringify(formValue, '', 2)}</pre> */}
          {/* <pre style={{margin: '2em 0px 0em', padding: '1em', background: '#eaeaea'}}>activeTab : {activeTab}</pre> */}
        </Card>
      </Spin>
    </div>
  )
}
