/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col, Card, Typography, PageHeader, Spin } from 'antd';
import ModalDashboard from './modal';
import Auth from '../../service/auth';
import { dialog } from '../../functions/alert';
import AdminConfig from '../../service/admin-config';

const { Text } = Typography;

const Dashboard = props => {
    const [isModalShow, setModalShow] = useState(false)
    const [status, setStatus] = useState('prestasi')
    const [isLoading, setLoading] = useState(true)
    
    useEffect(() => {
      document.title = props.name + " | Loyola"
    }, [props.name])

    useEffect(() => {
      initialConfig()
    }, [])

    const initialConfig = () => {
      AdminConfig.getDataConfig().then((res)=>{
        if (Auth.isLogin()) {
          const user = JSON.parse(sessionStorage.getItem('user'));
          props.history.push(user.jalurPenerimaan === 'Prestasi' ? '/pendaftaran/jalur-prestasi' : '/pendaftaran/jalur-tes-online')
        }
        setLoading(false)
      }).catch(err => {
        if(err.response){
          console.log('AdminConfig Error : ',err.response.data.message)
        }
      })
    }

    const showModal = (params) => {
      sessionStorage.removeItem('signature');   
      const isDisabled = sessionStorage.getItem('adminConfig') && JSON.parse(sessionStorage.getItem('adminConfig'))
      if(params && params == 'prestasi' && !isDisabled.prestasiDaftar){
        dialog({title: 'Pemberitahuan', text: `${isDisabled.prestasiDaftarInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
        initialConfig()
      }else if(params && params !== 'prestasi' && !isDisabled.tesDaftar){
        dialog({title: 'Pemberitahuan', text: `${isDisabled.tesDaftarInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
        initialConfig()
      }else{
        setModalShow(!isModalShow)
        setStatus(params)
      }
    }

    const goToNextStage = () => {
      if(status === 'prestasi'){
        props.history.push('/pendaftaran/jalur-prestasi')
      } else if (status === 'tes-online') {
        props.history.push('/pendaftaran/jalur-tes-online')
      }
    }

    return (
      <div>
        <PageHeader
          className="site-page-header"
          title="Pilih Jalur Pendaftaran"
        />
        <Spin spinning={isLoading}>
            <Row justify="center" gutter={[30, 60]}>
            <Col lg={12} span={24}>
              <Card className="card-prestasi" onClick={() => showModal('prestasi')}>
                <div className="card-content">
                  <Row>
                    <Text className="text-title" style={{color: '#fff'}}>Prestasi</Text>
                  </Row>
                  <Row>
                    <Text className="text-subtitle" style={{color: '#fff'}}>Punya keunggulan dari yang lain di bidang akademik atau non-akademik? Coba jalur prestasi</Text>
                  </Row>
                </div>
              </Card>
              <ModalDashboard
                visible={isModalShow}
                buttonCancel={()=>showModal()}
                pressed={()=>goToNextStage()}
              />
            </Col>
            <Col lg={12} span={24}>
              <Card className="card-tes" onClick={() => showModal('tes-online')}>
                <div className="card-content">
                  <Row>
                    <Text className="text-title" style={{color: '#fff'}}>Tes</Text>
                  </Row>
                  <Row>
                    <Text className="text-subtitle" style={{color: '#fff'}}>Mari perjuangkan mimpimu untuk menjadi bagian dari SMA Kolese Loyola Semarang</Text>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Spin>
    </div>
  )

}

export default withRouter(Dashboard)
