/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Typography, Button, Row, Col, Card, Space} from 'antd';
import { ModalDataAkademik, ModalBiodata } from '../Modals';
import { loadMasterDataAkademik, loadMasterDataDiri } from './inputData';
import { dialog } from '../../../functions/alert';
import { LoadingOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const colorCode = {
  23: '#072A6F',
  24: '#072A6F',
  25: '#EB5757', //tidak lolos verifikator
  6: '#EB5757', //tidak lolos eksekutif
  26: '#219653', //lolos verifikator
  3: '#219653', //lolos eksekutif
}

const VerifikasiData = props => {
  const [isShowModalRevisi, setIsShowModalRevisi] = useState(false);
  const [isShowModalBiodata, setIsShowModalBiodata] = useState(false);
  const [masterData, setMasterData] = useState({});
  const [isDisabled, setIsDisabled] = useState();
  // const [isPindahJalur, setIsPindahJalur] = useState(false)
  const [statusTahapAwal, setStatusTahapAwal] = useState({statusAdministrasi: {}}); 
  const {statusAdministrasi: {statusId, statusName, statusDescription, statusMessage}, revisiDataDiri, revisiDataAkademik} = statusTahapAwal;

  async function loadMasterData() {
    // if (props.status !== 'Revisi Data Input') {return}
    const newMasterData = {};
    try {
      newMasterData.dataAkademik = await loadMasterDataAkademik();
      newMasterData.dataDiri = await loadMasterDataDiri();
      setMasterData(newMasterData);
    } catch (err) {
      dialog({title: 'Gagal memuat Master Data', icon: 'error'});
      console.log(err);
    }
  }

  useEffect(()=>{
    const isDisabled = sessionStorage.getItem('adminConfig') && JSON.parse(sessionStorage.getItem('adminConfig'))
    setIsDisabled(isDisabled)
  },[sessionStorage.getItem('adminConfig')])

  useEffect(() => {
    if (!!props.status) {
      console.log('Status: ', props.status)
      const newStatus = {...statusTahapAwal, ...props.status}
      if(!newStatus.statusAdministrasi) { newStatus.statusAdministrasi = {}}
      setStatusTahapAwal(newStatus);
      if (props.status.statusAdministrasi.statusId === 24) {
        loadMasterData()
      }
    }
  }, [props.status])

  const toggleModalAkademik = () => {
    setIsShowModalRevisi(!isShowModalRevisi);
  }

  const toggleModalBiodata = () => {
    setIsShowModalBiodata(!isShowModalBiodata);
  }

  const handleFinishRevisiAkademik = () => {
    toggleModalAkademik();
    window.location.reload();
  }

  const handleFinishRevisiBiodata = () => {
    toggleModalBiodata();
    window.location.reload();
  }

  const handlePindahJalur = () => {
    props.onPindahJalur()
  }


  return(
    
    <Col md={24} lg={statusId === 6 ? 24 : 12} style={{minHeight: '400px'}}>
      <Card className="app-card" style={{height: '100%'}}>
        <Row style={{borderBottom: '1px solid #d9d9d9'}}>
          <Col span={19}><Title level={4}>Tahap Awal</Title></Col>
        </Row>
        <Row style={{marginTop: 20, width: '100%'}}>
          <Col span={24}>
            <Title level={3} style={{color: colorCode[statusId]}}>STATUS: {statusDescription}</Title>
          </Col>
          <Col span={24}>
            <Title level={4}>{statusMessage}</Title>
          </Col>
          {statusId === 24 ? (
            <Col span={24}>
              {!!revisiDataAkademik && <Button type="link" onClick={toggleModalAkademik}>
                Revisi Data Akademik
              </Button>}
              {!!revisiDataDiri && <Button type="link" onClick={toggleModalBiodata}>
                Revisi Data Diri
              </Button>}
            </Col>
          ) : (statusId === 25 || statusId === 6) && props.jalurPenerimaan === 'Prestasi' ? (
            <Col span={24} style={{textAlign: 'right'}}>
              <Button
                block
                className="app-btn primary"
                disabled={(isDisabled && !isDisabled.prestasiPindahJalur) || props.isPindahJalur}
                onClick={handlePindahJalur} >
                {props.isPindahJalur && <LoadingOutlined />}
                Ikuti Pendaftaran Jalur Tes
              </Button>
            </Col>  
          ) : statusId === 26 ? (
            <Col span={24}>
              <Space>
                <Text block type="text" style={{color: '#072A6F', fontSize: '1.2em'}}>
                </Text>
              </Space>
            </Col>
          ) : (<></>)}
        </Row>
      </Card>
    {statusId === 24 && (
      <>
        <ModalDataAkademik
          masterData={masterData.dataAkademik}
          isRevisi={true}
          visible={isShowModalRevisi}
          onClose={toggleModalAkademik}
          onFinish={handleFinishRevisiAkademik}
          isRevisiOther={props.status.revisiDataDiri}
        />
        <ModalBiodata
          masterData={masterData.dataDiri}
          visible={isShowModalBiodata}
          isRevisi={true}
          onClose={() => toggleModalBiodata() }
          onFinish={handleFinishRevisiBiodata}
          jalurPenerimaan={props.jalurPenerimaan}
          isRevisiOther={props.status.revisiDataAkademik}
        />
      </>
    )}
  </Col>
  );

}

export default VerifikasiData