import { CheckCircleFilled } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'

export default function UjianSelesai(props) {
  return (
    <Row style={{justifyContent: 'center'}}>
      <Col span={24} xxl={6} xl={12} lg={18} md={20}>
        
        <Card className="appCard" style={{textAlign: 'center', padding: '1em .5em'}}>
          <CheckCircleFilled style={{color: '#27AE60', fontSize: '5em', marginBottom: '.5em'}} />
          <Title level={3}>Jawaban Anda Sudah Tersimpan</Title>
          <div style={{textAlign: 'left', fontSize: '1.2em', margin: '2em 0em 2em'}}>
              Terima kasih sudah mengikuti ujian akademik {props.matpel}.
          </div>
          <Button className="app-btn" onClick={() => props.onBack()} style={{color: '#ffffff', fontWeight: '1.2em', width: '100%', background: '#072A6E'}}>Kembali ke halaman ujian</Button>
        </Card>
      </Col>
    </Row>
  )
}
