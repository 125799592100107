import Axios from 'axios';
import Auth from './auth';

const securedPort = ['9015', '9016']

Axios.interceptors.request.use((config) => {
  const url = config.url;
  // console.log('requesting to', url)
  // if ((url.split(':')).length > 1) {
  //   const port = (url.split(':')[2]).split('/')[0];
  //   if (securedPort.indexOf(port) >= 0) {
      const token = sessionStorage.getItem('token');
      // console.log('token', token)
      if (!!token)
      config.headers.Authorization = `Bearer ${token}`;
  //   }
  // }
  // console.log('request passed: ', config)
  return config;
});

Axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    let messageErrorResponse = error.response && error.response.data && error.response.data.error || ''
    let messageErrorStatus = error.response && error.response.status || ''
    if(messageErrorResponse == 'Unauthorized' || messageErrorStatus == 401){
        Auth.logout()
        window.location = '/';
        // window.location.reload(false)
    }
    return Promise.reject(error);
  });