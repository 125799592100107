export const dummyData = {
  "dataDiri": {
    "value": {
      "noPpdb": "202002029",
      "namaLengkap": "Mey mey",
      "email": "hehe@gmail.com",
      "smp": "SMPN 2 Semarang",
      "nisn": "14512312312",
      "minatJurusan": "IPA",
      "agama": "Islam",
      "suku": "Batak karo",
      "jenisKelamin": 1,
      "noHp": "03128039012",
      "idLine": "@lineaidi",
      "alamatTempatTinggal": "jalan merah jambu",
      "alamatKos": "jalan hijau daun"
    },
    "valid": true
  },
  "dataBakatMinat": {
    value: {
      "bakatAlatMusik": "Mampu memainkan angklung",
      "bakatSeni": "Memiliki bakat menari jaipong",
      "bakatOlahraga": "Bermain pencak silat",
      "bakatFoto": "Mampu menggunakan aplikasi Photoshop"
    },
    "valid": true
  },
  "dataKesehatan": {
    "value": {"sedangCideraSakit": false},
    "valid": false,
  },
  "dataAktivitas": {
    "valid": false,
    value: {
      "koordinatRumah": "-6.981849, 110.425988",
      "fotoBantuOrtu": {
        "fileName": "scanSkl/scanSkl_1599635513.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-27",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
      "fotoKeluargaRuangKeluarga": {
        "fileName": "scanSkl/scanSkl_1599635513.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-27",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
      "fotoKeluargaDepanRumah": {
        "fileName": "scanSkl/scanSkl_1599635513.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-27",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
    }
  },
  "dataOrtuWali": {
    "value": {
      "value": [
        {
          "kodeOrtu": 1,
          "namaLengkap": "Mr. Hey hey",
          "alamatTempatTinggal": "Jalan hey hey",
          "pekerjaan": "Hay producer",
          "alamatKantor": "jalan hey hey",
          "email": "mr.heyhey@gmail.com",
          "noTelpRumah": "08902930123",
          "noHpWa": "14802193801293"
        },
        {
          "kodeOrtu": 2
        },
        {
          "kodeOrtu": 3
        }
      ]
    },
    "valid": true
  },
  "berkas": {
    value: {
      "scanSkl": {
        "fileName": "scanSkl/scanSkl_1599635513.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-27",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
      "scanIjazah": {
        "fileName": "scanIjazah/scanIjazah_1599635834.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635815706-17",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
      "scanRaporSemester5": {
        "fileName": "raporKelas9Sem1/raporKelas9Sem1_1599635531.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-33",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
      "scanRaporSemester6": {
        "fileName": "raporKelas9Sem2/raporKelas9Sem2_1599635529.pdf",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-31",
            "lastModified": 1597119978744,
            "name": "download - Copy.pdf",
            "size": 2708,
            "type": "application/pdf",
            "percent": 0
          }
        ]
      },
      "pasFotoSmp": {
        "fileName": "passFotoSmp/passFotoSmp_1599635564.jpg",
        "fileList": [
          {
            "uid": "rc-upload-1599635148400-37",
            "lastModified": 1597119978744,
            "name": "00.jpg",
            "size": 2708,
            "type": "image/jpeg",
            "percent": 0
          }
        ]
      }
    }
  }
}