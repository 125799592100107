/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, InputNumber, Alert } from 'antd'
import { CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import _ from 'lodash'
import { countValidProp } from '../../../../functions/object';


const borderedCellStyle = {borderBottom: "1px solid #e0e0e0", marginBottom: '1em'};
const inputNumberConfig = {min: 0, max: 100, maxLength: 4, style: {width: "100%" }}


export function NilaiPengetahuan(props) {
  const [form] = Form.useForm();
  const {initValue = {value: [], valid: false}, listMatpel = [], onChange} = props
  let value = form.getFieldsValue();
  let debouncedFn;

  useEffect(() => {
    if (!!listMatpel) {
      let defaultField;
      defaultField = {
        value: (initValue.value && initValue.value.length > 0) ? initValue.value : listMatpel.map(matpel => ({matpelId: {matpelId : matpel.matpelId}}) ),
        valid: !!initValue.valid
      };
      form.setFieldsValue(defaultField);
      value = defaultField
    }
  }, [initValue, listMatpel]);

  const handleChange = async (event) => {
    if (props.isRevisi) { await updateVerifikasiStatus(event); }
    if (!debouncedFn) {
      debouncedFn =  _.debounce(async  () => {
        let val = form.getFieldsValue();
        await form.validateFields().then(_ => {
          val.valid = props.isRevisi ? ((val.value).filter(e => !e.verifikasi).length === 0) : true
        }).catch(_ => val.valid = false);
        onChange(val);
      }, 1000);
    }
    debouncedFn();
  }

  const updateVerifikasiStatus = async (event) => {
    let val = Object.values(event.value);
    if (val.length > 0) {
      val = val[0];
      const valueKey = event.value.indexOf(val);
      let newValue = form.getFieldsValue();
      newValue.value[valueKey].verifikasi = true;
      form.setFieldsValue(newValue);
    }
  }
  
  return (
    <Form form={form} onValuesChange={handleChange}>
      <div className="ant-table app-table">
        <div className="ant-table-container">
          <div className="ant-table-content" style={{overflowY: 'auto', paddingBottom: '1em'}}>
            <table style={{fontWeight: "bold", fontSize: "1.2em"}}>
              <thead style={{textAlign: "center"}}>
                <tr>
                  <td style={{...borderedCellStyle, width: "30%", textAlign: "left"}} rowSpan={2} >Mata Pelajaran</td>
                  <td style={{...borderedCellStyle, width: "60px", textAlign: "right"}} rowSpan={2}></td>
                  <td colSpan={3}>KELAS VII</td>
                  <td colSpan={3}>KELAS VIII</td>
                </tr>
                <tr>
                  <td style={borderedCellStyle}>KKM</td>
                  <td style={borderedCellStyle}>Smt 1</td>
                  <td style={borderedCellStyle}>Smt 2</td>
                  <td style={borderedCellStyle}>KKM</td>
                  <td style={borderedCellStyle}>Smt 1</td>
                  <td style={borderedCellStyle}>Smt 2</td>
                </tr>
              </thead>
              <tbody>
                <Form.List name="value">
                {(fields, {}) => (
                  <>
                    {listMatpel.length > 0 && fields.map((field, index) => {
                      const {verifikasi, matpelId, raporId, namaMatpel,  ...matpelValue} = value.value[index]
                      const mataPelajaran = listMatpel.find(e => e.matpelId === matpelId.matpelId); 
                      const matpelValidProp = !!value.value ? countValidProp(matpelValue) : 0;
                      return (
                        <tr key={'field-nilai'+index}>
                          <td>{mataPelajaran.matpel} </td>
                          <td style={{width: '20px'}}>
                            {/* {matpelValidProp + '' + verifikasi} */}
                            { matpelValidProp >= 6 ? (
                              !verifikasi && props.isRevisi ? <ExclamationCircleFilled style={{color: "#faad14", fontSize: "1.2em"}} title="Data tidak diterima" /> : 
                              <CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid"  />
                            ) : ( <CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  /> )}
                          </td>
                          <td><Form.Item disabled className="no-error" name={[field.name, 'kkmKelas7']} fieldKey={[field.name, 'vii', 'kkm2']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber className="input-modal"  {...inputNumberConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas7Sem1']} fieldKey={[field.name, 'vii', '1']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber className="input-modal"  {...inputNumberConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas7Sem2']} fieldKey={[field.name, 'vii', '2']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber className="input-modal"  {...inputNumberConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kkmKelas8']} fieldKey={[field.name, 'viii', 'kkm2']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber className="input-modal"  {...inputNumberConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas8Sem1']} fieldKey={[field.name, 'viii', '3']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber className="input-modal"  {...inputNumberConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name,'kelas8Sem2']} fieldKey={[field.name, 'viii', '4']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber className="input-modal"  {...inputNumberConfig} />
                          </Form.Item></td>
                        </tr>
                      )
                    })}
                  </>
                )}
                </Form.List>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={{padding: '1.25em 0px', marginTop: '1em'}}><Alert message="Jangan inputkan nilai desimal" banner /></div>

      {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
    </Form>
  )
}