import { InputNumber } from 'antd'
import React from 'react'

export default function MoneyInput({value, onChange, ...props}) {
  return (
    <div style={{display: 'flex', width: '100%'}}>
      <div className="ant-input-group-addon" style={{paddingTop:'2px', verticalAlign:'middle', display:'inline-table', lineHeight:'24px', height:'32px'}}>Rp </div>
      <InputNumber
        {...props}
        value={value}
        onChange={onChange}
        style={{verticalAlign:'middle', borderBottomLeftRadius:0, borderTopLeftRadius:0, width: 'auto', flex: '1'}}
        className="input-modal"
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/(,*)/g, '')}
      />
    </div>
  )
}
