export default {
	"isTest": false,
	"rules": [
			{
					"rule": "Ujian Dimulai"
			},
			{
					"rule": "Durasinya 50 menit "
			},
			{
					"rule": "Dan terdapat 19 soal"
			}
	],
	"endAt": 1600785089761,
	"startAt": 1600092300000
}