import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import {useForm} from 'antd/lib/form/Form';
import { Layout, Row, Input, Button, Form, Col, Typography, Spin, PageHeader } from 'antd';
import axios from 'axios';
import CONFIG_INITIAL_STATE from '../../service/config';
import swal from "sweetalert"
import { LoadingOutlined } from '@ant-design/icons';

const ForgotPassword = props => {
  const { Title } = Typography;
  const [form] = useForm();
  const [email, setEmail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Lupa Password | Loyola";
  })

  const alert = (config) => {
    return new Promise( async(resolve) => {
      const result = await swal({
        ...config
      });
      resolve(result)
    })
  };

  const handleFinish = () => {
    setIsLoading(true);
    const dataEmail = { to: email };
    handleSendLink(dataEmail)
  }

  const handleBack = () => {
    props.history.push('/login');
  }

  const handleSendLink = param => {
    return new Promise((resolve, reject) => {
      const body = param;
      axios.put(CONFIG_INITIAL_STATE.BASE_URL_NOAUTH +'/api/forgotPassword?isWebAdmin=false', body).then(res => {
        resolve(res.data.Message)
        setIsLoading(false)
        console.log(res.data.Message)
        if(res.data.Message === "E-mail telah terkirim! Silahkan check e-mail Anda!") {
          alert({
            icon: 'success',
            text: 'E-mail Reset Password berhasil dikirim. \n Silahkan cek pada e-mail Anda!',
          }).then(() => {form.resetFields()})
        } else {
          alert({
            icon: 'error',
            text: 'E-mail tidak terdaftar',
          }).then(() => {form.resetFields()})
        } 
      }).catch(err => {
        reject(err)
        setIsLoading(false)
        console.log(err)
        alert({icon: 'error',
          title: 'Gagal',
          text: "Terjadi kesalahan pada server",
        })
      })
    })
  } 

  return (
    <Layout.Content className='site-layout-forgotpass'>
      <div className="container" style={{paddingTop: '4em', paddingBottom: '4em'}}>
        {/* <Title level={3} style={{ color: "#072A6F", fontSize: "30px"}}>
          Lupa Password?
        </Title> */}
        <PageHeader
          style={{marginLeft: '-20px'}}
          className="site-page-header"
          onBack={() => handleBack()}
          title="Lupa Password"
        />
        <Form form={form} onFinish={handleFinish}>
          <Row justify="left" align="left" >
            <h1 style={{ color: "#000", fontSize: "18px"}}>
              Password baru akan dikirimkan ke alamat e-mail Anda
            </h1>
          </Row>
          <Row gutter={20}>
            <Col lg={8} md={12} span={24}>
              <Form.Item
                name="email"
                rules={[
                  {required: true, message: 'Harap masukkan E-mail!'},
                  {type: 'email', message: 'Harap masukkanalamat E-mail yang valid!'},
                ]}
              >
                <Input
                  style={{width: '100%', margin: '0px'}}
                  className="form-group-forgotpass"
                  prefix="@&nbsp;"
                  placeholder="E-mail"
                  disabled={isLoading}
                  onChange={event => setEmail(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col lg={3} md={4} span={24}>
              <>
                <Button
                  style={{borderRadius: '30px'}}
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  className="app-btn primary block"
                >
                  {isLoading && <LoadingOutlined />}
                  KIRIM
                </Button>
              </>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout.Content>
  )
}

export default withRouter(ForgotPassword) 