/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Typography, Form, Button, Input, Row, Col, Radio, DatePicker, Select} from 'antd';
import moment from 'moment';
import  CONFIG  from '../../../../service/config';
import { RedStar, AppHint } from '../../../../component/common';
import UploadComponent from '../../../../component/upload-component';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import SelectComponent from '../../../../component/select-component';
import AppAutoComplete from '../../../../component/app-autocomplete';

const { Text } = Typography
const listPeminatan = [{key:"IPA", value:"IPA"},{key:"IPS", value:"IPS"}]
const noTelpPanitia = '0857-4124-7145';

function SmpNotFound () {
  return (
    <div style={{textAlign: "center", padding: "1em 2em 1.5em", color: "#777777"}}>
        Asal SMP yang Anda cari belum terdaftar di sistem. <br />
        Hubungi nomor panitia berikut ini ({noTelpPanitia}) agar Asal SMP Anda dapat didaftarkan.    </div>
  )
}

export default function BiodataDiri(props) {
  const [form] = Form.useForm();
  const {initValue, isRevisi, masterData, contentKey, onChange, onAddMasterData} = props;

  useEffect(() => {
    const newInitValue = initValue;
    if(!!newInitValue && !!('tglLahir' in newInitValue)) {
      newInitValue.tglLahir = moment(newInitValue.tglLahir);
    }
    form.setFieldsValue(newInitValue || {});
  }, [initValue]);

  useEffect(() => {
    return () => {
      const newValue = form.getFieldValue();
      if (!newValue.tglLahir) {
        delete newValue.tglLahir;
      }
      onChange(contentKey, newValue); 
    }
  }, [])

  const autoFill = async () => {
    form.setFieldsValue({
      "namaSiswa": "Jorge Serrano",
      "asalSmp": "SMIT",
      "peminatan": "IPA",
      "nisn": '2131232132',
      "nik": '3123123213',
      "tempatLahir": "Mexico",
      "tglLahir": moment("2020-08-13T12:28:59.409Z"),
      "jenisKel": 1,
      "agama": "Kristen",
      "keturunan": "Amerika latin",
      "alamat": "Jalan bueno aieres no 7 cimanuk",
      "rt": "09",
      "rw": "03",
      "kelurahan": "argentina",
      "kodePos": '21312',
      "kecamatan": "america",
      "kotaKab": "eropa",
      "noHp": '0',
      "anakKe": '1',
      "jumlahSaudara": '1',
      "jumlahKakakBelajar": '1',
      "jumlahKakakBekerja": '0',
      "kakakLoyola": {},
      "fotoSiswa": {fileName: 'file/00.jpg'},
      suratPermandian: {fileName: 'file/00.jpg'},
      akteKelahiran: {fileName: 'file/00.jpg'},
      kartuKeluarga: {fileName: 'file/00.jpg'},
    });
  }

  return (
    <Form form={form}>
      <br />
      <Row gutter={20}>
        <Col span={24} style={{marginBottom: '20px'}}>
          <Text>Nama Siswa <RedStar /></Text>
          <Form.Item
            style={{marginBottom: 0}}
            name="namaSiswa"
            rules={[{ required: true, message: 'Nama harus diisi' }]}
          >
            <Input className='input-modal' maxLength={50} placeholder="Nama lengkap siswa sesuai Akta Kelahiran atau Kartu Keluarga" />
          </Form.Item>
          <Text style={{color: '#1890ff', fontStyle: 'italic'}}>Nama Lengkap sesuai Akta Kelahiran</Text>
        </Col>
        <Col lg={12} span={24}>
          <Text>Cari Asal SMP  <RedStar /> <AppHint text={`Tidak dapat menemukan Asal SMP Anda? Anda dapat menghubungi nomor panitia berikut ini (0857-4124-7145) agar Asal SMP Anda dapat didaftarkan.`} /></Text>
          <Form.Item
            name="asalSmp"
            rules={[{ required: true, message: 'Asal SMP harus diisi' }]}
          >
            <AppAutoComplete api={CONFIG.BASE_URL_NOAUTH + '/api/noAuth/autoComplete/masterSMP'} notFoundMessage={<SmpNotFound />} valueKey="label" placeholder="Cari Asal SMP" />
            {/* <SelectComponent notFoundMessage={<SmpNotFound />} items={masterData['smp'] || []} labelKey="namaSMP" valueKey="namaSMP" placeholder="Pilih Asal SMP" /> */}
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Peminatan <RedStar /></Text>
          <Form.Item
            name="peminatan"
            rules={[{ 
              required: true, message: 'Peminatan harus diisi' }
            ]}>
            <Select placeholder="Pilih Peminatan">
              {listPeminatan.map((minat, index) => {
                return <Select.Option key={`peminatanOption${index}`} value={minat.key}>{minat.value}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>NISN <RedStar /></Text>
          <Form.Item
            name="nisn"
            rules={[
              {min: 10, message: 'Harap diisi dengan 10 angka NISN'},
              {required: true, message: 'NISN harus diisi'},
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan 10 angka NISN"}
            ]} >
            <Input className='input-modal' maxLength={10} style={{width: '100%' }} placeholder="Nomor Induk Siswa Nasional" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text> NIK <RedStar /> <AppHint text="NIK (Nomor Induk Kependudukan) dapat ditemukan pada Kartu Keluarga atau Akta Kelahiran" /></Text>
          <Form.Item
            name="nik"
            rules={[
              {required: true, message: 'NIK harus diisi'},
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan 16 angka NIK"}
            ]} >
            <Input className='input-modal' maxLength={16} style={{width: '100%' }} placeholder="Nomor Induk Kependudukan berdasarkan KK" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Tempat Lahir <RedStar /></Text>
          <Form.Item
            name="tempatLahir"
            rules={[{ 
              required: true, message: 'Tempat Lahir harus diisi' }]}
          >
            <Input className='input-modal' maxLength={50} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Tanggal Lahir <RedStar /></Text>
          <Form.Item
            name="tglLahir"
            rules={[
              { required: true, message: 'Tanggal Lahir harus diisi' },
            ]}
          >
            <DatePicker allowEmpty className='input-modal' style={{width: '100%'}} placeholder="Select Date"  />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>No. Telepon <RedStar /></Text>
          <Form.Item
            name="noHp"
            rules={[{ 
              required: true, message: 'Nomor Telepon harus diisi'},
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan Nomor telepon"}
            ]}
          >
            <Input className='input-modal' maxLength={15} min={0} style={{width: "100%"}} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Jenis Kelamin <RedStar /></Text>
          <Form.Item
            name="jenisKel"
            rules={[{ required: true, message: 'Jenis Kelamin harus dipilih' }]}
          >
            <Radio.Group>
              <Radio value={1}>Laki-Laki</Radio>
              <Radio value={2}>Perempuan</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Agama <RedStar /></Text>
          <Form.Item
            name="agama"
            rules={[{ required: true, message: 'Agama harus diisi' }]}
          >
            <SelectComponent items={masterData['agama']} showSearch={false} labelKey="namaAgama" valueKey="namaAgama" placeholder="Pilih Agama" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Keturunan <RedStar /></Text>
          <Form.Item
            name="keturunan"
            rules={[ { required: true, message: 'Keturunan harus diisi' } ]}
          >
            <SelectComponent onAddItem={item => onAddMasterData('suku', item)} labelKey="namaSuku" valueKey="namaSuku"  placeholder="Pilih Suku" items={masterData['suku']} />
          </Form.Item>
        </Col>
        <Col span={24} style={{marginBottom: '20px'}}>
          <Text>Alamat Lengkap <RedStar /></Text>
          <Form.Item
            style={{marginBottom: 0}}
            name="alamat"
            rules={[{ required: true, message: 'Alamat Lengkap harus diisi' }]}
          >
            <Input className='input-modal' placeholder="Alamat lengkap sesuai KK" maxLength={255} />
          </Form.Item>
          <Text style={{color: '#1890ff', fontStyle: 'italic'}}>Alamat Sesuai KK</Text>
        </Col>
        <Col span={4}>
          <Text>RT <RedStar /></Text>
          <Form.Item
            name="rt"
            rules={[
              { required: true, message: 'RT harus diisi' },
              { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }
            ]}
          >
            <Input className='input-modal' maxLength={3} style={{width: "100%"}} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Text>RW <RedStar /></Text>
          <Form.Item
            name="rw"
            rules={[
              { required: true, message: 'RW harus diisi' },
              { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }
            ]}
          >
            <Input className='input-modal' maxLength={3} style={{width: "100%"}} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Text>Kelurahan <RedStar /></Text>
          <Form.Item
            name="kelurahan"
            rules={[{ 
              required: true, message: 'Kelurahan harus diisi' }]}
          >
            <Input className='input-modal' maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text>Kode Pos <RedStar /></Text>
          <Form.Item
            name="kodePos"
            rules={[
              { required: true, message: 'Kode Pos harus diisi' },
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka "}
            ]}
          >
            <Input className='input-modal' maxLength={5} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Kecamatan <RedStar /></Text>
          <Form.Item
            name="kecamatan"
            rules={[{ 
              required: true, message: 'Kecamatan harus diisi' }]}
          >
            <Input className='input-modal' maxLength={100} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Kota/Kabupaten <RedStar /></Text>
          <Form.Item
            name="kotaKab"
            rules={[{ 
              required: true, message: 'Kota/Kabupaten harus diisi' }]}
          >
            <Input className='input-modal' maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={24} style={{marginBottom: '20px'}}>
          <Text>E-mail <RedStar /></Text>
          <Form.Item
            style={{marginBottom: 0}}
            name="email"
            rules={[{ 
              required: true, message: 'E-mail harus diisi' },{
              type:'email', message: 'Harap masukan format e-mail'
              }]}
          >
            <Input className='input-modal' maxLength={50} disabled={isRevisi} />
          </Form.Item>
          <Text style={{color: '#1890ff', fontStyle: 'italic'}}>E-mail ini akan digunakan sebagai username/e-mail login akun PPDB</Text>
        </Col>
        <Col span={24} style={{marginBottom: '20px'}}>
          <Text>Password <RedStar /></Text>
          <Form.Item
            style={{marginBottom: 0}}
            name="password"
            rules={[{ required: !props.isRead, message: 'Password harus diisi' }]}
          >
            <Input.Password className='input-modal' disabled={isRevisi} />
          </Form.Item>
          <Text style={{color: '#1890ff', fontStyle: 'italic'}}>Password ini akan digunakan sebagai password login akun PPDB</Text>
        </Col>
        <Col lg={12} span={24}>
          <Text>Anak ke- <RedStar /></Text>
          <Form.Item
            name="anakKe"
            rules={[
              { required: true, message: 'Anak ke- harus diisi' },
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka "}
            ]}
          >
            <Input className='input-modal' maxLength={2} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>dari Total Anak <RedStar /></Text>
          <Form.Item
            name="jumlahSaudara"
            rules={[
              { required: true, message: 'dari Total Anak harus diisi' },
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Jumlah Kakak Belajar</Text>
          <Form.Item
            name="jumlahKakakBelajar"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Jumlah Kakak Bekerja</Text>
          <Form.Item
            name="jumlahKakakBekerja"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}> 
          <Text>Jumlah Adik Laki-Laki Belajar</Text>
          <Form.Item
            name="jumlahAdikLaki"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Jumlah Adik Perempuan Belajar</Text>
          <Form.Item
            name="jumlahAdikPerempuan"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2}/>
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Jumlah Saudara Tiri Laki-Laki</Text>
          <Form.Item
            name="jumlahTiriLaki"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2}/>
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Jumlah Saudara Tiri Perempuan</Text>
          <Form.Item
            name="jumlahTiriPerempuan"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className='input-modal' maxLength={2} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Foto Siswa <RedStar /></Text>
          <Form.Item
            name="fotoSiswa"
            rules={[{required: true, message: 'Foto Siswa harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.png"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/foto'}
              title="Upload Foto Siswa"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Akta Kelahiran <RedStar /></Text>
          <Form.Item
            name="akteKelahiran"
            rules={[{required: true, message: 'Akta Kelahiran harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/aktaKelahiran'}
              title="Upload Akta Kelahiran"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Kartu Keluarga <RedStar /></Text>
          <Form.Item
            name="kartuKeluarga"
            rules={[{required: true, message: 'Kartu Keluarga harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/kartuKeluarga'}
              title="Upload Kartu Keluarga"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Surat Baptis </Text>
          <Form.Item
            name="suratPermandian"
            // rules={[{required: true, message: 'Surat Pemandian harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/suratPermandian'}
              title="Upload Surat Baptis"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        
        {/* Kakak loyola */}
        <Col span="24" style={{marginBottom: "1em"}}>
          <Form.List name={['kakakLoyola', 'kakakMasih']}>
            {(fields, {add, remove}) => { 
              return (
                <div>
                  <div className="subtitle">Kakak yang bersekolah di Loyola</div>
                  {fields.map((field, index) => {
                    return(
                      <Row gutter={20}>
                        <Col span="16">
                          <Text>Nama Kakak</Text>
                          <Form.Item 
                            name={[field.name, "namaKakakMasih"]}
                            fieldKey={[field.fieldKey, "namaKakakMasih"]}
                          >
                            <Input className='input-modal' maxLength={50} />
                          </Form.Item>
                        </Col>
                        <Col span="8">
                          <Text>Kelas</Text>
                          <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <Form.Item 
                              style={{marginBottom: '0px'}}
                              name={[field.name, "kelasKakak"]}
                              fieldKey={[field.fieldKey, "kelasKakak"]}
                              rules={[{pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}]}
                            >
                              <Input className='input-modal' maxLength={3}/>
                            </Form.Item>
                            <CloseOutlined style={{fontSize: '1em', color: '#EB5757', marginLeft: '1em'}} onClick={_ => remove(index)} title="Hapus Kakak" /> 
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {!isRevisi && <Button 
                    type="dashed"
                    onClick={_ => add({masihSekolah: true})}
                    style={{ width: "100%" }}
                  >
                    <PlusOutlined /> Tambah
                  </Button>}
                </div>
              )
            }}
          </Form.List>
        </Col>
        <Col span="24">
          <Form.List name={['kakakLoyola', 'kakakPernah']}>
            {(fields, {add, remove}) => { 
              return (
                <div>
                  <div className="subtitle">Kakak yang pernah bersekolah di Loyola</div>
                  {fields.map((field, index) => {
                    return(
                      <Row gutter={20}>
                        <Col span="16">
                          <Text>Nama Kakak</Text>
                          <Form.Item 
                            name={[field.name, "namaKakakPernah"]}
                            fieldKey={[field.fieldKey, "namaKakakPernah"]}
                          >
                            <Input className='input-modal' maxLength={50}/>
                          </Form.Item>
                        </Col>
                        <Col span="8">
                          <Text>Tahun Lulus</Text>
                          <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <Form.Item 
                              style={{marginBottom: '0px'}}
                              name={[field.name, "tahunLulus"]}
                              fieldKey={[field.fieldKey, "tahunLulus"]}
                              rules={[{pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}]}
                            >
                              <Input className='input-modal' maxLength={4}/>
                            </Form.Item>
                            <CloseOutlined style={{fontSize: '1em', color: '#EB5757', marginLeft: '1em'}} onClick={_ => remove(index)} title="Hapus Kakak" /> 
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {!isRevisi && <Button 
                    type="dashed"
                    onClick={_ => add({masihSekolah: false})}
                    style={{ width: "100%" }}
                  >
                    <PlusOutlined /> Tambah
                  </Button>}
                </div>
              )
            }}
          </Form.List>
        </Col>
      </Row>
      {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
      <div className="steps-action">
        {
          CONFIG.isServer !== 'Production' &&
          <Button onClick={autoFill} className="app-btn" style={{marginRight: '20px'}}>AUTO FILL</Button>
        }
        <Button type="primary" className="app-btn primary" onClick={_ => {
          form.validateFields().then(values => {
            props.onNext();
          }).catch(info => {});
        }}>
          Selanjutnya
        </Button>
      </div>
    </Form>
  )
}