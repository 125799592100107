/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalFunction } from '../../../global/function';

const buttonStyle = {
  width: '70%',
  margin: '0px auto 1em',
  borderRadius: '30px',
  padding: '.5em',
  fontWeight: 'bold',
  color: '#072a6f',
  border: '1px solid #072a6f',
  height: 'auto',
  fontSize: '1.2em'
}

const activeButtonStyle = {
  ...buttonStyle,
  color: '#fff',
  background: '#072a6f',
}

const btnStyle = {
  textAlign: 'center',
  alignItems: 'center',
  padding: '.5em 2em',
  background: '#072a6f',
  color: '#fff',
  marginLeft: '1em',
  borderRadius: '8px'
}

export default function Jadwal(props) {
  const { data = [],isLoadingStartUjian } = props;
  const history = useHistory()
  const [activeJadwal, setActiveJadwal] = useState(0);
  const [isSubmit, setSubmit] = useState(false);
  const jadwal = data[activeJadwal]
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [timer, setTimer] = useState();
  const isEnded = jadwal ? Date.now() > jadwal.endDateTime : true;
  const isStarted = jadwal ? Date.now() > jadwal.startDateTime : false;
  const hasResult = jadwal ? jadwal.hasResult : false;

  useEffect(() => {
    if(jadwal && jadwal.startDateTime && Date.now() < jadwal.endDateTime) {
      updateTimer()
    }
    return () => {
      clearInterval(timer);
    }
  }, [jadwal])

  const updateTimer = () => {
    clearInterval(timer)
    const timeLeft = Date.now() > jadwal.startDateTime ? jadwal.endDateTime : jadwal.startDateTime;
    console.log(jadwal, timeLeft)
    setTime(GlobalFunction.timeConversion(timeLeft - Date.now()));
    const newTimer = setInterval(() => {
      setTime(GlobalFunction.timeConversion(timeLeft - Date.now()));
    }, 1000)
    setTimer(newTimer);
  }

  const handleClick = (index) => {
    updateTimer();
    setActiveJadwal(index);
  }

  const handleOnStart = () => {
    setSubmit(true)
    props.onStart(jadwal.subjectId)
  }
  
  return (
    <Row gutter={[20,20]} style={{alignItems: 'stretch'}}>
      <Col xs={24} md={24} lg={12} style={{height: '100%'}}>
        <Card className="app-card" style={{width: '100%', textAlign: 'center', lineHeight: 'normal'}}>
          <Title level={3} style={{marginBottom: '1em'}}>Mata pelajaran</Title>
          {data.map(({subjectName, endDateTime, hasEnroled}, i) => {
            const isFinished = Date.now() > endDateTime;
            let btnStyle = buttonStyle; 
            const accentColor = isFinished ? (hasEnroled ? '#03ac0e' : '#ce2929') : '#072a6f';
            btnStyle = {...btnStyle, color: accentColor, borderColor: accentColor};  
            if (activeJadwal === i || isFinished || (hasEnroled && isFinished)) {
              btnStyle = {...btnStyle, background: accentColor, borderColor: accentColor, color: '#fff'}
            }
            return (
              <Button onClick={_ => handleClick(i)} key={'buttonSubject'+i} style={btnStyle}>
                {subjectName}
              </Button>
            )
          })}
          <Button onClick={() => history.push('/pendaftaran')} style={buttonStyle}>
            Kembali Ke Dashboard
          </Button>
        </Card>
      </Col>
      {(jadwal && activeJadwal !== undefined) && (
        <Col xs={24} md={24} lg={12} style={{height: '100%'}}>
          <Card className="app-card" style={{width: '100%', height: '100%', lineHeight: 'normal'}}>
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <div>
                <Title level={3} style={{marginBottom: '.5em'}}>Catatan Ujian</Title>
                <div style={{fontSize: '1.2em'}}>Mata pelajaran {jadwal.subjectName}</div>
                <div style={{fontSize: '1.2em'}}>Aturan :</div>
                <ul style={{fontSize: '1.2em'}}>
                  {jadwal.rules.map(e => <li>{e.rule}</li>)}
                </ul>
              </div>
              <Space style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'flex-end'}}>
                <Row gutter={[10,10]}>
                  <Col style={{fontSize: '1.4em'}} xs={24} flex='auto'>
                    <div>{isEnded ? 'Ujian telah berakhir' : isStarted ? 'Ujian sedang berlangsung' : 'Ujian dimulai dalam :'}</div>
                    {(!isEnded && time) && ( 
                      <div style={{fontWeight: 'bold', color: '#072a6f'}}>
                        {/* {JSON.stringify(timer, '', 2)} */}
                        {(time.days < 0 ? 0 : time.days) + ' Hari '}
                        {(time.hours < 0 ? 0 : + time.hours) +' Jam '}
                        {(time.minutes < 0 ? 0 : time.minutes) + ' Menit '}
                        {(time.seconds < 0 ? 0 : time.seconds) + ' Detik '}
                      </div>
                    )}
                  </Col>
                  <Col xs={24} sm={3} >
                    {/* <Button className="app-btn primary" disabled={!hasResult} onClick={handleOnStart} style={{marginLeft: '1em'}}>Lihat hasil ujian</Button> */}
                    <Button className="app-btn primary"  loading={isLoadingStartUjian} disabled={!isStarted || isEnded} onClick={handleOnStart} style={{marginLeft: '1em'}}>Mulai</Button>
                  </Col>
                </Row>
              </Space>
            </div>
          </Card>
        </Col>
      )}
    </Row>
  )
}
