/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Input, Col, Row, Form, Typography, Card } from 'antd';
import { RedStar } from '../../../component/common';
import PropTypes from 'prop-types';
import UploadComponent from '../../../component/upload-component';
import { debounce } from 'lodash';
import CONFIG from '../../../service/config';

import gmapsGuideImg from '../../../../assets/gmaps-tutorial.png';

const {Text, Title} = Typography;

DataAktivitas.propTypes = {
  onChange: PropTypes.func
};


export default function DataAktivitas(props) {
  const [form] = Form.useForm();
  const {onChange, initValue, formId, formTitle, isValidating} = props;
  const {noRegistrasi} = props.userData;
  let debouncedFn;

  useEffect(() => {
    if(!!initValue) {
      form.setFieldsValue(initValue.value);
      onChange(formId, initValue)
    }
  }, [initValue]);

  useEffect(() => {
    if (!!isValidating) {
      form.validateFields().then(_ => onChange(formId, {valid: true}))
      .catch(_ => onChange(formId, {valid: false}))
    }
  }, [isValidating])


  const handleChange = (ev) => {
    if (!debouncedFn) {
      debouncedFn = debounce(async () => {
        form.validateFields().then(_ => onChange(formId, {valid: true, value: form.getFieldsValue()}))
        .catch(_ => onChange(formId, {valid: false, value: form.getFieldsValue()}))
        // onChange(formId, {value: form.getFieldsValue()})
      }, 300)
    }
    debouncedFn()
  }  

  return (
    <Card>
      <Form form={form} onValuesChange={handleChange}>
      <Row gutter={0}>
        <Col span={24} style={{borderBottom: '1px solid rgb(217, 217, 217)', marginBottom: '1em'}}><Title level={4}>{formTitle}</Title></Col>
        <Col span={24} style={{marginBottom: '1em'}}>
          <Card className="app-card paper" style={{paddingBottom: '5px'}}>
            <Row>
              <Col lg={12} span={24} style={{marginBottom: '1em'}}>
                <Text strong>Cara mendapatkan koordinat rumah :</Text>
                <ol>
                  <li>Buka aplikasi Google Maps atau buka halaman Google Maps melalui link berikut  <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps">https://www.google.com/maps</a></li>
                  <li>Letakkan pin pada lokasi rumah Anda</li>
                  <li>Salin koordinat yang tertera ke dalam field Koordinat Rumah</li>
                </ol>
                <Text>Contoh koordinat: <strong>-6.981849, 110.425988</strong></Text>
              </Col>
              <Col lg={12} span={24}>
              <a href={gmapsGuideImg} target="_blank" rel="noopener noreferrer">
                <img src={gmapsGuideImg} alt='how-to-get-coordinate-pic' style={{width: '100%'}} />
              </a>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Text>Koordinat Rumah <RedStar /></Text>
          <Form.Item 
            name="koordinatRumah"
            rules={[{ required: true, message: 'Koordinat Rumah harus diisi' }]}
          >
            <Input className="input-modal" placeholder={"Masukkan Titik Koordinat Rumah"} maxLength={26} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>Foto saat siswa membantu orangtua memasak di dapur. (Foto menampakkan seluruh badan utuh bukan hanya setengah atau seperempat badan) <RedStar /></Text>
          <Form.Item 
            name="fotoBantuOrtu"
            rules={[{ required: true, message: 'Foto Membantu Orangtua harus diisi' }]}
          >
            <UploadComponent 
              url={CONFIG.BASE_URL + `/api/daftarUlang/upload/fotoMembantuOrtu?noReg=${noRegistrasi}`} 
              accept=".jpg,.png"
              maxSize={10}
              showDesc
              title="Upload Foto" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>Foto saat siswa berkumpul bersama keluarga di ruang keluarga. (Foto menampakkan seluruh badan utuh bukan hanya setengah atau seperempat badan) <RedStar /></Text>
          <Form.Item 
            name="fotoKeluargaRuangKeluarga"
            rules={[{ required: true, message: 'Foto Keluarga di Ruang Keluarga harus diisi' }]}
          >
            <UploadComponent 
              url={CONFIG.BASE_URL + `/api/daftarUlang/upload/fotoKeluargaRuangKeluarga?noReg=${noRegistrasi}`} 
              accept=".jpg,.png"
              maxSize={10}
              showDesc
              title="Upload Foto" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>Foto saat siswa bersama keluarga di depan rumah. (Foto menampakkan seluruh badan utuh bukan hanya setengah atau seperempat badan, dengan latar belakang bangunan rumah) <RedStar /></Text>
          <Form.Item 
            style={{marginBottom: '0px'}}
            name="fotoKeluargaDepanRumah"
            rules={[{ required: true, message: 'Foto Keluarga di Depan Rumah harus diisi' }]}
          >
            <UploadComponent 
              url={CONFIG.BASE_URL + `/api/daftarUlang/upload/fotoKeluargaDepanRumah?noReg=${noRegistrasi}`} 
              accept=".jpg,.png"
              maxSize={10}
              showDesc
              title="Upload Foto" />
          </Form.Item>
        </Col>
      </Row>
      </Form>
    </Card>
  )
}
