/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, InputNumber } from 'antd'
import { CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import _ from 'lodash'
import { countValidProp } from '../../../../functions/object';

const borderedCellStyle = {borderBottom: "1px solid #e0e0e0", marginBottom: '1em'};
const inputNumberConfig = {min: 0, max: 100, maxLength: 4, style: {width: "100%" }}


export function Kehadiran(props) {
  const [form] = Form.useForm();
  let value = form.getFieldValue();
  const {initValue, listKehadiran} = props;
  let debouncedFn;

  useEffect(() => {
    if (!!listKehadiran) {
      let defaultValue = {value: listKehadiran.map(e => ({kehadiranId: {kehadiranId : e.kehadiranId}})), valid: false};
      if (!!initValue && !!initValue.value && initValue.value.length > 0) {
        defaultValue = initValue;
      }
      value = defaultValue;
      form.setFieldsValue(defaultValue);
      props.onChange(defaultValue);
    }
  }, [initValue, listKehadiran])
  
  const handleChange = async (event) => {
    if (props.isRevisi) { await updateVerifikasiStatus(event); }
    if (!debouncedFn) {
      debouncedFn =  _.debounce(async  () => {
        const val = form.getFieldsValue();
        await form.validateFields().then(_ => {
          val.valid = props.isRevisi ? ((val.value).filter(e => !e.verifikasi).length === 0) : true
        }).catch(_ => val.valid = false);
        // setValue(val)
        props.onChange(val)
      }, 1000);
    }
    debouncedFn();
  }

  const updateVerifikasiStatus = async (event) => {
    let val = Object.values(event.value);
    if (val.length > 0) {
      val = val[0];
      const valueKey = event.value.indexOf(val);
      let newValue = form.getFieldsValue();
      newValue.value[valueKey].verifikasi = true;
      form.setFieldsValue(newValue);
    }
  }

  return (
    <Form form={form} onValuesChange={handleChange}>
      <div className="ant-table app-table">
        <div className="ant-table-container">
          <div className="ant-table-content" style={{overflowY: 'auto', paddingBottom: '1em'}}>
            <Form.List name="value">
              {(fields, {add, remove}) => (
                <table style={{fontWeight: "bold", fontSize: "1.2em"}}>
                  <thead style={{textAlign: "center"}}>
                    <tr>
                      <td style={{...borderedCellStyle, width: "30%", textAlign: "left"}} rowSpan={2}>Kehadiran</td>
                      <td style={{...borderedCellStyle, width: "60px", textAlign: "right"}} rowSpan={2}></td>
                      <td colSpan={2}>KELAS VII</td>
                      <td colSpan={2}>KELAS VIII</td>
                    </tr>
                    <tr>
                      <td style={borderedCellStyle}>Smt 1</td>
                      <td style={borderedCellStyle}>Smt 2</td>
                      <td style={borderedCellStyle}>Smt 1</td>
                      <td style={borderedCellStyle}>Smt 2</td>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((field, index) => {
                      const {verifikasi, kehadiranId, raporId, jenisKehadiran,  ...kehadiranValue} = value.value[index]
                      const kehadiranValidProp = !!value.value ? countValidProp(kehadiranValue) : 0;
                      return (
                        <tr key={`kehadiran_row_${index}`}>
                          <td>{listKehadiran[index]['jenisKehadiran']}</td>
                          <td style={{width: '20px'}}>
                            { kehadiranValidProp >= 4 ? (
                              !verifikasi && props.isRevisi ? <ExclamationCircleFilled style={{color: "#faad14", fontSize: "1.2em"}} title="Data tidak diterima" /> : 
                              <CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid"  />
                            ) : ( <CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  /> )}
                          </td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas7Sem1']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber {...inputNumberConfig} className="input-modal" />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas7Sem2']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber {...inputNumberConfig} className="input-modal" />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas8Sem1']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber {...inputNumberConfig} className="input-modal" />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas8Sem2']} rules={[{ required: true}, { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }]}>
                            <InputNumber {...inputNumberConfig} className="input-modal" />
                          </Form.Item></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </Form.List>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
    </Form>
  )
}