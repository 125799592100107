import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Typography, Button } from 'antd';
import { AppLoading } from './loading';
import { SearchOutlined } from '@ant-design/icons';

const { Text } = Typography
CardUjianComponent.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  isText: PropTypes.bool,
  styles: PropTypes.object
};

CardUjianComponent.defaultProps  = {
  value: '',
  type: '',
  isText: true,
  styles: {}
};

const alfabet = ['A', 'B', 'C', 'D', 'E']

function CardUjianComponent(props) {
  const [isShow, setShow] = useState(false) 
  return (
    <Col xs={24} lg={6} md={12}  style={{marginBottom: 20}}>
      <Row 
        onClick={() => props.onClick()}
        style={{minHeight: 150, width: '100%'}}
        className={ props.selected ? 'card-ujian-akademik-selected' : 'card-ujian-akademik' } 
        align='middle'
        justify='center'
      >
        <div style={{position: 'absolute', left: 20, top: 10,fontSize: 28, fontWeight: 'bold'}}>{alfabet[props.index]}. </div>
        { props.isText ? 
          <div className='textJawaban'>{props.value}</div> :
          <img style={{width: '100%', minHeight: 180, borderRadius: 'inherit', opacity: props.selected ? 0.5 : 1}}  src={props.value}/>
        }
        {
          !props.isText && 
          <Button 
            style={{position: 'absolute', right: 20, bottom: 20, backgroundColor:'#ECECEC'}}
            onClick={()=>setShow(true)}
          ><SearchOutlined /></Button>
        }

        <Modal 
          visible={isShow}
          onCancel={()=>setShow(false)}
          footer={null}
          closeIcon={null}
        >
          {
            props.value ? 
            <img style={{width: '100%', height: '100%'}}  src={props.value}/> :
            <AppLoading/>
          }
        </Modal>
      </Row>
    </Col>
  );
}

export default CardUjianComponent;