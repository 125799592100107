export default {
	"data": {
		"password": null,
		"username": "dewi123@boximail.com",
		"authorities": [{
			"authority": "Siswa"
		}],
		"accountNonExpired": true,
		"accountNonLocked": true,
		"credentialsNonExpired": true,
		"enabled": true,
		"userId": 30,
		"applicationId": 0,
		"jalurPenerimaan": "Transfer",
		"statusEksekutif": false,
        "jadwal": {
			/* Timestamp list untuk testing login
				1600045200000 -> Sep 14 2020 08:00:00
				1599958800000 -> Sep 13 2020 08:00:00
				1599872400000 -> Sep 12 2020 08:00:00
				1599699600000 -> Sep 11 2020 08:00:00
			*/
            "tglTesOnline": 1599984000000, //Sep 10 2020 01:00:00 (UTC) || Sep 10 2020 08:00:00 (UTC+7, INDONESIA)
            "tesOnlineStatus": false,  // true ketika tes sudah dilakukan atau sudah h+1 tes,, false ketika test belum dilakukan 
            "tglInterview": 1599984000000, //Sep 11 2020 01:00:00 (UTC) || Sep 11 2020 08:00:00 (UTC+7, INDONESIA)
            "interviewStatus": false, // true ketika tes sudah dilakukan atau sudah h+1 tes,, false ketika test belum dilakukan
            "tglBipTest": 1599984000000, //Sep 12 2020 01:00:00 (UTC) || Sep 12 2020 08:00:00 (UTC+7, INDONESIA)
            "bipTestStatus": true, // true ketika tes sudah dilakukan atau sudah h+1 tes,, false ketika test belum dilakukan
            "tglDaftarUlang": 1599984000000, //Sep 13 2020 01:00:00 (UTC) || Sep 13 2020 08:00:00 (UTC+7, INDONESIA)
            "daftarUlangStatus": false // true ketika daftar ulang sudah dilakukan atau sudah h+1 daftar ulang,, false daftar ulang belum dilakukan
			  }
			// "jadwal": null
  },
	"auth": {
		"Authorization": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZXdpMTIzQGJveGltYWlsLmNvbSIsImF1dGhvcml0aWVzIjpbIlNpc3dhIl0sImlhdCI6MTU5OTcwNzk1NSwiZXhwIjoxNTk5Nzk0MzU1fQ.JHi8QhUULgSKUvvYsT_RAbIMBVFX7OYOLPWK29IpnqXTRaLqmDHN1xmqe0a7BfrlJgc1ieh4k8V7DZ4cmHOjEg",
		"tokenType": "Bearer ",
		"scope": "JwtSecretKey",
		"expiresIn": 86400
	}
}