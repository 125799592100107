/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Typography, Form, Button, Input, Row, Col, } from 'antd';
import _ from 'lodash';
import { confirmDialog, confirmDialog2 } from '../../../../functions/alert';
import  CONFIG  from '../../../../service/config';
import { AppHint, RedStar } from '../../../../component/common';
import UploadComponent from '../../../../component/upload-component';
import SelectComponent from '../../../../component/select-component';
import MoneyInput from '../../../../component/money-input';

const { Text } = Typography

export default function DataWali (props) {

  const [form] = Form.useForm();
  const {initValue, masterData, onChange, contentKey} = props;
  // eslint-disable-next-line no-unused-vars
  const [isFormRequired, setIsFormRequired] = useState(false);
  const [isNpwpRequired, setIsNpwpRequired] = useState(false);
  let debouncedFn;

  useEffect(() => {
    if (initValue) {
      form.setFieldsValue(initValue || {});
      validateFormOrtu(initValue)
      console.log('initValue ', contentKey, initValue)
    }
    return () => {
      // onChange(contentKey, form.getFieldsValue());
    }
  }, [initValue])

  // Validate when required rules change
  useEffect(() => {
    form.validateFields().then(_ => {}).catch(_ => {});
  }, [isFormRequired, isNpwpRequired]);

  const validateFormOrtu = (value) => {
    const validValues = (Object.values(value)).filter(e => !!e);
    setIsNpwpRequired(!!value.penghasilan);
    setIsFormRequired(validValues.length > 0);
  }

  const handleFormChange = (event) => {
    // event.persist();
    if (!debouncedFn) {
      debouncedFn =  _.debounce(async  () => {
        const newValue = form.getFieldsValue();
        // setValue(newValue);
        validateFormOrtu(newValue);
      }, 300)
    }
    debouncedFn();
  }

  const subject = props.title.split(' ');

  const autoFill = async () => {
    form.setFieldsValue({
      "namaWali": "maximus",
      "agama": "Kristen",
      "noHp": "31232132132",
      "alamat": "Jalan bueno aieres no 7 cimanuk",
      "rt": "09",
      "rw": "03",
      "kodePos": "12333",
      "kelurahan": "argentina",
      "kecamatan": "america",
      "kotaKab": "america",
      "email": "maximus@gmail.com",
      "noTelpRumah": "3122312312",
      "pendidikanTerakhir": "sma",
      "profesi": "2321",
      "namaKantor": "kantor",
      "bidangUsaha": "dadas",
      "alamatKantor": "dsadw",
      "noTelpKantor": "12312",
      "penghasilan": 2312300,
      "npwp": "23123",
      "pbb": "32131",
      fileNpwp: {fileName: 'file/00.jpg'},
      fileSlipGaji: {fileName: 'file/00.jpg'},
      filePbb: {fileName: 'file/00.jpg'},
      fileSpt: {fileName: 'file/00.jpg'},
      fileKtp: {fileName: 'file/00.jpg'},
    });
    setIsFormRequired(true);
    await form.validateFields().then(_ => {}).catch(_ => {});
  }
  
  return (
    <Form form={form} name={props.contentKey} onValuesChange={handleFormChange}>
      <Row gutter={20}>
        <Col span={24} style={{marginBottom: '20px'}}>
          <Text>Nama {subject[1]} <RedStar /></Text>
          <Form.Item
            style={{marginBottom: 0}}
            name="namaWali"
            rules={[{ required: isFormRequired, message: 'Nama '+subject[1]+' harus diisi' }]}
          >
            <Input className="input-modal" placeholder={`Nama Lengkap ${subject[1]} sesuai Akta Kelahiran`} maxLength={50} />
          </Form.Item>
          <Text style={{color: '#1890ff', fontStyle: 'italic'}}>Nama Lengkap sesuai Akta Kelahiran</Text>
        </Col>
        <Col lg={12} span={24}>
          <Text>Agama <RedStar /></Text>
          <Form.Item
            name="agama"
            rules={[{ required: isFormRequired, message: 'Agama harus diisi' }]}
          >
            <SelectComponent items={masterData['agama']} showSearch={false} labelKey="namaAgama" valueKey="namaAgama" placeholder="Pilih Agama" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>No. HP <RedStar /></Text>
          <Form.Item
            name="noHp"
            rules={[{ 
              required: isFormRequired, message: 'Nomor HP harus diisi'},
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan Nomor telepon"}
            ]}
          >
            <Input className='input-modal' maxLength={15} min={0} style={{width: "100%"}} />
          </Form.Item>
        </Col>
        <Col span={24} style={{marginBottom: '20px'}}>
          <Text>Alamat Lengkap <RedStar /></Text>
          <Form.Item
            style={{marginBottom: 0}}
            name="alamat"
            rules={[{ required: isFormRequired, message: 'Alamat harus diisi' }]}
          >
            <Input className="input-modal" placeholder="Alamat lengkap sesuai KK" maxLength={100} />
          </Form.Item>
          <Text style={{color: '#1890ff', fontStyle: 'italic'}}>Alamat Sesuai KK</Text>
        </Col>
        <Col span={4}>
          <Text>RT <RedStar /></Text>
          <Form.Item
            name="rt"
            rules={[
              { required: isFormRequired, message: 'RT harus diisi' },
              { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }
            ]}
          >
            <Input className='input-modal' maxLength={3} style={{width: "100%"}} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Text>RW <RedStar /></Text>
          <Form.Item
            name="rw"
            rules={[
              { required: isFormRequired, message: 'RW harus diisi' },
              { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }
            ]}
          >
            <Input className='input-modal' maxLength={3} style={{width: "100%"}}  />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Text>Kelurahan <RedStar /></Text>
          <Form.Item
            name="kelurahan"
            rules={[{ 
              required: isFormRequired, message: 'Kelurahan harus diisi' }]}
          >
            <Input className='input-modal' maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Text>Kode Pos <RedStar /></Text>
          <Form.Item
            name="kodePos"
            rules={[
              { required: isFormRequired, message: 'Kode Pos harus diisi' },
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka "}
            ]}
          >
            <Input className='input-modal' maxLength={5} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Kecamatan <RedStar /></Text>
          <Form.Item
            name="kecamatan"
            rules={[{ required: isFormRequired, message: 'Kecamatan harus diisi' }]}
          >
            <Input className="input-modal" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Kota/Kabupaten <RedStar /></Text>
          <Form.Item
            name="kotaKab"
            rules={[{ required: isFormRequired, message: 'Kota/Kabupaten harus diisi' }]}
          >
            <Input className="input-modal" maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Text>E-mail </Text>
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'Harap masukan format e-mail' }
            ]}
          >
            <Input className="input-modal" placeholder="Alamat E-mail" maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text>Pendidikan Terakhir <RedStar /></Text>
          <Form.Item
            name="pendidikanTerakhir"
            rules={[
              { required: isFormRequired, message: 'Pendidikan Terakhir harus diisi' },
            ]}
          >
            <SelectComponent items={masterData['pendidikanTerakhir']} showSearch={false} valueKey="value" placeholder="Pilih Pendidikan Terakhir" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>No. Telepon Rumah</Text>
          <Form.Item
            name="noTelpRumah"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className="input-modal" maxLength={15} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Profesi <RedStar /></Text>
          <Form.Item
            name="profesi"
            rules={[
              { required: isFormRequired, message: 'Profesi harus diisi' },
            ]}
          >
            <Input className="input-modal" maxLength={100} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Nama Kantor</Text>
          <Form.Item
            name="namaKantor"
          >
            <Input className="input-modal" maxLength={100} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Bidang Usaha</Text>
          <Form.Item
            name="bidangUsaha"
          >
            <Input className="input-modal" maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>Alamat Kantor</Text>
          <Form.Item
            name="alamatKantor"
          >
            <Input className="input-modal" maxLength={255} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>No. Telepon Kantor</Text>
          <Form.Item
            name="noTelpKantor"
            rules={[
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className="input-modal" maxLength={15} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Nilai Penghasilan <RedStar /> <AppHint text="Jika tidak memiliki nilai penghasilan, diisi dengan angka nol (0)" /></Text>
          <Form.Item
            name="penghasilan"
            rules={[{required: isFormRequired, message: 'Nilai Penghasilan harus diisi', pattern: new RegExp('^[0-9]+$')}]}
            className="no-handler"
          >
            <MoneyInput maxLength={16}  />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>NPWP</Text>
          <Form.Item
            name="npwp"
            rules={[
              { required: false, message: 'NPWP harus diisi' },
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <Input className="input-modal" maxLength={15} />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Pajak Bumi Bangunan <RedStar /></Text>
          <Form.Item
            name="pbb"
            className="no-handler"
            rules={[
              { required: isFormRequired, message: 'Pajak Bumi Bangunan harus diisi' },
              {pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka"}
            ]}
          >
            <MoneyInput maxLength={16} />
          </Form.Item>
        </Col>
        <Col span={24}>
        {/* Upload FIles */}
        </Col>
        <Col lg={12} span={24}>
          <Text>Hasil Scan KTP {isFormRequired && <RedStar />}</Text>
          <Form.Item
            name="fileKtp"
            rules={[{required: isFormRequired, message: 'Scan KTP harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              key="uploadKtp"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/ktp'}
              title="Upload Scan KTP"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Hasil Scan PBB {isFormRequired && <RedStar />}</Text>
          <Form.Item
            name="filePbb"
            rules={[{required: isFormRequired, message: 'Scan PBB harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              key="uploadPbb"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/pbb'}
              title="Upload Scan PBB"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Hasil Scan Slip Gaji</Text>
          <Form.Item
            name="fileSlipGaji"
            rules={[{required: false, message: 'Scan slip gaji harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              key="uploadSlipGaji"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/slipGaji'}
              title="Upload Scan Gaji"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Hasil Scan NPWP</Text>
          <Form.Item
            name="fileNpwp"
            rules={[{required: false, message: 'Scan NPWP harus diinput' }]}
          >
            <UploadComponent fluidBtn showDesc
              accept=".jpg,.jpeg,.pdf"
              key="uploadnpwp"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/npwp'}
              title="Upload Scan NPWP"
              onChange={_ => {}}
            />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Hasil SPT</Text>
          <Form.Item
            name="fileSpt"
          >
            <UploadComponent fluidBtn
              accept=".jpg,.jpeg,.pdf"
              key="uploadSpt"
              url={CONFIG.BASE_URL_NOAUTH+'/api/upload/spt'}
              title="Upload Scan SPT"
              maxSize={5}
            />
          </Form.Item>
        </Col>
      </Row>

      { !props.isLoading && <div className="steps-action">
        {
          CONFIG.isServer !== 'Production' &&
          <Button style={{ marginLeft: 20 }} type="secondary" onClick={autoFill} className="app-btn">AUTO FILL</Button>
        }
        <Button style={{ marginLeft: 20 }} className="app-btn primary" onClick={_ => { props.onPrev(contentKey, form.getFieldsValue()); }}>
          Sebelumnya
        </Button>
        
        {!props.isLast && (
          <Button style={{ marginLeft: 20 }} type="primary" className="app-btn primary" onClick={_ => {
            form.validateFields().then(values => {
              if (props.contentKey === 'dataIbu' && !props.hasDataWali && !props.isRevisi) {
                confirmDialog2({text: 'Apakah Anda memiliki wali?', title: ''}).then(_ => {
                  props.onNext(contentKey, form.getFieldsValue());
                }).catch(_ => {
                  let messageValidation = "Apakah Anda yakin ingin menyimpan data diri? \nData yang telah disimpan tidak dapat diubah kembali."
                  if(!props.bodyValues.dataAyah?.namaWali && !props.bodyValues.dataIbu?.namaWali){
                    messageValidation = "Apakah Data Ayah/Ibu tidak diisi? \n karena halaman form pengisian Data Ayah/Ibu seringkali hilang sehingga tidak bisa input."
                  }
                  confirmDialog({title: 'Simpan data diri?', text: messageValidation}).then(e => {
                    // console.log('Validate Failed:', form.getFieldsValue())
                    props.onDone(form.getFieldsValue());
                  }).catch(e => {});
                })
              } else {
                props.onNext(contentKey, form.getFieldsValue());
              }
            }).catch(info => console.log('Validate Failed:', info) );
          }}>
            Selanjutnya
          </Button>
        )}

        {props.isLast && (
          <Button type="primary" style={{ marginLeft: 20 }} className="app-btn primary" onClick={_ => {
            form.validateFields().then(values => {
              let messageValidation = "Apakah Anda yakin ingin menyimpan data diri? \nData yang telah disimpan tidak dapat diubah kembali."
              if(!props.bodyValues?.dataAyah?.namaWali && !props.bodyValues?.dataIbu?.namaWali){
                messageValidation = "Apakah Data Ayah/Ibu tidak diisi? \n karena halaman form pengisian Data Ayah/Ibu seringkali hilang sehingga tidak bisa input."
              }
              confirmDialog({title: 'Simpan data diri?', text: messageValidation}).then(e => {
                // console.log('Validate Failed:', form.getFieldsValue())
                props.onDone(form.getFieldsValue());
              }).catch(e => {});
            }).catch(info => console.log('Validate Failed:', info) );
          }}>
            Simpan
          </Button> 
        )}
      </div>}
    </Form>
  )
}
