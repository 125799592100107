import React from 'react'
import { Col, Row, Card, Button } from 'antd';

function Verifikasi(props) {
  const headTitle = props.dataKomit.komitmenUps !== 'Negosiasi' ? props.dataKomit.komitmenUps !== 'Flat Offer' ? props.dataKomit.komitmenUps : undefined : undefined  
  const isCancel = props.dataKomit.isAlreadyCancel
  const isUtama = props.dataKomit.statusKomitmenUtama !== undefined ? !props.dataKomit.statusKomitmenUtama : false
  const isTambahan = props.dataKomit.statusKomitmenTambahan !== undefined  ? !props.dataKomit.statusKomitmenTambahan : false
  const isNego = props.dataKomit.statusKomitmenNegosiasi !== undefined ? !props.dataKomit.statusKomitmenNegosiasi : false
  
  return(
    <>
      <Row gutter={[40, 24]}>
        <Col span={24}>
          <Card className="app-card-info-user" style={{height: '100%', textAlign: 'center'}}>
            <span className='head-modal' style={{ position: 'absolute', left: '26px'}}>
              {headTitle ? headTitle !== '' ? headTitle.toUpperCase() + ' PACKAGE' + '\n' : '\n' : ''}
            </span>
            <span className='head-modal' style={{ fontWeight: '600'}} >
              {props.dataKomit.komitmenUps === 'Negosiasi' ? 'Negosiasi' : 'KOMITMEN UPS & US YANG DISETUJUI OLEH TIM FINANCE & SCHOLARSHIP'}
            </span>
          </Card>
        </Col>
      </Row>
      <Row gutter={[40, 24]}>
        <Col span={24}>
          <Card className="app-card-info-user" style={{height: '100%', textAlign: 'center'}}>
            <Row gutter={[16, 16]}>
              <Col span={12} style={{ textAlign: 'left', marginBottom: '30px' }} >
                <span className='head-modal' style={{ color: '#000000'}} >
                  {props.dataKomit.komitmenUps === 'Negosiasi' ? 'Komitmen Yang Diajukan' : 'Beasiswa Yang Diajukan' }
                </span>
              </Col>
              <Col span={12} style={{ marginBottom: '30px' }}>
                <span className='head-modal' style={{ color: '#000000'}} >
                  {props.dataKomit.komitmenUps === 'Negosiasi' ? 'Status Pengajuan' : 'Status Pengajuan Beasiswa' }
                </span>
              </Col>
              {props.dataKomit.komitmenUps === 'Negosiasi' ? (
                <>
                  <Col span={12} style={{ textAlign: 'left'}} >
                  <span className='head-modal' style={{ fontWeight: 'normal' }} >
                    • Negosiasi
                  </span>
                  </Col>
                  <Col span={12} >
                    <span className='head-modal' 
                      style={{ color: '#000000',  fontWeight: 'normal'} }
                    >
                      {props.dataKomit.statusNegosiasi}
                    </span>
                  </Col>
                  <Col span={24} style={{ textAlign: 'left', marginTop: '50px'}}>
                    <span className='text-diskon'>
                      * Mohon Menunggu Informasi Selanjutnya Via E-mail
                    </span>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={12} style={{ textAlign: 'left'}} >
                    <span className='head-modal' style={{ fontWeight: 'normal' }} >
                      • Beasiswa Utama: {props.dataKomit.beasiswaUtama === 'Tidak Mengajukan' ? 'Tidak Memilih Beasiswa' : props.dataKomit.beasiswaUtama}
                    </span>
                  </Col>
                  <Col span={12} >
                    <span className='head-modal' 
                      style={props.dataKomit.beasiswaUtama === 'Tidak Mengajukan' ? { color: '#E04C4C', fontWeight: 'normal'} : { color: '#000000',  fontWeight: 'normal'} }
                    >
                      {props.dataKomit.beasiswaUtama === 'Tidak Mengajukan' ? props.dataKomit.beasiswaUtama : props.dataKomit.statusBeasiswaUtama }
                    </span>
                  </Col>
                  <Col span={12} style={{ textAlign: 'left'}} >
                    <span className='head-modal' style={{ fontWeight: 'normal' }} >
                      • Beasiswa Tambahan: {props.dataKomit.beasiswaTambahan === 'Tidak Mengajukan' ? 'Tidak Memilih Beasiswa' : props.dataKomit.beasiswaTambahan}
                    </span>
                  </Col>
                  <Col span={12} >
                    <span 
                      className='head-modal' 
                      style={props.dataKomit.beasiswaTambahan === 'Tidak Mengajukan' ? { color: '#E04C4C', fontWeight: 'normal'} : { color: '#000000',  fontWeight: 'normal'} } 
                    >
                      {props.dataKomit.beasiswaTambahan === 'Tidak Mengajukan' ? props.dataKomit.beasiswaTambahan : props.dataKomit.statusBeasiswaTambahan}
                    </span>
                  </Col>
                </>
              )}
            </Row>
            { (props.dataKomit.statusBeasiswaUtama !== 'Belum diverifikasi' && props.dataKomit.beasiswaTambahan !== 'Belum diverifikasi')&&
              <Row>
                <Col span={24} style={{ textAlign: 'left'}}>
                    <span className='text-diskon'>
                      * Langkah selanjutnya, siswa harus menyetujui nilai komitmen yang sudah diverifikasi, sebelum melakukan pembayaran
                    </span>
                  </Col>
              </Row>
            }
          </Card>
        </Col>
        { (isUtama || isTambahan ) || (isNego || isCancel) ? (
          <></>
        ) : (
          <Col span={24}>
            <Button
              className='app-btn primary xl bold'
              style={{ float: 'right'}}
              onClick={() => props.getNilaiKomit()}
              >
              Nilai Komitmen
            </Button>
          </Col>
        )}
      </Row>
    </>
  )

}

export default Verifikasi