export default {
    "data": [
        {
            "duration": 100000,
            "image": null,
            "soal": "Pola persajakan bait pada puisi di atas ialah",
            "soalId": 1,
            "pilihan": [
                {
                    "key": "A",
                    "value": "abab",
                    "isText": true
                },
                {
                    "key": "B",
                    "value": "aabb",
                    "isText": true
                },
                {
                    "key": "C",
                    "value": "aaaa",
                    "isText": true
                },
                {
                    "key": "D",
                    "value": "bbaa",
                    "isText": true
                }
            ],
            "type": 1
        },
        {
            "duration": 180,
            "image": "https://storage.fsm79.com:9000/loyola-dev/gambar.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200909%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200909T085222Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d22c39e2751f626937cbe0fbf8e4c492b2039b893e4a6263a6153f6adf354c27",
            "soal": "Anekdot adalah",
            "soalId": 2,
            "pilihan": [
                {
                    "key": "A",
                    "value": 'https://vignette.wikia.nocookie.net/naruto/images/0/09/Naruto_newshot.png/revision/latest/scale-to-width-down/340?cb=20170621101134',
                    "isText": false
                },
                {
                    "key": "B",
                    "value": 'https://vignette.wikia.nocookie.net/naruto/images/0/09/Naruto_newshot.png/revision/latest/scale-to-width-down/340?cb=20170621101134',
                    "isText": false
                },
                {
                    "key": "C",
                    "value": 'https://vignette.wikia.nocookie.net/naruto/images/0/09/Naruto_newshot.png/revision/latest/scale-to-width-down/340?cb=20170621101134',
                    "isText": false
                },
                {
                    "key": "D",
                    "value": 'https://vignette.wikia.nocookie.net/louser/images/f/f0/Sasuke.jpeg/revision/latest/scale-to-width-down/340?cb=20130217024213',
                    "isText": false
                }
            ],
            "type": 1
        },
        {
            "duration": 180,
            "image": null,
            "soal": "Apa yang dimaksud dengan berita",
            "soalId": 1,
            "type": 2
        },
        {
            "duration": 180,
            "image": "https://storage.fsm79.com:9000/loyola-dev/gambar.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20200909%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200909T085222Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=d22c39e2751f626937cbe0fbf8e4c492b2039b893e4a6263a6153f6adf354c27",
            "soal": "Ini pertanyaan esai yg ada gambar",
            "soalId": 2,
            "type": 2
        }
    ]
}