// setting your configuration server

//---- please insert server your developer BE in here and set
const FE_NAME = "sdw"
const BE_NAME = "dienal"
const BASE_URL_BE = "http://25.19.223.252:9015" 
const BASE_URL_LOGIN_BE = "http://25.19.223.252:9015"
const BASE_URL_NOAUTH_BE = "http://25.19.223.252:9015"

//---- please change comment if you use server BE local 
const isServerBE = true
// const isServerBE = false

//---- please change comment if you use testmode
// const testMode = true
const testMode = false

//---- only for TECH LEAD
const isProd = true
// const isProd = false

// export default  {
//     FE_NAME: isServerBE ? FE_NAME : '',
//     BE_NAME: isServerBE ? BE_NAME : '',
//     isServer: isProd ? 'Production' : (isServerBE ? 'BE_DEVELOPER' : "Staging"),
//     BASE_URL: isProd ? "https://loyola.fsm79.com" : (isServerBE ? BASE_URL_BE : "http://staging"),
//     BASE_URL_LOGIN: isProd ? "https://loyola.fsm79.com" : (isServerBE ? BASE_URL_LOGIN_BE : "http://staging"),
//     BASE_URL_NOAUTH: isProd ? "https://loyola.fsm79.com" : (isServerBE ? BASE_URL_NOAUTH_BE : "http://staging"),
//     COPYRIGHT: '20200716',
//     VERSION: '0.0.1',
//     TIMEOUT: 6000,
//     TESTMODE: testMode
// }

//deployment urgent
export default  {
    FE_NAME: '',
    BE_NAME: '',
    isServer: 'Production',
    BASE_URL: "https://yayasanloyola.org/proxy/be",
    BASE_URL_LOGIN: "https://yayasanloyola.org/proxy/auth",
    BASE_URL_NOAUTH: "https://yayasanloyola.org/proxy/be",
    COPYRIGHT: '20200716',
    VERSION: '0.0.1',
    TIMEOUT: 6000,
    TESTMODE: testMode
}