import React from 'react'
import { Col, Button, Radio, Input, Select } from 'antd';
import { CheckCircleFilled, UploadOutlined, DeleteFilled } from '@ant-design/icons'

const { Option } = Select

function SeniOlahraga(props) {

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginTop: '10px',
    fontWeight: 'normal'
  };

  return(
    <>
      <Col xs={24} lg={18}>
        <span className='text-header-finance'>
          Beasiswa Prestasi {props.from === 'seni' ? 'Seni' : 'Olahraga'}
        </span>
      </Col>
      <Col xs={24} lg={6}>
        <span className='text-detail-lov' onClick={() => props.handleDetail('tabel', 'Tabel Diskon untuk Seni dan Olahraga')} >
          Lihat Tabel Diskon
        </span>
      </Col>
      <Col span={24}>
        <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
          Silahkan Mencantumkan 1 Prestasi {props.from === 'seni' ? 'seni ' : 'olahraga '} 
          di masa SMP dengan Pencapaian Prestasi 
          yang Tertinggi.
        </span>
      </Col>
      <Col xs={22} lg={10}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          Nama Kejuaraan <span className='text-Kekl' style={{color: '#EB5757', fontWeight: 'bold', fontSize:'18px'}} >*</span>
        </span>
      </Col>
      <Col span={2} style={{ alignSelf: 'flex-end'}} >
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          :
        </span>
      </Col>
      <Col xs={24} lg={12} style={{ alignSelf: 'flex-end'}}>
        <Input 
          maxLength={254}
          placeholder='Input Nama Kejuaraan'
          className='inpt-seni'
          value={props.dataKejuaraan.namaKejuaraan} 
          onChange={(e) => props.handleKejuaraan('nama', e.target.value)}
        />
      </Col>
      <Col xs={22} lg={10}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          Tahun Kejuaraan <span className='text-Kekl' style={{color: '#EB5757', fontWeight: 'bold', fontSize:'18px'}} >*</span>
        </span>
      </Col>
      <Col span={2} style={{ alignSelf: 'flex-end'}} >
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          :
        </span>
      </Col>
      <Col xs={24} lg={12} style={{ alignSelf: 'flex-end'}} >
        <Select 
          placeholder='Input Tahun Kejuaraan' 
          onChange={(e) => props.handleKejuaraan('tahun', e)}
          values={props.dataKejuaraan.tahunKejuaraan}
          className='slct-seni'
        >
          {props.dataLOV.tahun.length > 0 ? props.dataLOV.tahun.map(res => (
            <Option 
              value={res.tahun}
            >
              {res.tahun}
            </Option>
          )) : <></>}
        </Select>
      </Col>
      <Col xs={22} lg={10}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          Nama Penyelenggara <span className='text-Kekl' style={{color: '#EB5757', fontWeight: 'bold', fontSize:'18px'}} >*</span>
        </span>
      </Col>
      <Col span={2} style={{ alignSelf: 'flex-end'}}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          :
        </span>
      </Col>
      <Col xs={24} lg={12} style={{ alignSelf: 'flex-end'}}>
        <Input 
          maxLength={254}
          placeholder='Input Nama Penyelenggara'
          className='inpt-seni'
          value={props.dataKejuaraan.namaPenyelenggara} 
          onChange={(e) => props.handleKejuaraan('penyelenggara', e.target.value)}
        />
      </Col>
      <Col xs={22} lg={10}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          Tingkat
        </span>
      </Col>
      <Col span={2}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          :
        </span>
      </Col>
      <Col xs={24} lg={12}>
        <Radio.Group 
          key={'tingkat'} 
          style={{ width: '100%'}}
          value={props.dataKejuaraan.tingkat} 
          onChange={(e) => props.handleKejuaraan('tingkat', e.target.value)}
        >
          {props.dataLOV.tingkat.length > 0 ? props.dataLOV.tingkat.map((res, index) => (
            <Radio style={radioStyle} key={res.tingkat + 'tingkat' + index} value={res.tingkat}>
              {res.tingkatName}
            </Radio>
            )
          ) : <></>}
        </Radio.Group>
      </Col>
      <Col xs={22} lg={10}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          Kategori
        </span>
      </Col>
      <Col span={2}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          :
        </span>
      </Col>
      <Col span={12}>
        <Radio.Group 
          key={'kategori'} 
          style={{ width: '100%'}}
          value={props.dataKejuaraan.kategori} 
          onChange={(e) => props.handleKejuaraan('kategori', e.target.value)}
        >
          {props.dataLOV.kategori.length > 0 ? props.dataLOV.kategori.map((res, index) => (
            <Radio style={radioStyle} key={res.kategori + 'kategori' + index} value={res.kategori}>
              {res.kategoriName}
            </Radio>
            )
          ) : <></>}
        </Radio.Group>
      </Col>
      <Col xs={22} lg={10}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          Juara
        </span>
      </Col>
      <Col span={2}>
        <span className='text-header-finance' style={{ fontWeight: 'normal', color: '#222222'}}>
          :
        </span>
      </Col>
      <Col xs={24} lg={12}>
        <Radio.Group 
          key={'juara'} 
          style={{ width: '100%'}}
          value={props.dataKejuaraan.juara} 
          onChange={(e) => props.handleKejuaraan('juara', e.target.value)}
        >
          {props.dataLOV.juara.length > 0 ? props.dataLOV.juara.map((res, index) => (
            <Radio style={radioStyle} key={res.juara + 'juara' + index} value={res.juara}>
              {res.juaraName}
            </Radio>
            )
          ) : <></>}
        </Radio.Group>
      </Col>
      <Col xs={24} lg={17} style={{textAlign: 'right'}} > 
        <span className='text-Kekl'>
          Upload sertifikat  yang telah dilegalisir
        </span>
        <br></br>
        <span className='text-kekl' style={{ color: '#000000', fontSize: '11px'}}>
          maximal size (1mb) , tipe dokumen (pdf, jpg) 
        </span>
      </Col>
      <Col xs={24} lg={7} style={{textAlign: 'right'}}>
        <input
          type="file"
          hidden
          onChange={(e) => props.fileHandler(e, 'sertifikat')}
          ref={props.fileInputSeni}
        />
        <Button
          className='btn-upload'
          size="large"
          block
          onClick={() => props.openFileBrowser('sertifikat')}
          loading={props.loadingUpload.seni}
        >
          <UploadOutlined />
          Upload
          {props.filePathSeni && <CheckCircleFilled style={{ color: '#27ae60'}} />}
        </Button>
      </Col>
      <Col span={24} style={{marginBottom: '30px', textAlign: 'right'}}>
        <span>
            { props.fileNameSeni ? (
              props.fileNameSeni
              ) : ('')
            }
        </span>
        { props.filePathSeni && 
          <DeleteFilled
            style={{ marginLeft: '1rem' }}
            onClick={() => props.deleteUpload('sertifikat')}
          />
        }
      </Col>
      <Col span={24} style={{ marginTop: '30px'}}>
        <span className='text-diskon'>
          * Pastikan data yang diisi/upload adalah benar, <br></br>
            data yang tidak sesuai akan menyebabkan pengajuan beasiswa ditolak
        </span>
      </Col>
    </>
  )

}

export default SeniOlahraga