import axios from "axios";
import Auth from "./auth";
import CONFIG_INITIAL_STATE from './config';

const client = axios.create({
  baseURL: CONFIG_INITIAL_STATE.BASE_URL,
  headers: {
    "Authorization": `Bearer ${sessionStorage.getItem('token')}`,
    "Content-type": "application/json"
  }
})

client.interceptors.request.use(
  function (configuration) {
      const token = sessionStorage.getItem('token');
      if (token !== null) {
          configuration.headers.Authorization = `Bearer ${token}`;
      }
      return configuration;
  },
  function (err) {
    console.log('masuk error : ',err)
      throw new Error(err);
  }
);

client.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  let messageErrorResponse = error.response && error.response.data && error.response.data.error || ''
  let messageErrorStatus = error.response && error.response.status || ''
  if(messageErrorResponse == 'Unauthorized' || messageErrorStatus == 401){
      Auth.logout()
      window.location = '/';
      // window.location.reload(false)
  }
  return Promise.reject(error);
});


export default client;