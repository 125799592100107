/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input, Col, Row, Form, Typography, Card, Radio } from 'antd';
import { RedStar } from '../../../component/common';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const {Text, Title} = Typography;

DataPribadi.propTypes = {
  onChange: PropTypes.func
};


export default function DataPribadi(props) {
  const [form] = Form.useForm();
  const {onChange, initValue, formId, formTitle, isValidating} = props;
  const [isSedanCidera, setIsSedanCidera] = useState(false);
  let debouncedFn;

  useEffect(() => {
    if(!!initValue) {
      form.setFieldsValue(initValue.value);
      onChange(formId, initValue)
    }
  }, [initValue]);

  useEffect(() => {
    if (!!isValidating) {
      form.validateFields().then(_ => onChange(formId, {valid: true}))
      .catch(_ => onChange(formId, {valid: false}))
    }
  }, [isValidating])

  const handleChange = (ev) => {
    // console.log()
    if (!debouncedFn) {
      debouncedFn = debounce(async () => {
        form.validateFields().then(_ => onChange(formId, {valid: true, value: form.getFieldsValue()}))
        .catch(_ => onChange(formId, {valid: false, value: form.getFieldsValue()}))
      }, 300)
    }
    debouncedFn();
    // console.log(!!ev.sedangCideraSakit)
    if(!!ev.sedangCideraSakit) { setIsSedanCidera(true); } 
    else { setIsSedanCidera(false); }
  } 

  return (
    <Card>
      <Form name={formId} form={form} onValuesChange={handleChange}>
        <Row gutter={20}>
          <Col span={24} style={{borderBottom: '1px solid rgb(217, 217, 217)', marginBottom: '1em'}}><Title level={4}>{formTitle}</Title></Col>
          <Col span={24}>
            <Text>Apakah sedang mengalami kondisi cedera atau menderita sakit khusus saat ini? <RedStar /></Text>
            <Form.Item
              name="sedangCideraSakit"
              rules={[{ required: true, message: 'Konfirmasi cedera/sakit khusus yang dialami saat ini harus diisi' }]}
            >
              <Radio.Group>
                <Radio value={true}>Ya</Radio>
                <Radio value={false}>Tidak</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Jika Ya, jelaskan apa kondisi sakit yang sedang Anda alami saat ini!  {isSedanCidera && <RedStar />}</Text>
            <Form.Item
              name="detailCideraSakit"
              rules={[{ required: isSedanCidera, message: 'Detail cedera/sakit khusus yang dialami saat ini harus diisi' }]}
            >
              <Input.TextArea className="input-modal" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Sejak kapan mulai mengalami sakit tersebut? (Jelaskan secara detail!)</Text>
            <Form.Item
              name="awalCideraSakit"
            >
              <Input.TextArea className="input-modal" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Apakah Anda mempunyai riwayat cedera atau sakit tertentu? (Bila ada, silahkan jelaskan!)</Text>
            <Form.Item
              name="riwayatCideraSakit"
            >
              <Input.TextArea className="input-modal" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Sejak kapan mengalami cedera tersebut? (Jelaskan secara detail!)</Text>
            <Form.Item
              style={{marginBottom: '0px'}}
              name="awalRiwayatCideraSakit"
            >
              <Input.TextArea className="input-modal" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
