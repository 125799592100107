import React  from 'react'
import { Col, Button } from 'antd';
import { CheckCircleFilled, UploadOutlined, DeleteFilled } from '@ant-design/icons'

function OfoN(props) {

  return(
    <>
      <Col span={24}>
        <span className='text-header-finance'>
          Beasiswa OfoN
        </span>
      </Col>
      <Col span={24}>
        <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
          Upload Berkas
          <span className='text-Kekl' style={{color: '#EB5757', fontWeight: 'bold', fontSize: '18px'}} > * </span>
           :
        </span>
      </Col>
      <Col span={24}>
        <span className='text-Kekl'>
          Silahkan upload minimal 1 dokumen
        </span>
      </Col>
      <Col xs={24} lg={7} style={{ alignSelf: 'center' }}>
        <input
          type="file"
          hidden
          onChange={(e) => props.fileHandler(e, 'yatim')}
          ref={props.fileInputYatim}
        />
        <Button
          className='btn-upload'
          size="large"
          block
          onClick={() => props.openFileBrowser('yatim')}
          loading={props.loadingUpload.yatim}
        >
          <UploadOutlined />
          Upload
          {props.filePathYatim && <CheckCircleFilled style={{ color: '#27ae60'}} />}
        </Button>
      </Col>
      <Col xs={24} lg={17} > 
        <span className='text-Kekl'>
          Single Parent / Yatim / Piatu atau Yatim Piatu
          <br></br>
          (Bukti : Surat Kematian / KK / Surat Cerai )
        </span>
        <br></br>
        <span className='text-kekl' style={{ color: '#000000', fontSize: '11px'}}>
          maximal size (1mb) , tipe dokumen (pdf, jpg) 
        </span>
      </Col>
      <Col span={24} style={{marginBottom: '30px'}}>
        <span>
            { props.fileNameYatim ? (
              props.fileNameYatim
              ) : ('')
            }
        </span>
        { props.filePathYatim && 
          <DeleteFilled
            style={{ marginLeft: '1rem' }}
            onClick={() => props.deleteUpload('yatim')}
          />
        }
      </Col>
      <Col xs={24} lg={7} style={{ alignSelf: 'center' }}>
        <input
          type="file"
          hidden
          onChange={(e) => props.fileHandler(e, 'rekamMedis')}
          ref={props.fileInputRekamMedis}
        />
        <Button
          className='btn-upload'
          size="large"
          block
          onClick={() => props.openFileBrowser('rekamMedis')}
          loading={props.loadingUpload.rekamMedis}
        >
          <UploadOutlined />
          Upload
          {props.filePathMedis && <CheckCircleFilled style={{ color: '#27ae60'}} />}
        </Button>
      </Col>
      <Col xs={24} lg={17} > 
        <span className='text-Kekl'>
          Kedua orang tua dalam kondisi tidak dapat bekerja dikarenakan sakit atau cedera
          <br></br>
          (Bukti : Surat Rekom Medis dari Rumah Sakit)
        </span>
        <br></br>
        <span className='text-kekl' style={{ color: '#000000', fontSize: '11px'}}>
          maximal size (1mb) , tipe dokumen (pdf, jpg) 
        </span>
      </Col>
      <Col span={24} style={{marginBottom: '30px'}}>
        <span>
            { props.fileNameMedis ? (
              props.fileNameMedis
              ) : ('')
            }
        </span>
        { props.filePathMedis && 
          <DeleteFilled
            style={{ marginLeft: '1rem' }}
            onClick={() => props.deleteUpload('rekamMedis')}
          />
        }
      </Col>
      <Col xs={24} lg={7}  style={{ alignSelf: 'center' }}>
        <input
          type="file"
          hidden
          onChange={(e) => props.fileHandler(e, 'tempatTinggal')}
          ref={props.fileInputTempatTinggal}
        />
        <Button
          className='btn-upload'
          size="large"
          block
          onClick={() => props.openFileBrowser('tempatTinggal')}
          loading={props.loadingUpload.tempatTinggal}
        >
          <UploadOutlined />
          Upload
          {props.filePathTinggal && <CheckCircleFilled style={{ color: '#27ae60'}} />}
        </Button>
      </Col>
      <Col xs={24} lg={17} > 
        <span className='text-Kekl'>
          Tidak memiliki tempat tinggal dikarenakan bencana alam
          <br></br>
          (Bukti : Scan berita harian nasional, alamat dan foto tempat tinggal sebelum dan sesudah bencana )
        </span>
        <br></br>
        <span className='text-kekl' style={{ color: '#000000', fontSize: '11px'}}>
          maximal size (1mb) , tipe dokumen (pdf, jpg) 
        </span>
      </Col>
      <Col span={24} style={{marginBottom: '30px'}}>
        <span>
            { props.fileNameTinggal ? (
              props.fileNameTinggal
              ) : ('')
            }
        </span>
        { props.filePathTinggal && 
          <DeleteFilled
            style={{ marginLeft: '1rem' }}
            onClick={() => props.deleteUpload('tempatTinggal')}
          />
        }
      </Col>
      <Col xs={24} lg={7} style={{ alignSelf: 'center' }}>
        <input
          type="file"
          hidden
          onChange={(e) => props.fileHandler(e, 'sktm')}
          ref={props.fileInputSktm}
        />
        <Button
          className='btn-upload'
          size="large"
          block
          onClick={() => props.openFileBrowser('sktm')}
          loading={props.loadingUpload.sktm}
        >
          <UploadOutlined />
          Upload
          {props.filePathSktm && <CheckCircleFilled style={{ color: '#27ae60'}} />}
        </Button>
      </Col>
      <Col xs={24} lg={17} > 
        <span className='text-Kekl'>
          Surat Keterangan Tidak Mampu
          <br></br>
          (Bukti : Scan Surat Keterangan Tidak Mampu )
        </span>
        <br></br>
        <span className='text-kekl' style={{ color: '#000000', fontSize: '11px'}}>
          maximal size (1mb) , tipe dokumen (pdf, jpg) 
        </span>
      </Col>
      <Col span={24} style={{marginBottom: '30px'}}>
        <span>
            { props.fileNameSktm ? (
              props.fileNameSktm
              ) : ('')
            }
        </span>
        { props.filePathSktm && 
          <DeleteFilled
            style={{ marginLeft: '1rem' }}
            onClick={() => props.deleteUpload('sktm')}
          />
        }
      </Col>
    </>
  )

}

export default OfoN