/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input, Col, Row, Form, Typography, Card, Radio } from 'antd';
import { RedStar } from '../../../component/common';
import PropTypes from 'prop-types';
import {debounce, isEqual} from 'lodash';

const {Text, Title} = Typography;

const ortuSubject = [
  'Ayah', 'Ibu', 'Wali'
]

DataOrtuWali.propTypes = {
  onChange: PropTypes.func
};

const defaultValue = { value: ortuSubject.map((subject, i) => ({kodeOrtu: i+1})) };

export default function DataOrtuWali(props) {
  const [form] = Form.useForm();
  const [requiredForm, setRequiredForm] = useState([true, true ,true]);
  const {onChange, initValue, formId, isValidating} = props;
  let debouncedFn;

  useEffect(() => {
    // console.log(''{initValue, defaultValue});
    if(!!initValue && initValue.value) {
      form.setFieldsValue(initValue.value);
      onChange(formId, initValue)
      refreshFormValidation();
    } else {
      form.setFieldsValue(defaultValue);
    }
  }, [initValue]);

  useEffect(() => {
    if (!!isValidating) {
      form.validateFields().then(_ => onChange(formId, {valid: true}))
      .catch(_ => onChange(formId, {valid: false}))
    }
  }, [isValidating])
  
  useEffect(() => {
  }, [])


  const handleChange = (ev) => {
    if (!debouncedFn) {
      debouncedFn = debounce(async () => {
        form.validateFields().then(_ => onChange(formId, {valid: true, value: form.getFieldsValue()}))
        .catch(_ => onChange(formId, {valid: false, value: form.getFieldsValue()}))
        refreshFormValidation();
      }, 300)
    }
    debouncedFn()
  } 

  const refreshFormValidation = () => {
    let values = form.getFieldValue('value');
    console.log(values)
    values = values.map(value => {
      const keys = Object.values(value).filter(e => !!e);
      return keys.length > 1;

    }) 
    values = values.filter(e => !!e).length <= 0 ? [true, true, true] : values;
    if (!isEqual(values, requiredForm)){
      setRequiredForm(values);
      form.validateFields().then(_ => {}).catch(_ => {})
    }
  }

  return (
    <Card>
      <div style={{marginBottom: '1em', width: '100%'}}>
        <Card className="app-card paper">
          <Text>Siswa wajib mengisi paling sedikit 1 data Orangtua/Wali</Text>  
        </Card>
        {/* <pre>{JSON.stringify(requiredForm)}</pre> */}
      </div>
      <Form name={formId} form={form} onValuesChange={handleChange} initialValues={{value: []}}>
        <Form.List name="value">
          {(fields, add, remove) => fields.map((field, index) => {
            const namaOrtu = ortuSubject[index];
            return (
              <Row gutter={20} style={index < 2 && {marginBottom: '2em'}} key={`ortuwali-${index}`}>
                <Col span={24} style={{borderBottom: '1px solid rgb(217, 217, 217)', marginBottom: '1em'}}><Title level={4} >Data {ortuSubject[index]}</Title></Col>
                <Col span={24}>
                  <Text>Nama Lengkap {namaOrtu} {requiredForm[index] && <RedStar />}</Text>
                  <Form.Item
                    name={[field.name, "namaLengkap"]}
                    rules={[{ required: requiredForm[index], message: `Nama Lengkap ${namaOrtu} harus diisi` }]}
                  >
                    <Input className="input-modal" placeholder={`Nama Lengkap ${namaOrtu}`} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>Alamat Tempat Tinggal {namaOrtu} {requiredForm[index] && <RedStar />}</Text>
                  <Form.Item 
                    name={[field.name, "alamatTempatTinggal"]} 
                    rules={[{ required: requiredForm[index], message: `Alamat Tempat Tinggal ${namaOrtu} harus diisi` }]}
                  >
                    <Input className="input-modal" placeholder={`Alamat Tempat Tinggal ${namaOrtu}`} maxLength={255} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>Pekerjaan {namaOrtu} {requiredForm[index] && <RedStar />}</Text>
                  <Form.Item
                    name={[field.name, "pekerjaan"]}
                    rules={[{ required: requiredForm[index], message: `Pekerjaan ${namaOrtu} harus diisi` }]}
                  >
                    <Input className="input-modal" placeholder={`Pekerjaan ${namaOrtu}`} maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>Alamat Kantor {namaOrtu} (Bila Ada)</Text>
                  <Form.Item name={[field.name, "alamatKantor"]} >
                    <Input className="input-modal" placeholder={`Alamat Kantor ${namaOrtu}`} maxLength={255} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Text>E-mail {namaOrtu}</Text>
                  <Form.Item
                    name={[field.name, "email"]}
                    rules={[
                      // { required: requiredForm[index], message: `E-mail ${namaOrtu} harus diisi` },
                      { type: 'email', message: 'Harap masukan format e-mail' }
                    ]}
                  >
                    <Input className="input-modal" placeholder={`E-mail ${namaOrtu}`} maxLength={50} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Text>Nomor Telepon {namaOrtu} (Rumah) </Text>
                  <Form.Item
                    name={[field.name, "noTelpRumah"]}
                    style={index === 2 && {marginBottom: '0em'}}
                    rules={[
                      // { required: requiredForm[index], message: `Nomor Telepon ${namaOrtu} (Rumah) harus diisi` },
                      { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }
                    ]}
                  >
                    <Input className="input-modal" placeholder={`Nomor Telepon ${namaOrtu} (Rumah)`} maxLength={15} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Text>Nomor Handphone {namaOrtu} (WhatsApp) {requiredForm[index] && <RedStar />}</Text>
                  <Form.Item
                    name={[field.name, "noHpWa"]}
                    style={index === 2 && {marginBottom: '0em'}}
                    rules={[
                      { required: requiredForm[index], message: `Nomor Handphone ${namaOrtu} (Whatsapp) harus diisi` },
                      { pattern: new RegExp('^[0-9]+$'), message: "Harap di isi dengan angka" }
                    ]}
                  >
                    <Input className="input-modal" placeholder={`Nomor Handphone  ${namaOrtu} (Whatsapp)`} maxLength={15} />
                  </Form.Item>
                </Col>
              </Row>
            )}
          )}
        </Form.List>
      </Form>
      {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
    </Card>
  )
}
