import React, { useState, useRef } from 'react'
import { Modal, Button, Typography, Row, Col} from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import { dialog } from '../../functions/alert'
import { saveFile } from '../../component/upload-component'
import { blobtoFile, dataURItoBlob } from '../../functions/files';
import  CONFIG  from '../../service/config';
import AppCache from '../../service/cache'
import { LoadingOutlined } from '@ant-design/icons'

const {Text} = Typography

async function saveTtd(ttd) {
  return new Promise((resolve, reject) => {
    const blob = dataURItoBlob(ttd);
    const file = blobtoFile(blob, 'file.jpg');
    saveFile(CONFIG.BASE_URL_NOAUTH + '/api/upload/tandaTangan', file).then(res => {
      console.log('Signature saved!');
      resolve(res);
      // sessionStorage.setItem('signature', JSON.stringify(ttd));
    }).catch(err => {
      reject(err);
      // console.log('Failed to save Signature', err);
    })
  })
}

function ModalDashboard (props) {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const sigPad = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAgree = async () => {
    setIsLoading(true);
    console.log("data handleAgree : ", sigPad.current.isEmpty())
    if(!sigPad.current.isEmpty()){
      await saveTtd(sigPad.current.getTrimmedCanvas().toDataURL('image/jpeg')).then(res => {
        console.log('res ttd: ', res.data.fileName);
        AppCache.set('signature', {fileName: res.data.fileName});
        props.pressed(res.data.fileName)
      }).catch(err => {
        dialog({title: 'Gagal menyimpan tanda tangan', text: 'Harap dicoba kembali', icon: 'error'})
      });
    } else {
      dialog({title: 'Gagal', text: 'Anda belum tanda tangan', icon: 'error'});
    }
    setIsLoading(false);
  }

  return(
    <Modal
      destroyOnClose={true}
      visible={props.visible}
      className="form-modal-middle"
      footer={null}
      closable={true}
      onCancel={props.buttonCancel}
      centered
      width="800px"
    >
      <Row style={{justifyContent: 'center', alignItems: 'center', padding: '1em 0px 0px', color: '#242c3c'}}>
        <Col span={24}>
          <p style={{fontWeight: '600', fontSize: '1.2em', textAlign: 'center'}}>
            Dengan ini, saya bertanggung jawab atas kebenaran data yang saya berikan. 
            Adapun segala bentuk pemalsuan/manipulasi data yang kiranya dilakukan, akan disanksi berupa 
            blacklist dari proses PPDB SMA Kolese Loyola Semarang.
          </p>
        </Col>
        <Col span={24} style={{overflow: 'hidden'}}>
          <SignatureCanvas 
          penColor='black'
          backgroundColor={'#D5D5D5'}
          canvasProps={{width: 580, height: 250, className: 'sigCanvas'}} 
          ref={sigPad} 
          />
        </Col>
        <Col span={24} style={{ justifyContent: 'center', display: 'flex', margin: '.5em 0px .5em'}}>
          <Text className="container-signature">Tanda Tangan</Text>
        </Col>
        <Col span={24} style={{ justifyContent: 'center'}}>
          <Button
            style={{width: '100%'}}
            type="primary"
            className="app-btn secondary"
            disabled={isLoading}
            onClick={handleAgree}
          >
            {isLoading ? (
              <> <LoadingOutlined /> &nbsp;MENYIMPAN TANDA TANGAN </>
            ) : 'SAYA MENERIMA KETENTUAN YANG BERLAKU'}
            
          </Button>
        </Col>
      </Row>
    </Modal>
  )

}

export default ModalDashboard