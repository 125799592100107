import { ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import PropTypes from 'prop-types';
import React from 'react'

AppNotice.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
  onClickBtn: PropTypes.string,
  btnmessage: PropTypes.string,
};

AppNotice.defaultProps  = {
  heading: '',
  message: '',
  onClickBtn: () => {},
  btnmessage: 'Button'
};
export default function AppNotice({heading, message, onRetry, btnmessage, onClickBtn, ...props}) {
  return (
    <div style={{padding: '80px 40px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <div><ExclamationCircleOutlined style={{fontSize: '40px', marginBottom: '.5em'}} /></div>
      <Title level={3}>{heading}</Title>
      <Text style={{fontSize: '1.2em'}}>{message}</Text>
      <Button style={{marginTop: '1em',}} onClick={e => onClickBtn(e)} className="app-btn secondary long">{btnmessage}</Button>
    </div>
  )
}
