/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useScript = (props) => {
  const {src, clientKey} = props;
  useEffect(() => {
    let script = document.createElement('script');
    script.async = true;
    script['src'] = src;
    script.type = 'text/javascript'
    script.dataset.clientKey = clientKey;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [props]);
};

export default useScript;