/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Typography, Col, Card, Steps, Button, Modal, Row} from 'antd';
import { useHistory } from 'react-router';

const { Text, Title } = Typography
const { Step } = Steps;

// const lihatJadwal = [
//   {
//     "namaMatpel": "PKN",
//     "waktuPelaksanaan": "15:30:00",
//     "tglPelaksanaan": "2021-03-08"
//   },
//   {
//     "namaMatpel": "IPA",
//     "waktuPelaksanaan": "16:00:00",
//     "tglPelaksanaan": "2021-03-08"
//   },
//   {
//     "namaMatpel": "IPS",
//     "waktuPelaksanaan": "17:00:00",
//     "tglPelaksanaan": "2021-03-08"
//   }
// ];

function JadwalCard(props) {
  const history = useHistory();
  const {listJadwal} = props;
  const [isShowModalUjian, setIsShowModalUjian] = useState(false);
  const [lihatJadwal, setLihatJadwal] = useState(listJadwal ? listJadwal.find(e => !!e.lihatJadwal).lihatJadwal || [] : [])

  const toggleModalUjian = (jadwal) => {
    if(Array.isArray(jadwal)){
      setLihatJadwal(jadwal)
    }
    setIsShowModalUjian(!isShowModalUjian);
  }
  
  const goToUjian = () => {
    history.push('/pendaftaran/ujian-akademik');
  }

  let current = 0;
  listJadwal.forEach((e, i) => {
    if (!!e.status) current = i+1;
  })
  return (
    <div>
      <Steps direction="vertical" size="default" current={current}>
        {listJadwal.map((j, i) => (
          <Step title={<b>{j.jenisJadwal}</b>} description={
            <div>
              {!!j.lokasi && <div>
                <span style={{fontWeight: 'bold'}}>Lokasi: </span>
                <span>{j.lokasi}</span>
              </div>}
              {!!j.link && <div>
                <span style={{fontWeight: 'bold'}}>Link: </span>
                <span>{j.link}</span>
              </div>}
              {
                j.jenisJadwal === 'Hasil Ujian Akademik' ? 
                  <div>
                    <span style={{fontWeight: 'bold'}}>Hasil ujian akademik akan dikirimkan melalui e-mail</span>
                  </div>
                : (
                    j.jenisJadwal === 'Ujian Akademik' ? 
                      <div>
                        <Button type="primary" style={{marginTop: '1em'}} className="app-btn" onClick={() => toggleModalUjian(j.lihatJadwal)}>Lihat Jadwal</Button>
                      </div>
                    :
                    j.tglPelaksanaan &&
                      <div>
                        <span style={{fontWeight: 'bold'}}>Tanggal: </span>
                        <span>{j.tglPelaksanaan} - {j.waktuPelaksanaan}</span>
                      </div>
                  )
              }
            </div>
          } />
        ))}
      </Steps>
      <Modal className="app-modal" visible={isShowModalUjian} okButtonProps={{style: {display: 'none'}}} okText="Buka halaman ujian" cancelText="Batal" onOk={goToUjian} onCancel={toggleModalUjian} width={400}>
        <Title level={4} style={{textAlign: 'center'}}>Jadwal Ujian</Title>
        <hr style={{border: '1px solid #d9d9d9'}} />
        { Array.isArray(lihatJadwal) && lihatJadwal.map(jadawl => (
          <Row style={{display: 'flex', justifyContent: 'space-between'}}>
            <Col span={8}>{jadawl.namaMatpel}</Col>
            <Col span={8} style={{textAlign: 'center'}}>{jadawl.tglPelaksanaan}</Col>
            <Col span={8} style={{textAlign: 'right'}}>{jadawl.waktuPelaksanaan}</Col>
          </Row>
        ))}
        <div style={{marginTop: 10}}>* Jika ujian akademik tidak tampil pada aplikasi saat tanggal pelaksanaan ujian, harap melakukan login kembali</div>
        {/* <pre>{JSON.stringify(lihatJadwal, '', 2)}</pre> */}
      </Modal>
    </div>
  )
}

function JadwalWawancara (props) {
  const [statusTahapAwal, setStatusTahapAwal] = useState({statusAdministrasi: {}});
  const {statusAdministrasi: {statusId}, ajukanTahapAwal, listJadwal = {}} = statusTahapAwal;
  // const {lihatJadwal} = listJadwal[0];
  useEffect(() => {
  }, [])

  useEffect(() => {
    if (!!props.status) {
      console.log('Status: ', props.status)
      const newStatus = {...statusTahapAwal, ...props.status}
      if(!newStatus.statusAdministrasi) { newStatus.statusAdministrasi = {}}
      setStatusTahapAwal(newStatus);
    }
  }, [props.status]);
  

  return(
    <Col lg={12} span={24}>
      <Card style={{height: '100%'}} className="app-card">
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%'}}>
          <div>
            <div style={{borderBottom: '1px solid #d9d9d9', marginBottom: 20}}>
              <Title level={4}>{ statusId === 3 ? 'Tahap Selanjutnya' : 'Tahap Kedua'} </Title>
            </div>
            {statusId === 26 && ajukanTahapAwal ? (Object.values(listJadwal).length > 0 ?
              <JadwalCard listJadwal={listJadwal} /> :
              <Text className="text-desc">Tahap berikutnya akan segera dijadwalkan. Harap menunggu pemberitahuan selanjutnya.</Text>
            ) : (
              <div>
                {props.jalurPenerimaan === 'Prestasi' ? (
                  <Text className="text-desc">{
                    statusId === 3 ?
                    '* Silahkan melakukan login kembali untuk pindah halaman ke tahap selanjutnya':
                    '* Tahap kedua akan dijadwalkan apabila dinyatakan lolos seleksi tahap awal'
                  }</Text>
                ) : (
                  <Text className="text-desc">{
                    statusId === 3 ?
                    '* Silahkan melakukan login kembali untuk pindah halaman ke tahap selanjutnya':
                    '* Tahap kedua akan dijadwalkan apabila dinyatakan lolos seleksi tahap awal'
                  }</Text>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
    </Col>
  )

}

export default JadwalWawancara