import http from "./http-common";

const getAll = (url,config) => {
  return http.get(`${url}`,config);
};

const get = (url,id,config) => {
  return http.get(`${url}${id}`,config);
};

const create = (url,data,config) => {
  return http.post(`${url}`, data,config);
};

const update = (url,id, data,config) => {
  return http.put(`${url}/${id}`, data,config);
};

const put = (url, data,config) => {
  return http.put(`${url}`, data,config);
};

const remove = (url,id,config) => {
  return http.delete(`${url}/${id}`,config);
};

const removeAll = (url,config) => {
  return http.delete(`${url}`,config);
};

const findByTitle = (url,title,config) => {
  return http.get(`${url}?title=${title}`,config);
};

export default {
  getAll,
  get,
  create,
  update,
  put,
  remove,
  removeAll,
  findByTitle
};