export function countValidProp(obj, pattern) {
  const keys = Object.keys(obj);
  const regex = new RegExp(pattern)
  const newObj = keys.filter(key => {
    return (!!obj[key] || (typeof obj[key] === 'number' && obj[key] === 0)) && (pattern ? regex.test(obj[key]) : true)
  });
  return newObj.length;
}

export function compare(oldObj, newObj) {
  return JSON.stringify(oldObj) === JSON.stringify(newObj);
}