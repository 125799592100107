/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, Input, Alert } from 'antd'
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import _ from 'lodash'

import { countValidProp } from '../../../../functions/object';
import UppercaseInput from '../../../../component/uppercaseInput';

const borderedCellStyle = {borderBottom: "1px solid #e0e0e0", marginBottom: '1em'};
const matpelSikapRules = [
  { required: true, message: "Harap masukan nilai (ABCDEF)" },
  { pattern: new RegExp('^[ABCDEFabcdef+-]+$'), message: "Harap diisi dengan nilai (ABCDEF)" }
]

export function PenilaianPribadi(props) {
  const [form] = Form.useForm();
  const {initValue = {value: [], valid: false}, listPenilaian = [], onChange} = props;
  let value = form.getFieldsValue();
  let debouncedFn;

  useEffect(() => {
    if (!!listPenilaian) {
      let defaultValue = {
        value: listPenilaian.map(e => ({matpelId: { matpelId : e.matpelId}})),
        valid: false,
      }
      if(!!initValue && !!initValue.value && initValue.value.length > 0) {
        defaultValue = initValue;
      }
      form.setFieldsValue(defaultValue);
      value = defaultValue;
      onChange(defaultValue);
    }
  }, [listPenilaian, initValue])
  
  const handleChange = () => {
    if (!debouncedFn) {
      debouncedFn =  _.debounce(async  () => {
        const val = form.getFieldsValue();
        await form.validateFields().then(_ => val.valid = true ).catch(_ => val.valid = false);
        form.setFieldsValue({valid: val.valid})
        onChange(val)
      }, 1000);
    }
    debouncedFn();
  }

  return (
    <Form form={form} onValuesChange={handleChange}>
      <div className="ant-table app-table">
        <div className="ant-table-container">
          <div className="ant-table-content" style={{overflowY: 'auto', paddingBottom: '1em'}}>
            <Form.List name ="value">
              {(fields, {}) => (
                <table style={{fontWeight: "bold", fontSize: "1.2em"}}>
                  <thead style={{textAlign: "center"}}>
                    <tr>
                      <td style={{...borderedCellStyle, width: "30%", textAlign: "left"}} rowSpan={2}>Penilaian Pribadi</td>
                      <td style={{...borderedCellStyle, width: "60px", textAlign: "right"}} rowSpan={2}></td>
                      <td colSpan={2}>KELAS VII</td>
                      <td colSpan={2}>KELAS VIII</td>
                    </tr>
                    <tr>
                      <td style={borderedCellStyle}>Smt 1</td>
                      <td style={borderedCellStyle}>Smt 2</td>
                      <td style={borderedCellStyle}>Smt 1</td>
                      <td style={borderedCellStyle}>Smt 2</td>
                    </tr>
                  </thead>
                  <tbody>
                    {listPenilaian.length > 0 && fields.map((field, index) => {
                      const sikapValidProp = !!value.value ? countValidProp(value.value[index], '^[ABCDEFabcdef+-]+$') : 0;
                      return (
                        <tr key={`penilaian_pribadi_row_${index}`}>
                          <td>{listPenilaian[index]['matpelSikap']} </td>
                          <td style={{width: '20px'}}>
                            {sikapValidProp >= 4 ? (<CheckCircleFilled style={{color: "#27ae60", fontSize: "1.2em"}} title="Data valid"  />) : 
                            (<CloseCircleFilled style={{color: "#eb5757", fontSize: "1.2em"}} title="Data tidak valid"  />)}
                          </td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas7Sem1']} rules={matpelSikapRules}>
                            <UppercaseInput className="input-modal" maxLength={2} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas7Sem2']} rules={matpelSikapRules}>
                            <UppercaseInput className="input-modal" maxLength={2} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas8Sem1']} rules={matpelSikapRules}>
                            <UppercaseInput className="input-modal" maxLength={2} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, 'kelas8Sem2']} rules={matpelSikapRules}>
                            <UppercaseInput className="input-modal" maxLength={2} />
                          </Form.Item></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </Form.List>
          </div>
        </div>
      </div>
      {!value.valid && <div style={{padding: '0px 1.25em'}}><Alert message="Harap diisi dengan nilai (ABCDEF)" banner /></div>}
      {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
    </Form>
  )
}
