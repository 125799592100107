import { Select } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';


AppAutoComplete.propTypes = {
  api: PropTypes.string,
  limit: PropTypes.number,
  searchFn: PropTypes.func,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  minInpuLength: PropTypes.number,
};

AppAutoComplete.defaultProps  = {
  limit: 50,
  searchFn: getOptions,
  placeholder: 'Cari Item',
  onChange: () => {},
  minInpuLength: 3,
};




function getOptions(api, keyword, limit) {
  return new Promise((resolve, reject) => {
    Axios.post(`${api}?keyword=${keyword}&limit=${limit}`).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export default function AppAutoComplete(props) {
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const {api, limit, searchFn, onChange, placeholder, minInpuLength, notFoundMessage, value, valueKey} = props;
  let debouncedFn;


  

  const onSearch = async (info) => {
    // const keyword = info;
    if (!debouncedFn) {
      debouncedFn = _.debounce(async (keyword) => {
        if (keyword.length < minInpuLength) {return}
        setIsLoading(true)  
        await searchFn(api, keyword, limit).then(newOption => {
          setOptions(newOption.data.data)
        }).catch(err => {console.log(err)});
        setIsLoading(false);
      }, 1000);
    }
    await debouncedFn(info)
  }

  const handleOnChange = (info) => {
    onChange(info);
  }

  const notFoundProps = !options ? {notFoundContent: ''} : !!notFoundMessage ? {notFoundContent: notFoundMessage} : {};

  return (
    <>
      <Select 
        suffixIcon={isLoading ? <LoadingOutlined style={{color: '#43aafe'}} /> : ''}
        {...notFoundProps}
        placeholder={placeholder}
        showSearch
        filterOption={false}
        style={{width: '100% '}}
        defaultActiveFirstOption={false}
        onChange={handleOnChange}
        value={value}
        onSearch={onSearch}
      >
        {/* <Select.Option value="smp">SMP</Select.Option> */}
        {!!options && options.map((option, index) => <Select.Option key={`autocomplete-option-${index}`} value={option[valueKey || 'value']}>{option.label}</Select.Option>)}
      </Select>
    </>
  )
}
