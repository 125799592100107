import React from 'react'
import { Modal, Typography, Button, Space, Row, Col } from 'antd'

const { Title } = Typography

const GantiJalur = props => {

    return(
      <Modal
        destroyOnClose={true}
        visible={props.visible}
        className="form-modal"
        footer={null}
        closable={true}
        onCancel={props.buttonCancel()}
        centered
        width="800px"
      >
      <Title>Anda yakin ingin ganti jalur pendaftaran</Title>
      semua data yang Anda input sebelumnya akan hilang
      <Row>
          <Space>
              <Col>
                <Button danger
                    onClick={() => props.buttonYes()}
                >
                    Ya, ganti jalur
                </Button>
              </Col>
              <Col>
                <Button 
                    onClick={() => props.buttonCancel()}
                >
                    Batal
                </Button>
              </Col>
          </Space>
      </Row>
    </Modal>
    )
}

export default GantiJalur