import axios from 'axios';
import CONFIG from './config';
import AppCache from './cache';
import { dialog } from '../functions/alert'

export default class Auth {

  static login(user) {
    return new Promise((resolve, reject) => {
      axios.post(CONFIG.BASE_URL_LOGIN + '/login', user).then(({data, ...res}) => {
        // console.log(res,data.data)
        let dataRole = data && data.data.authorities && data.data.authorities[0] && data.data.authorities[0].authority || ''
        let isLogin = data && data.data && data.data.isLogin || true
        let isLoginMessage = data && data.data && data.data.isLoginMessage || ''
        if(dataRole === 'Siswa' || dataRole === 'SISWA' || dataRole === 'siswa'){
          if(!isLogin){
            reject({disableLogin: !isLogin, isLoginMessage: isLoginMessage})
          }else{
            sessionStorage.setItem('token', data.auth.Authorization)
            sessionStorage.setItem('user', JSON.stringify(data.data));
            sessionStorage.setItem('siswaId', JSON.stringify(data.data.userId));
            AppCache.clear();
            setTimeout(_ => {
              resolve(data);
              dialog({icon: 'success', text: `Selamat ${user.username} telah berhasil login!`})
            }, 1000);
          }
        }else{
          reject('isAdmin');
        }
      }).catch(err => {
        reject(err);
      });
    })
  }
  
  static logout() {
    sessionStorage.clear();
    // sessionStorage.removeItem('token')
    // sessionStorage.removeItem('user');
    // sessionStorage.removeItem('siswaId');
    // sessionStorage.removeItem('signature');
    AppCache.clear();
    localStorage.clear();
  }
  
  static isLogin() {
    return !!sessionStorage.getItem('token') && !!sessionStorage.getItem('user');
  }
}