/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Input, Col, Row, Form, Typography, Card, Radio, Button } from 'antd';
import { AppHint, RedStar } from '../../../component/common';
import PropTypes from 'prop-types';
import SelectComponent from '../../../component/select-component';
import { debounce } from 'lodash';
import Axios from 'axios';
import CONFIG from '../../../service/config';
import { dialog } from '../../../functions/alert';

const {Text, Title} = Typography;

DataDiri.propTypes = {
  onChange: PropTypes.func
};

function SmpNotFound () {
  return (
    <div style={{textAlign: "center", padding: "1em 2em 1.5em", color: "#777777"}}>
      {/* <InboxOutlined style={{fontSize: "4em", marginBottom: "10px", color: "#dddddd"}} /> */}
      <div>
        Asal SMP yang Anda cari belum terdaftar di sistem. <br />
        Hubungi nomor panitia berikut ini (0857-4124-7145) agar Asal SMP Anda dapat didaftarkan.
      </div>
    </div>
  )
}

async function loadMasterData() {
  const listDataKey = ["agama", "suku", "smp",];
  return new Promise((resolve, reject) => {
    Axios.all([
      Axios.get(CONFIG.BASE_URL + '/api/masterAgama'),
      Axios.get(CONFIG.BASE_URL + '/api/masterSuku'),
      Axios.get(CONFIG.BASE_URL + '/api/masterSMP')
    ]).then(data => {
      console.log('masterData: ', data);
      let newDataList = {}; 
      listDataKey.forEach((key, index) => {
        newDataList[key] = data[index].status === 200 ? data[index].data.data : [];
      })
      resolve(newDataList);
    }).catch(err => reject(err))
  })
}


export default function DataDiri(props) {
  const [form] = Form.useForm();
  const {onChange, initValue, formId, formTitle, isValidating} = props;
  const [dataList, setDataList] = useState({
    smp: [
      {key: 'smp1', value: 'Smp Nomor 1'},
      {key: 'smp2', value: 'Smp Nomor 2'},
      {key: 'smp3', value: 'Smp Tiga'},
      {key: 'smp4', value: 'SMP #4'},
    ],
    peminatan: [
      {key: 'IPA', value: 'IPA'},
      {key: 'IPS', value: 'IPS'},
    ]
  });
  let debouncedFn;

  useEffect(() => {
    loadMasterData().then(res => {
      setDataList({ ...dataList, ...res });
    }).catch(err => {
      dialog({title: 'Failed getting data list!', text:  err.response ? err.response.data.message : err.message, icon: 'error'});
    })
  }, [])

  useEffect(() => {
    if(!!initValue) {
      form.setFieldsValue(initValue.value);
      onChange(formId, initValue)
    }
  }, [initValue]);

  useEffect(() => {
    if (!!isValidating) {
      form.validateFields().then(_ => onChange(formId, {valid: true}))
      .catch(_ => onChange(formId, {valid: false}))
    }
  }, [isValidating])


  const handleChange = (ev) => {
    if (!debouncedFn) {
      debouncedFn = debounce(async () => {
        form.validateFields().then(_ => onChange(formId, {valid: true, value: form.getFieldsValue()}))
        .catch(_ => onChange(formId, {valid: false, value: form.getFieldsValue()}))
      }, 300)
    }
    debouncedFn()
  }  

  const addItemToList = (itemsKey, item) => {
    const mewDataList = { ...dataList };
    mewDataList[itemsKey] = [
      ...(mewDataList[itemsKey] || []),
      item
    ];
    
    setDataList(mewDataList)
  }

  const {curentIndex, maxIndex} = props;

  return (
    <>
      <Card>
        <Form form={form} onValuesChange={handleChange}>
        <Row gutter={20}>
          <Col span={24} style={{borderBottom: '1px solid rgb(217, 217, 217)', marginBottom: '1em'}}><Title level={4}>{formTitle}</Title></Col>
          <Col span={24}>
            <Text>Nomor PPDB <RedStar /></Text>
            <Form.Item
              name="noPpdb"
              rules={[
                { required: true, message: 'Nomor PPDB harus diisi' },
                { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }  
              ]}
            >
              <Input className="input-modal" placeholder={"Nomor PPDB"} disabled={true} maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Nama Lengkap Siswa <RedStar /></Text>
            <Form.Item
              name="namaLengkap"
              rules={[{ required: true, message: 'Nama Lengkap Siswa harus diisi' }]}
            >
              <Input className="input-modal" placeholder={"Nama Lengkap Siswa"} maxLength={50}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>E-mail <RedStar /> <AppHint text="Tuliskan alamat e-mail yang valid! E-mail akan digunakan seterusnya untuk kegiatan belajar." /></Text>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'E-mail harus diisi' },
                { type: 'email', message: 'Harap masukkan format e-mail' }
              ]}
            >
              <Input className="input-modal" placeholder={"E-mail"} maxLength={50} />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>Cari Asal SMP <AppHint text="Tidak dapat menemukan Asal SMP Anda? Anda dapat menghubungi nomor panitia berikut ini (0857-4124-7145) agar Asal SMP Anda dapat didaftarkan." /> <RedStar /></Text>
            <Form.Item
              name="smp"
              rules={[{ required: true, message: 'Asal SMP harus diisi' }]}
            >
              <SelectComponent notFoundMessage={<SmpNotFound />} items={dataList['smp']} labelKey="namaSMP" valueKey="namaSMP" placeholder="Pilih Asal SMP" />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>NISN  <RedStar /></Text>
            <Form.Item
              name="nisn" 
              rules={[
                { required: true, message: 'NISN harus diisi' },
                { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" },
                { min: 10, message: "Harap diisi dengan 10 angka NISN" }
              ]}
            >
              <Input className="input-modal" maxLength={10} placeholder={"10 Digit nomor NISN"}  />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>Peminatan  <RedStar /></Text>
            <Form.Item
              name="minatJurusan"
              rules={[{ required: true, message: 'Peminatan harus diisi' }]}
            >
              <SelectComponent items={dataList['peminatan']} placeholder="Pilih Peminatan" />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>Agama  <RedStar /></Text>
            <Form.Item
              name="agama"
              rules={[{ required: true, message: 'Agama harus diisi' }]}
            >
              <SelectComponent items={dataList['agama']} labelKey="namaAgama" valueKey="namaAgama" placeholder="Pilih Agama" />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>Suku  <RedStar /></Text>
            <Form.Item
              name="suku"
              rules={[{ required: true, message: 'Suku harus diisi' }]}
            >
              <SelectComponent onAddItem={item => addItemToList('suku', item)} labelKey="namaSuku" valueKey="namaSuku"  placeholder="Pilih Suku" items={dataList['suku']} />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>Jenis Kelamin <RedStar /></Text>
            <Form.Item
              name="jenisKelamin"
              rules={[{ required: true, message: 'Jenis Kelamin harus dipilih' }]}
            >
              <Radio.Group>
                <Radio value={1}>Laki-Laki</Radio>
                <Radio value={2}>Perempuan</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>Nomor Handphone Siswa  <RedStar /></Text>
            <Form.Item
              name="noHp"
              rules={[
                { required: true, message: 'Nomor Handphone Siswa harus diisi' },
                { pattern: new RegExp('^[0-9]+$'), message: "Harap diisi dengan angka" }
              ]}
            >
              <Input className="input-modal" maxLength={15} placeholder={"Nomor Handphone Siswa"}  />
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <Text>ID Line</Text>
            <Form.Item name="idLine" >
              <Input className="input-modal" placeholder={"ID Line"} maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Alamat Tempat Tinggal Siswa <RedStar /></Text>
            <Form.Item
              name="alamatTempatTinggal"
              rules={[{ required: true, message: 'Alamat Rumah harus diisi' }]}
            >
              <Input className="input-modal" placeholder={"Alamat Rumah"} maxLength={255} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Text>Alamat Kos (kosongkan bila tidak kos) </Text>
            <Form.Item
              style={{marginBottom: '0px'}}
              name="alamatKos"
            >
              <Input className="input-modal" placeholder={"Alamat Kos (kosongkan bila tidak kos)"} maxLength={255} />
            </Form.Item>
          </Col>
        </Row>
    
        </Form>
        {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
      </Card>
      <div className="actions" style={{margin: '0em 0px 0px', display: 'flex',justifyContent: 'right'}}>
        {curentIndex > 0 && (
          <Button className="app-btn primary" onClick={_ => props.handlePrev()} style={{ padding: '.75em 1.5em', marginLeft: '1em'}}>
            Sebelumnya
          </Button>
        )}
        {curentIndex < maxIndex && (
          <Button className="app-btn primary" onClick={_ => props.handleNext()} style={{ padding: '.75em 1.5em', marginLeft: '1em'}}>
            Selanjutnya
          </Button>
        )}
      </div>
    </>
  )
}
