export default {
  "status": 'Lulus',
  "benar": 8,
	"salah": 2,
	"namaSiswa": 'Ato',
  "reviewSoal":[
	{
	  "skor": 70,
	  "matpel": 'B Indonesia'
	},
	{
	  "skor": 80,
	  "matpel": 'Matematika'
	}
  ]
}