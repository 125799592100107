import React, {useState, useEffect} from 'react'
import { Row, Col, Card, Button, Radio } from 'antd';
import Rapor from './rapor'
import OfoN from './ofon'
import SeniOlahraga from './seni-olahraga'
import Simulasi from './simulasi'
import { CheckCircleFilled, UploadOutlined, DeleteFilled, InfoCircleOutlined } from '@ant-design/icons'

function Offer(props) {

  const [ketentuan, setKetentuan] = useState('')

  const [dataPlus, setDataPlus] = useState({
    gold: props.data.gold || undefined,
    platinum: props.data.platinum || undefined,
    silver: props.data.silver || undefined
  })

  useEffect(() => {
    setDataPlus({
      gold: props.data.gold || undefined,
      platinum: props.data.platinum || undefined,
      silver: props.data.silver || undefined
    })
  }, [props.data])

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginTop: '10px'
  };

  const handleDetail = (body, title) => {
    let head = title
    if (title === 'Prestasi OfoN') {
      head = 'Beasiswa OfoN'
    }else if (title === 'Prestasi Seni') {
      head = 'Beasiswa Prestasi Seni'
    }else if (title === 'Prestasi Olahraga') {
      head = 'Beasiswa Prestasi Olahraga'
    }else if (title === 'Prestasi Rapor') {
      head = 'Beasiswa Prestasi Rapor'
    }
    props.handleShow(head, body)
  }

  const handlePackage = (name, id) => {
    props.handlePackage(name, id)
  }

  const handleNext = () => {
    let name = ''
    setKetentuan('Ketentuan')
    if (props.beasiswa.umum ===  4 ) {
      name = 'ofon'
    }else if (props.beasiswa.umum === 1) {
      name = 'rapor'
    }else if (props.beasiswa.umum === 2 ) {
      name = 'seni'
    }else if (props.beasiswa.umum === 3) {
      name = 'olahraga'
    }
    props.handleNext(name, props.beasiswa.umum)
  }

  return (
    <Row gutter={[40, 24]}>
      <Col span={24}>
        <Card className="app-card-info-user" style={{height: '100%', textAlign: 'center'}}>
          {
            props.responsive && props.responsive.grid.xs ? 
              <div>
                <span className='head-modal'>
                  {props.package !== '' ? props.package + ' PACKAGE' + '\n' : '\n'}

                  {props.from === 'simulasi' ? (
                      'SIMULASI NILAI KOMITMEN UPS & US : '
                    ) : (
                      'KOMITMEN UPS : '
                    )
                  } 
                  {props.data.komitmenUps.toUpperCase()}
                </span>
              </div>
            :
              <div>
                <span className='head-modal' style={{ position: 'absolute', left: '26px'}}>
                  {props.package !== '' ? props.package + ' PACKAGE' + '\n' : '\n'}
                </span>
                <span className='head-modal'>
                  {props.from === 'simulasi' ? (
                      'SIMULASI NILAI KOMITMEN UPS & US : '
                    ) : (
                      'KOMITMEN UPS : '
                    )
                  } 
                  {props.data.komitmenUps.toUpperCase()}
                </span>
              </div>
          }
        </Card>
      </Col>
      {props.from === 'plus' ? (
        <Col span={24}>
          <Card className="app-card-info-user" style={{height: '100%'}}> 
            <Row justify="space-around">
              <Col xs={24} sm={7} md={5} style={{ marginTop: '20px'}}>
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Button 
                    block
                    size={"large"}
                    className='btn-finance btn-plus'
                    style={{ marginBottom: '10px'}}
                    onClick={ () => handlePackage('SILVER', dataPlus.silver && dataPlus.silver.komitmenId)}
                  >
                    SILVER
                  </Button>
                  <span 
                    className='text-detail' 
                    onClick={() => handleDetail(dataPlus.silver && dataPlus.silver.komitmenInfo, 'SILVER')} 
                  >
                    Lihat Detail
                  </span>
                </Col>
              </Col>
              <Col xs={24} sm={7} md={5} style={{ marginTop: '20px'}}>
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Button 
                    block
                    size={"large"}
                    className='btn-finance btn-plus'
                    style={{ marginBottom: '10px'}}
                    onClick={ () => handlePackage('GOLD', dataPlus.gold && dataPlus.gold.komitmenId)}
                  >
                    GOLD
                  </Button>
                  <span 
                    className='text-detail' 
                    onClick={() => handleDetail(dataPlus.gold && dataPlus.gold.komitmenInfo, 'GOLD')} 
                  >
                    Lihat Detail
                  </span>
                </Col>
              </Col>
              <Col xs={24} sm={7} md={5} style={{ marginTop: '20px'}}>
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Button 
                    block
                    size={"large"}
                    className='btn-finance btn-plus'
                    style={{ marginBottom: '10px'}}
                    onClick={ () => handlePackage('PLATINUM', dataPlus.platinum && dataPlus.platinum.komitmenId)}
                  >
                    PLATINUM
                  </Button>
                  <span 
                    className='text-detail' 
                    onClick={() => handleDetail(dataPlus.platinum && dataPlus.platinum.komitmenInfo, 'PLATINUM')} 
                  >
                    Lihat Detail
                  </span>
                </Col>
              </Col>
            </Row>
          </Card>
        </Col>
      ) :
      props.from === 'simulasi' ? (
        <Simulasi 
          dataSimulasi={props.dataSimulasi} 
          responsive = {props.responsive}
        />
      ) : (
        <>
          <Col xs={24} md={12}>
            <Card className="app-card-info-user" style={{height: '100%'}}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <span className='text-header-finance'>
                    {ketentuan} Beasiswa Utama
                  </span>
                </Col>
                <Col span={24}>
                  <div className='divider-finance' />
                </Col>
                {props.from === 'offer' ? (
                  <>
                    <Col span={24}>
                      <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                        Apakah Anda ingin mengajukan Beasiswa ? <br></br>
                        Silahkan pilih 1 dari {props.data.beasiswaUtama && props.data.beasiswaUtama.length ? props.data.beasiswaUtama.length : 0} Beasiswa Utama :
                      </span>
                    </Col>
                    <Col span={24}>
                      <Radio.Group style={{ width: '100%'}} value={props.beasiswa.umum} onChange={(e) => props.handleRadio(e.target.value, 'umum')}>
                        {props.data.beasiswaUtama ? props.data.beasiswaUtama.map(res => (
                          <Radio style={radioStyle} key={res.beasiswaId} value={res.beasiswaId}>
                            <span className='text-header-finance' style={{ color: '#072A6E', fontWeight: 'normal'}}>
                              {res.beasiswa}
                            </span>
                            { props.responsive && props.responsive.grid.xs ? 
                              <span className='text-detail-lov' onClick={() => handleDetail(res.infoBeasiswa, res.beasiswa)} >
                                <InfoCircleOutlined/> 
                              </span> :
                              <span className='text-detail-lov' onClick={() => handleDetail(res.infoBeasiswa, res.beasiswa)} >
                                Lihat Detail
                              </span>
                            
                          }
                          </Radio>
                        )) : <></>}
                        <Radio style={radioStyle} value={0}>
                          <span className='text-header-finance' style={{ color: '#072A6E', fontWeight: 'normal'}} >
                            Tidak
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Col>
                  </>
                ) : props.from === 'rapor' ? (
                  <Rapor 
                    nilai={props.nilaiSiswa}
                    diskon={props.diskon.rapor}
                  />
                ) : props.from === 'ofon' ? (
                  <OfoN 
                    fileNameYatim={props.fileNameYatim}
                    fileNameMedis={props.fileNameMedis}
                    fileNameTinggal={props.fileNameTinggal}
                    fileNameSktm={props.fileNameSktm}
                    filePathYatim={props.filePathYatim}
                    filePathMedis={props.filePathMedis}
                    filePathTinggal={props.filePathTinggal}
                    filePathSktm={props.filePathSktm}
                    fileInputYatim={props.fileInputYatim}
                    fileInputRekamMedis={props.fileInputRekamMedis}
                    fileInputTempatTinggal={props.fileInputTempatTinggal}
                    fileInputSktm={props.fileInputSktm}
                    fileHandler={props.fileHandler}
                    deleteUpload={(name) => props.deleteUpload(name)}
                    openFileBrowser={(name) => props.openFileBrowser(name)}
                    loadingUpload={props.loadingUpload}
                  />
                ) : props.from === 'seni' || props.from === 'olahraga' ? (
                  <SeniOlahraga 
                    from={props.from}
                    dataLOV={props.dataLOV} 
                    dataKejuaraan={props.dataKejuaraan}
                    fileNameSeni={props.fileNameSeni}
                    filePathSeni={props.filePathSeni}
                    fileHandler={props.fileHandler}
                    fileInputSeni={props.fileInputSeni}
                    openFileBrowser={(name) => props.openFileBrowser(name)}
                    loadingUpload={props.loadingUpload}
                    handleKejuaraan={props.handleKejuaraan}
                    deleteUpload={(name) => props.deleteUpload(name)}
                    handleDetail={(body, title) => handleDetail(body, title)}
                  />
                ) : (
                  <Col span={24}>
                    <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                      Tidak Memilih Beasiswa Utama
                    </span>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card className="app-card-info-user" style={{height: '100%'}}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <span className='text-header-finance'>
                    {ketentuan} Beasiswa Tambahan
                  </span>
                </Col>
                <Col span={24}>
                  <div className='divider-finance' />
                </Col>
                {console.log('data beasiswa: ', props.beasiswa)}
                {props.from === 'offer' ? (
                  props.beasiswa.umum === 4 ? (
                    <Col span={24}>
                      <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                        Beasiswa tidak dapat diajukan dengan beasiswa Ofon
                      </span>
                    </Col>
                  ) 
                  : 
                  (
                    <>
                      <Col span={24}>
                        <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                          Apakah Anda ingin mengajukan Beasiswa Tambahan ?
                        </span>
                      </Col>
                      {props.data.beasiswaTambahan ? props.data.beasiswaTambahan.map((res, index) => (
                        <>
                          <Col span={24} key={index+'text_tambahan'}>
                            <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                              {res.beasiswa}
                            </span>
                          </Col>
                          <Col span={24} key={index+'tambahan'}>
                            <Radio.Group style={{ width: '100%'}} value={props.beasiswa.tambahan} onChange={(e) => props.handleRadio(e.target.value, 'tambahan')}>
                              <Radio style={radioStyle} value={1}>
                                <span className='text-header-finance' style={{ color: '#072A6E', fontWeight: 'normal'}} >
                                  Ya
                                </span>
                              </Radio>
                              <Radio style={radioStyle} value={0}>
                                <span className='text-header-finance' style={{ color: '#072A6E', fontWeight: 'normal'}} >
                                  Tidak
                                </span>
                              </Radio>
                            </Radio.Group>
                          </Col>
                        </>
                      ))
                      : 
                    <></>}
                    </>
                  )
                ) : props.beasiswa.tambahan === 1 ? (
                  <>
                    <Col span={24}>
                      <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                        Upload berkas Beasiswa Tambahan : <br></br>
                        Beasiswa Keluarga Inti Alumni (KEKL) / KKL <span className='text-Kekl' style={{color: '#EB5757', fontWeight: 'bold', fontSize:'15px'}} >*</span>
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text-Kekl'>
                        • Scan ijazah SMA Kolese Loyola <br />
                        (bagi yang memiliki Keluarga Inti Alumni (KEKL))
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text-Kekl'>
                        • Scan Rapor Semester Terakhir <br/>
                        (bagi yang memiliki Keluarga Inti KKL/sedang bersekolah di SMA Kolese Loyola)
                      </span>
                    </Col>
                    <Col xs={24} lg={7}>
                      <input
                        type="file"
                        hidden
                        onChange={(e) => props.fileHandler(e, 'kekl')}
                        ref={props.fileInput}
                      />
                      <Button
                        className='btn-upload'
                        size="large"
                        block
                        onClick={() => props.openFileBrowser('kekl')}
                        loading={props.loadingUpload.kekl}
                      >
                        <UploadOutlined />
                        Upload
                        {props.filePathKekl && <CheckCircleFilled style={{ color: '#27ae60'}} />}
                      </Button>
                    </Col>
                    <Col span={17}> 
                      <span className='text-kekl' style={{ color: '#000000', fontSize: '11px'}}>
                        maximal size (1mb) , tipe dokumen (pdf, jpg) 
                      </span>
                    </Col>
                    <Col span={24} style={{marginBottom: '30px'}}>
                      <span>
                          { props.fileNameKekl ? (
                            props.fileNameKekl 
                            ) : ('')
                          }
                      </span>
                      { props.filePathKekl && 
                        <DeleteFilled
                          style={{ marginLeft: '1rem' }}
                          onClick={() => props.deleteUpload('kekl')}
                        />
                      }
                    </Col>
                    <Col span={24}>
                      <span className='text-diskon'>
                        * Jika mengajukan Beasiswa Tambahan
                      </span>
                    </Col>
                    <Col span={24}>
                      <span className='text-diskon'>
                        * Potensi Diskon : {props.diskon.kekl}
                      </span>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <span className='text-header-finance' style={{ fontWeight: 'normal'}}>
                      Tidak Memilih Beasiswa Tambahan
                    </span>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </>
      )}
      <Col span={24}>
        <Row>
          <Col xs={24} sm={12} style={props.responsive && props.responsive.grid.xs ? {textAlign: 'center', marginBottom: 20} : {}}>
            <Button
              type='text'
              className='app-btn text xl'
              onClick={() => props.hanldeBack()}
            >
              Kembali
            </Button>
          </Col>
          <Col xs={24} sm={12} style={props.responsive && props.responsive.grid.xs ? {textAlign: 'center'} : {}}>
            {props.from !== 'plus' &&
              <Button
                className='app-btn primary xl'
                style={props.responsive && props.responsive.grid.xs ? {} : { float: 'right'}}
                disabled={props.disableButton}
                onClick={() => handleNext()}
              >
                {props.from === 'offer' ? (
                  'Selanjutnya'
                ) :
                props.from === 'simulasi' ? (
                  'Ajukan Beasiswa'
                ) : (
                  'Simulasi UPS & US'
                )}
              </Button>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Offer