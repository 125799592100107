import axios from "axios";
import CONFIG_INITIAL_STATE  from '../service/config';
import Auth from "./auth";

const client = axios.create(CONFIG_INITIAL_STATE.BASE_URL);
// eslint-disable-next-line no-unused-vars
const clientForLogin = axios.create(CONFIG_INITIAL_STATE.BASE_URL_LOGIN);

// Request interceptor
client.interceptors.request.use(
    function (configuration) {
        const token = sessionStorage.getItem('token');
        if (token !== null) {
            configuration.headers.Authorization = `Bearer ${token}`;
        }
        return configuration;
    },
    function (err) {
        throw new Error(err);
    }
);

client.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    let messageErrorResponse = error.response && error.response.data && error.response.data.error || ''
    let messageErrorStatus = error.response && error.response.status || ''
    if(messageErrorResponse == 'Unauthorized' || messageErrorStatus == 401){
        Auth.logout()
        window.location = '/';
        // window.location.reload(false)
    }
    return Promise.reject(error);
  });

const LoyolaServices = {

    getDataConfig(){
        return client.request(
            {
                method: "get",
                url: `${CONFIG_INITIAL_STATE.BASE_URL_NOAUTH}/api/settingConfig/getConfig`
            },
            { crossdomain: true }
        )
    },
}

export {LoyolaServices, client}