import React from 'react';
import 'antd/dist/antd.css';
import './app/css/global.css';
import './app/css/container.scss';
import './app/service/token-interceptor';
import { Layout } from "antd";
import Routes from "./Routes";
import useScript from './app/functions/use-script';
import CONFIG from './app/service/config'
// import midtransScript from './app/functions/midtrans/snap.sandbox';


const App = () => {
  
  // const midtransScript = 'https://app.sandbox.midtrans.com/snap/snap.js';
  const midtransScript = 'https://app.midtrans.com/snap/snap.js';

  useScript({'src': midtransScript, 'clientKey': CONFIG.MIDTRANS_CLIENT_KEY});
  return (
    <Layout className="app-layout">
      <Routes />
    </Layout>
  )
}


export default App;
