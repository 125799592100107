/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Input, Col, Row, Form, Typography, Card } from 'antd';
import PropTypes from 'prop-types';
import UploadComponent from '../../../component/upload-component';
import { RedStar } from '../../../component/common';
import { debounce } from 'lodash';
import CONFIG from '../../../service/config';

const {Text, Title} = Typography;

DataPribadi.propTypes = {
  onChange: PropTypes.func
};


export default function DataPribadi(props) {
  const [form] = Form.useForm();
  const {onChange, initValue, formId, formTitle, isValidating} = props;
  const [isAlasanRequired, setIsAlasanRequired] = useState(true);
  const {noRegistrasi} = props.userData;
  let debouncedFn;

  useEffect(() => {
    if(!!initValue && !!initValue.value) {
      form.setFieldsValue(initValue.value);
      onChange(formId, initValue)
      const validVal = Object.keys(initValue.value).filter(key => !!initValue.value[key] && key !== "keteranganBerkas");
      console.log({validVal, initValue})
      setIsAlasanRequired(validVal.length < 5);
    }
  }, [initValue]);

  useEffect(() => {
    if (!!isValidating) {
      form.validateFields().then(_ => onChange(formId, {valid: true}))
      .catch(_ => onChange(formId, {valid: false}))
    }
  }, [isValidating])


  const handleChange = (ev) => {
    if (!debouncedFn) {
      debouncedFn = debounce(async () => {
        const newValue = form.getFieldsValue();
        const validVal = Object.keys(newValue).filter(key => !!newValue[key] && key !== "keteranganBerkas");
        console.log({validVal, newValue})
        setIsAlasanRequired(validVal.length < 5);
        form.validateFields().then(_ => onChange(formId, {valid: true, value: newValue}))
        .catch(_ => onChange(formId, {valid: false, value: newValue}))
      }, 300)
    }
    debouncedFn()
  }  

  return (
    <Card>
      <Form form={form} onValuesChange={handleChange}>
      <Row gutter={20}>
        <Col span={24} style={{borderBottom: '1px solid rgb(217, 217, 217)', marginBottom: '1em'}}><Title level={4}>{formTitle}</Title></Col>
        <Col span={24} style={{marginBottom: '1em'}}>
          <Card className="app-card paper">
            Silahkan upload dokumen dalam bentuk PDF!
            <br />
            Anda bisa menggunakan aplikasi CAMSCANNER (tersedia di Playstore) 
            untuk melakukan scan dokumen menggunakan handphone.
          </Card>
        </Col>
        <Col lg={12} span={24}>
          <Text>Scan Surat Keterangan Lulus (SKL) </Text>
          <Form.Item
            name="scanSkl"
            // rules={[{ required: true, message: 'Surat Keterangan Lulus (SKL) harus diisi' }]}
          >
            <UploadComponent fluidBtn
              url={CONFIG.BASE_URL +`/api/daftarUlang/upload/scanSkl?noReg=${noRegistrasi}`} 
              accept=".pdf"
              maxSize={1}
              showDesc
              buttonLabel="Semester 2" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Scan Ijazah (Bila sudah ada)</Text>
          <Form.Item name="scanIjazah" >
            <UploadComponent fluidBtn
              url={CONFIG.BASE_URL +`/api/daftarUlang/upload/scanIjazah?noReg=${noRegistrasi}`} 
              accept=".pdf"
              maxSize={1}
              showDesc
              buttonLabel="Scan Ijazah" />
          </Form.Item>
        </Col>
        
        <Col lg={12} span={24}>
          <Text>Scan Rapor Semester 5 </Text>
          <Form.Item
            name="scanRaporSemester5"
            // rules={[{ required: true, message: 'Raport Semester 5 harus diisi' }]}
          >
            <UploadComponent fluidBtn
              url={CONFIG.BASE_URL +`/api/daftarUlang/upload/raporKelas9Sem1?noReg=${noRegistrasi}`} 
              accept=".pdf"
              maxSize={5}
              showDesc
              buttonLabel="Rapor Semester 5" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Scan Rapor Semester 6 </Text>
          <Form.Item
            name="scanRaporSemester6"
            // rules={[{ required: true, message: 'Raport Semester 6 harus diisi' }]}
          >
            <UploadComponent fluidBtn
              url={CONFIG.BASE_URL + `/api/daftarUlang/upload/raporKelas9Sem2?noReg=${noRegistrasi}`} 
              accept=".pdf"
              maxSize={5}
              showDesc
              buttonLabel="Rapor Semester 6" />
          </Form.Item>
        </Col>
        <Col lg={12} span={24}>
          <Text>Pas Foto Berseragam SMP (Berwarna) </Text>
          <Form.Item name="pasFotoSmp">
            <UploadComponent fluidBtn
              url={CONFIG.BASE_URL + `/api/daftarUlang/upload/passFotoSmp?noReg=${noRegistrasi}`} 
              accept=".pdf"
              maxSize={1}
              showDesc
              buttonLabel="Rapor Semester 6" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>Bila ada dokumen yang belum lengkap, silahkan jelaskan alasannya disini! {isAlasanRequired && <RedStar />} </Text>
          <Form.Item
            name="keteranganBerkas"
            style={{marginBottom: '0px'}}
            rules={[{required: isAlasanRequired, message: 'Alasan ketidaklengkapan berkas harus diisi'}]}
          >
            <Input.TextArea className="input-modal" placeholder="Alasan ketidaklengkapan dokumen harus diisi" disabled={!isAlasanRequired} />
          </Form.Item>
        </Col>
      </Row>
      </Form>
    </Card>
  )
}
