import {client} from './index.js';
import CONFIG  from '../service/config';

const DaftarUlangService = {

  getStatusDaftarUlang(siswaId) {
    return client.request(
      {
        method: "get",
        url: `${CONFIG.BASE_URL}/api/statusUlangSiswa?siswaId=${siswaId}`
      },
      { crossdomain: true }
    );
  },
  
  saveDaftarUlang(siswaId, body) {
    return client.request(
      {
        method: "put",
        url: `${CONFIG.BASE_URL}/api/daftarUlangSiswa/${siswaId}`,
        data: body
      },
      { crossdomain: true }
    );
  },
  
  getNoPpdb(siswaId) {
    return client.request(
      {
        method: "get",
        url: `${CONFIG.BASE_URL}/api/daftarUlang/getNoPpdb?siswaId=${siswaId}`
      },
      { crossdomain: true }
    );
  },
}

export default DaftarUlangService;