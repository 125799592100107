/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Form, Button, Select, Alert } from 'antd'
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import _ from 'lodash'
import UppercaseInput from '../../../../component/uppercaseInput';

const borderedCellStyle = {borderBottom: "1px solid #e0e0e0", marginBottom: '1em'};
const ekskulFieldConfig = {maxLength: 3}

const ekskulRules = [
  { pattern: new RegExp('^[ABCDEFabcdef+-]+$'), message: "Harap diisi dengan nilai (ABCDEF)" },
  { required: true, message: "Nilai Harus diisi" }
];

  
export function Ekstrakurikuler(props) {
  const [form] = Form.useForm(``);
  let value = form.getFieldValue();
  const {listEkskul = [], initValue} = props;

  useEffect(() => {
    const defaultValue = initValue ? initValue : {value: [],};
    form.setFieldsValue(defaultValue);
    props.onChange(defaultValue);
  }, [listEkskul, initValue])
  
  let debouncedFn;
  const handleChange = (event) => {
    if (!debouncedFn) {
      debouncedFn =  _.debounce(async  () => {
        const val = form.getFieldsValue();
        await form.validateFields().then(_ => val.valid = true).catch(_ => val.valid = false);
        if ((val.value || []).length <= 0) {
          delete val.valid;
        }
        value = val;
        props.onChange(val)
      }, 1000);
    }
    debouncedFn();
  }

  return (
    <Form form={form} onValuesChange={handleChange}>
      <div className="ant-table app-table">
        <div className="ant-table-container">
          <div className="ant-table-content" style={{overflowY: 'auto', paddingBottom: '1em'}}>
            <Form.List name="value">
              {(fields, {add, remove}) => (
                <>
                <table style={{fontWeight: "bold", fontSize: "1.2em"}}>
                  <thead style={{textAlign: "center"}}>
                    <tr>
                      <td style={{...borderedCellStyle, width: "50%", textAlign: "left"}} rowSpan={2}>Ekstrakurikuler</td>
                      <td colSpan={2}>KELAS VII</td>
                      <td colSpan={2}>KELAS VIII</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={borderedCellStyle}>Smt 1</td>
                      <td style={borderedCellStyle}>Smt 2</td>
                      <td style={borderedCellStyle}>Smt 1</td>
                      <td style={borderedCellStyle}>Smt 2</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {(fields.map((field, index) => {
                      return (
                        <tr key={`ekskul_row_${index}`}>
                          <td><Form.Item className="no-error" name={[field.name, 'ekskulId','ekskulId']} rules={[{required: true, message: 'Field harus diisi'}]}>
                            {/* <Input placeholder="Nama Ekstra Kurikuler" /> */}
                            <Select style={{width: "100%"}}>
                              {listEkskul.map((ekskul, idxEkskul) => <Select.Option key={'option-namaEkskul'+index+idxEkskul} value={ekskul.ekskulId}>{ekskul.namaEkskul}</Select.Option>)}
                            </Select>
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, "kelas7Sem1"]} rules={ekskulRules}>
                            <UppercaseInput className="input-modal" {...ekskulFieldConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, "kelas7Sem2"]} rules={ekskulRules}>
                            <UppercaseInput className="input-modal" {...ekskulFieldConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, "kelas8Sem1"]} rules={ekskulRules}>
                            <UppercaseInput className="input-modal" {...ekskulFieldConfig} />
                          </Form.Item></td>
                          <td><Form.Item className="no-error" name={[field.name, "kelas8Sem2"]} rules={ekskulRules}>
                            <UppercaseInput className="input-modal" {...ekskulFieldConfig} />
                          </Form.Item></td>
                          <td title="Hapus Ekstrakurikuler"><CloseOutlined style={{fontSize: '1em', color: '#EB5757'}} onClick={_ => remove(index)} /></td>
                        </tr>
                      )
                    }))}
                  </tbody>
                </table>
                <Button 
                  onClick={() => add()}
                  style={{ margin: "1em 1.25em 0em" }}
                >
                  <PlusOutlined /> Tambah Ekstrakurikuler
                </Button>
                
                {(!value.valid && fields.length > 0) && <div style={{padding: '0px 1.25em', marginTop: '1em'}}><Alert message="Harap diisi dengan nilai (ABCDEF)" banner /></div>}
                </>
              )}
            </Form.List>
          </div>
        </div>
      </div>
      <div>
        {/* <pre>{JSON.stringify(value, '', 2)}</pre> */}
      </div>
    </Form>
  )
}
