import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Button, Col, Typography,Card, Modal } from 'antd';
import siswaServices from '../../../service/siswaServices';
import { AppLoading } from '../../../component/loading';
import moment from 'moment'
import { dialog } from '../../../functions/alert';
import Dummy from '../../../dummy';

const { Title, Text } = Typography;

const HasilUjian = props => {
    const [data, setData] = useState(null)
    const [isShow, setShow] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    
    const getData = () => {
      if(sessionStorage.getItem('token')){
        var siswaId = sessionStorage.getItem('siswaId')
        siswaServices.getAll('api/ujianAkademik/getHasil?siswaId='+siswaId, config).then(res => {
          const tempData = res.data
          if(tempData){
            setData(tempData)
            setLoading(false)
          }
        }).catch(err => {
          if(err){
            dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
          }
        })
      }
    }

    const getDataDummy = () => {
      const tempData = Dummy.getHasil
      if(tempData){
        setData(tempData)
        setLoading(false)
      }
    }

    useEffect(() => {
      getData()
      // getDataDummy()
    }, [])

    return(
      <div className="container">
      {isLoading ? (
        <div style={{padding: '5em 0em'}}>
          <AppLoading text="Memuat data siswa"/>  
        </div>
      ) : (
        <>
        <Col offset={6} xs={12} md={12} lg={12}>
          <Card className="app-card" style={{height: '100%'}}>
            {/* {JSON.stringify(data)}   */}
            <Row gutter={20}>
              <Col span={12}>
                <Row>
                  Nama
                </Row>
                <Row>
                <Text className='text-title'>{data.namaSiswa}</Text> 
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  Status
                </Row>
                <Row>
                  <Text className='text-title'>{data.status}</Text> 
                </Row>
              </Col>
              
              <Col span={24} style={{minHeight: 200}}>
                
              </Col>

              <Col span={12} style={{padding: 20}}>
                <Row gutter={20} style={{backgroundColor: '#A8E723', borderRadius: 5, alignItems: 'center'}}>
                  <Col span={11}>
                    Benar
                  </Col>
                  <Col span={2}/>
                  <Col span={11} style={{textAlign: 'right'}}>
                    <Text className='text-heading-verifikator'>{data.benar}</Text>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{padding: 20}}>
                <Row gutter={20} style={{backgroundColor: '#E73B23', borderRadius: 5, alignItems: 'center'}}>
                  <Col span={11}>
                    Salah
                  </Col>
                  <Col span={2}/>
                  <Col span={11} style={{textAlign: 'right'}}>
                    <Text className='text-heading-verifikator'>{data.salah}</Text>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Button
                  style={{minHeight: '100%', width: '100%'}}
                  onClick={()=>setShow(true)}
                >
                  Review Soal
                </Button>
              </Col>
            </Row>
          </Card>

          <Modal 
            visible={isShow}
            onCancel={()=>setShow(false)}
            footer={null}
            closeIcon={null}
          >
            {
              data.reviewSoal? 
                <Col>
                  <Row style={{borderBottom: '1px solid #d9d9d9'}}>
                    <Col span={24}><Title level={4}>Review Soal</Title></Col>
                  </Row>
                  {data.reviewSoal.map(res => {
                    return (
                      <Row style={{padding: 20}}>
                        <Col span={12}>
                          {res.matpel}
                        </Col>
                        <Col span={12}>
                          {res.skor}
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
               :
              <AppLoading/>
            }
          </Modal>
        </Col>
        </>
      )}
      </div>
    )
}

export default withRouter(HasilUjian) 