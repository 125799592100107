import React from 'react'
import { Row, Col, Card, Button } from 'antd';
import { confirmDialog2 } from '../../functions/alert';

function NilaiKomit(props) {

  const createMessage = "Apakah Anda setuju dengan syarat beasiswa dan ketentuan berlaku ?"
  // const cancelMessage = 'Apakah Anda yakin untuk mengundurkan diri ?'
  const headTitle = props.dataKomit.komitmenUps !== 'Flat Offer' ? props.dataKomit.komitmenUps : undefined

  let isPotBiayaUps = props.nilaiKomitmen.potBiaya || props.nilaiKomitmen.potBiayaUps || props.nilaiKomitmen.potBiayaUs ? true : false
  let isUpsSetelahPot = props.nilaiKomitmen.potBiaya || props.nilaiKomitmen.upsSetelahPot || props.nilaiKomitmen.usSetelahPot ? true : false

  const nilaiKomitmen = {
    isPotBiayaUps: isPotBiayaUps,
    isUpsSetelahPot: isUpsSetelahPot,
    standarUps: props.nilaiKomitmen.standarUps !== '-' ? 'Rp. ' + props.nilaiKomitmen.standarUps + ',-' : '-',
    standarUs: props.nilaiKomitmen.standarUs !== '-' ? 'Rp. ' + props.nilaiKomitmen.standarUs + ',-' : '-',
    potBiayaUps: props.nilaiKomitmen.potBiayaUps ? 'Rp. ' + props.nilaiKomitmen.potBiayaUps + ',-' : '-',
    potBiayaUs: props.nilaiKomitmen.potBiayaUs ? 'Rp. ' + props.nilaiKomitmen.potBiayaUs + ',-' : '-',
    upsSetelahPot: props.nilaiKomitmen.upsSetelahPot ?  'Rp. ' + props.nilaiKomitmen.upsSetelahPot + ',-' : '-',
    usSetelahPot: props.nilaiKomitmen.usSetelahPot ? 'Rp. ' + props.nilaiKomitmen.usSetelahPot + ',-' : '-',
    diskonBeasiswa: props.nilaiKomitmen.diskonBeasiswa !== '-' ? 'Rp. ' + props.nilaiKomitmen.diskonBeasiswa + ',-' : '-',
    diskonBeasiswaUs: props.nilaiKomitmen.diskonBeasiswaUs !== '-' ? 'Rp. ' + props.nilaiKomitmen.diskonBeasiswaUs + ',-' : '-',
    diskonTambahan: props.nilaiKomitmen.diskonTambahan !== '-' ? 'Rp. ' + props.nilaiKomitmen.diskonTambahan + ',-' : '-',
    diskonTambahanUs: props.nilaiKomitmen.diskonTambahanUs !== '-' ? 'Rp. ' + props.nilaiKomitmen.diskonTambahanUs + ',-' : '-',
    upsFinal: props.nilaiKomitmen.upsFinal !== '-' ? 'Rp. ' + props.nilaiKomitmen.upsFinal + ',-' : '-',
    usFinal: props.nilaiKomitmen.usFinal !== '-' ? 'Rp. ' + props.nilaiKomitmen.usFinal + ',-' : '-',
    upsKontan: props.nilaiKomitmen.upsKontan ? 'Rp. ' + props.nilaiKomitmen.upsKontan + ',-' : '-',
    usKontan: props.nilaiKomitmen.usKontan ? 'Rp. ' + props.nilaiKomitmen.usKontan + ',-' : '-',
    
    nameDiskonALL: props.nilaiKomitmen.potBiaya ? props.nilaiKomitmen.potBiaya : '-',
    nameDiskonBeasiswa: props.nilaiKomitmen.beasiswa ? props.nilaiKomitmen.beasiswa : '-',
    namaDiskonTambahan : props.nilaiKomitmen.namaDiskonTambahan ? props.nilaiKomitmen.namaDiskonTambahan : '-',
    setuju: props.nilaiKomitmen.setuju ? props.nilaiKomitmen.setuju : false,
    batasWaktu: props.nilaiKomitmen.batasWaktu ? props.nilaiKomitmen.batasWaktu : false,
  }

  console.log(props.nilaiKomitmen.dataPotonganBiaya)

  const dataPotongan = props.nilaiKomitmen.dataPotonganBiaya ? props.nilaiKomitmen.dataPotonganBiaya : []

  const IsDesktopView = () => {
    return(
      <div>
        <Col span={24}>
          <Card className="app-card-info-user" style={{height: '100%'}}>
            <Row>
              <Col span={12}>
              </Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <span className='text-header-finance'>
                  UPS
                </span>
              </Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <span className='text-header-finance'>
                  US
                </span>
              </Col>
              <Col span={12}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  Nilai Komitmen Standar
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {nilaiKomitmen.standarUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {nilaiKomitmen.standarUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              {/* {
                nilaiKomitmen.isPotBiayaUps &&
                <Col span={24}>
                  <Row>
                    <Col span={12}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {nilaiKomitmen.nameDiskonALL}
                      </span>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {nilaiKomitmen.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                      </span>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {nilaiKomitmen.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                      </span>
                    </Col>
                  </Row>
                </Col>
              }
              {
                nilaiKomitmen.isUpsSetelahPot && 
                
                <Col span={24}>
                  <Row>
                    <Col span={12} style={{ margin: '20px 0px'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', color: '#072A6E', margin: '20px, 0'}}
                      >
                        Nilai Komitmen ( setelah {nilaiKomitmen.nameDiskonALL} )
                      </span>
                    </Col>
                    <Col span={6} style={{ margin: '20px 0px', textAlign: 'right'  }}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', color: '#072A6E'}}
                      >
                        {nilaiKomitmen.upsSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                      </span>
                    </Col>
                    <Col span={6} style={{ margin: '20px 0px', textAlign: 'right'  }}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', color: '#072A6E'}}
                      >
                        {nilaiKomitmen.usSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                      </span>
                    </Col>
                  </Row>
                </Col>
              } */}
              <Col span={24}>
                <span 
                  className='text-header-finance' 
                  style={{ color: '#000000'}}
                >
                  Potongan : 
                </span>
              </Col>
              {dataPotongan.length > 0 ? 
                (
                  dataPotongan.map((res,index) => (
                    (dataPotongan.length == index + 1) ?
                        <>
                        <Col span={24} style={{margin: '20px 0px'}}>
                          <Row>
                            <Col span={12}>
                              <span 
                                className='text-header-finance' 
                                style={{ fontWeight: 'normal', color: '#072A6E'}}
                              >
                                {res.potBiaya}:
                              </span>
                            </Col>
                            <Col span={6} style={{textAlign: 'right'}}>
                              <span 
                                className='text-header-finance' 
                                style={{ fontWeight: 'normal', color: '#072A6E'}}
                              >
                                {res.potBiayaUps !== 0 && 'Rp. '} 
                                {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                                {res.potBiayaUps !== 0 && ',-'}
                              </span>
                            </Col>
                            <Col span={6} style={{textAlign: 'right'}}>
                              <span 
                                className='text-header-finance' 
                                style={{ fontWeight: 'normal', margin: '20px 0px', color: '#072A6E'}}
                              >
                                {res.potBiayaUs !== 0 && 'Rp. '} 
                                {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                                {res.potBiayaUs !== 0 && ',-'}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        </> :
                        <>
                          <Col span={12}>
                            <span 
                              className='text-header-finance' 
                              style={{ fontWeight: 'normal'}}
                            >
                              • {res.potBiaya}:
                            </span>
                          </Col>
                          <Col span={6} style={{textAlign: 'right'}}>
                            <span 
                              className='text-header-finance' 
                              style={{ fontWeight: 'normal'}}
                            >
                              {res.potBiayaUps !== 0 && 'Rp. '} 
                              {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                              {res.potBiayaUps !== 0 && ',-'}
                            </span>
                          </Col>
                          <Col span={6} style={{textAlign: 'right'}}>
                            <span 
                              className='text-header-finance' 
                              style={{ fontWeight: 'normal'}}
                            >
                              {res.potBiayaUs !== 0 && 'Rp. '} 
                              {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                              {res.potBiayaUs !== 0 && ',-'}
                            </span>
                          </Col>
                        </>
                  ))
                )
                : 
                (
                  <Col span={24}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      • Tidak ada potongan
                    </span>
                  </Col>
                )
              }
              <Col span={12}>
                <span 
                  className='text-header-finance' 
                  style={{ color: '#000000'}}
                >
                  Beasiswa :
                </span>
              </Col>
              <Col span={6}>
              </Col>
              <Col span={6}>
              </Col>
              <Col span={12}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  • Beasiswa Utama: {nilaiKomitmen.nameDiskonBeasiswa}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {nilaiKomitmen.diskonBeasiswa.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {nilaiKomitmen.diskonBeasiswaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={12}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  • Beasiswa Tambahan: {nilaiKomitmen.namaDiskonTambahan}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {nilaiKomitmen.diskonTambahan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  {nilaiKomitmen.diskonTambahanUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={12} style={{ margin: '20px 0px' }}>
                <span 
                  className='text-header-finance' 
                  style={{ color: '#000000'}}
                >
                  Nilai Komitmen ( Apabila Beasiswa Disetujui )
                </span>
              </Col>
              <Col span={6} style={{ margin: '20px 0px', textAlign: 'right' }}>
                <span 
                  className='text-header-finance' 
                  style={{ textAlign: 'right', color: '#000000' }}
                >
                  {nilaiKomitmen.upsFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{ margin: '20px 0px', textAlign: 'right' }}>
                <span 
                  className='text-header-finance' 
                  style={{ textAlign: 'right', color: '#000000' }}
                >
                  {nilaiKomitmen.usFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={12}>
                <span 
                  className='text-diskon'
                >
                  "Apabila pembayaran UPS dilunasi di awal, maka siswa mendapatkan diskon tambahan sebesar 5%"
                </span>
              </Col>
              {/* <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal', color: '#072A6E'}}
                >
                  {nilaiKomitmen.upsKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col>
              <Col span={6} style={{textAlign: 'right'}}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal', color: '#072A6E'}}
                >
                  {nilaiKomitmen.usKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </span>
              </Col> */}
            </Row>
          </Card>
        </Col>
      </div>
    )
  }
  const IsMobileView = () => {
    return(
      <div>
        <Col span={24}>
          <Card className="app-card-info-user" style={{height: '100%'}}>
            <Row>
              <Col span={24}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  Nilai Komitmen Standar
                </span>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      UPS
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      {nilaiKomitmen.standarUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      US
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      {nilaiKomitmen.standarUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              {/* {
                nilaiKomitmen.isPotBiayaUps &&
                  <div style={{width: '100%'}}>
                    <Col span={24}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', marginTop: 20}}
                      >
                        {nilaiKomitmen.nameDiskonALL}
                      </span>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={6}>
                          <span className='text-header-finance'>
                            UPS
                          </span>
                        </Col>
                        <Col span={18} style={{textAlign: 'end'}}>
                          <span 
                            className='text-header-finance' 
                            style={{ fontWeight: 'normal'}}
                          >
                            {nilaiKomitmen.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={6}>
                          <span className='text-header-finance'>
                            US
                          </span>
                        </Col>
                        <Col span={18} style={{textAlign: 'end'}}>
                          <span 
                            className='text-header-finance' 
                            style={{ fontWeight: 'normal'}}
                          >
                            {nilaiKomitmen.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                </div>
              }
              {
                nilaiKomitmen.isUpsSetelahPot &&
                  <div style={{ marginTop: 20, marginBottom: 20, width: '100%'}}>
                    <Col span={24}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal', color: '#072A6E'}}
                      >
                        Nilai Komitmen ( setelah {nilaiKomitmen.nameDiskonALL} )
                      </span>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={6}>
                          <span className='text-header-finance'>
                            UPS
                          </span>
                        </Col>
                        <Col span={18} style={{textAlign: 'end'}}>
                          <span 
                            className='text-header-finance' 
                            style={{ fontWeight: 'normal'}}
                          >
                            {nilaiKomitmen.upsSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={6}>
                          <span className='text-header-finance'>
                            US
                          </span>
                        </Col>
                        <Col span={18} style={{textAlign: 'end'}}>
                          <span 
                            className='text-header-finance' 
                            style={{ fontWeight: 'normal'}}
                          >
                            {nilaiKomitmen.usSetelahPot.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                </div>
              } */}
              <Col span={24}>
                <span 
                  className='text-header-finance' 
                  style={{ color: '#000000'}}
                >
                  Potongan : 
                </span>
              </Col>
              {dataPotongan.length > 0 ? 
          (
            dataPotongan.map((res,index) => (
                (dataPotongan.length == index + 1) ?
                <>
                  <Col span={24} style={{marginTop: 20}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal', color: '#072A6E'}}
                    >
                      {res.potBiaya}:
                    </span>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={6}>
                        <span className='text-header-finance'>
                          UPS
                        </span>
                      </Col>
                      <Col span={18} style={{textAlign: 'end'}}>
                        <span 
                          className='text-header-finance' 
                          style={{ fontWeight: 'normal', color: '#072A6E'}}
                        >
                          {res.potBiayaUps !== 0 && 'Rp. '} 
                          {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                          {res.potBiayaUps !== 0 && ',-'}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={6}>
                        <span className='text-header-finance'>
                          US
                        </span>
                      </Col>
                      <Col span={18} style={{textAlign: 'end'}}>
                        <span 
                          className='text-header-finance' 
                          style={{ fontWeight: 'normal', color: '#072A6E'}}
                        >
                          {res.potBiayaUs !== 0 && 'Rp. '} 
                          {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                          {res.potBiayaUs !== 0 && ',-'}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </>:
                  <>
                    <Col span={12}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        • {res.potBiaya}:
                      </span>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {res.potBiayaUps !== 0 && 'Rp. '} 
                        {res.potBiayaUps !== 0 ? res.potBiayaUps.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUps !== 0 && ',-'}
                      </span>
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                      <span 
                        className='text-header-finance' 
                        style={{ fontWeight: 'normal'}}
                      >
                        {res.potBiayaUs !== 0 && 'Rp. '} 
                        {res.potBiayaUs !== 0 ? res.potBiayaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : '-'}
                        {res.potBiayaUs !== 0 && ',-'}
                      </span>
                    </Col>
                  </>
            ))
          )
                : 
                (
                  <Col span={24}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      • Tidak ada potongan
                    </span>
                  </Col>
                )
              }
              <Col span={24} style={{marginTop: 20}}>
                <span 
                  className='text-header-finance' 
                  style={{ color: '#000000'}}
                >
                  Beasiswa :
                </span>
              </Col>
              <Col span={24}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  • Beasiswa Utama: {nilaiKomitmen.nameDiskonBeasiswa}
                </span>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      UPS
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      {nilaiKomitmen.diskonBeasiswa.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      US
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      {nilaiKomitmen.diskonBeasiswaUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <span 
                  className='text-header-finance' 
                  style={{ fontWeight: 'normal'}}
                >
                  • Beasiswa Tambahan: {nilaiKomitmen.namaDiskonTambahan}
                </span>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      UPS
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      {nilaiKomitmen.diskonTambahan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      US
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal'}}
                    >
                      {nilaiKomitmen.diskonTambahanUs.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ margin: '20px 0px' }}>
                <span 
                  className='text-header-finance' 
                  style={{ color: '#000000'}}
                >
                  Nilai Komitmen ( Apabila Beasiswa Disetujui )
                </span>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      UPS
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ textAlign: 'right', color: '#000000' }}
                    >
                      {nilaiKomitmen.upsFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      US
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ textAlign: 'right', color: '#000000' }}
                    >
                      {nilaiKomitmen.usFinal.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <span 
                  className='text-diskon'
                >
                  "Apabila pembayaran UPS dilunasi di awal, maka siswa mendapatkan diskon tambahan sebesar 5%"
                </span>
              </Col>
              {/* <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      UPS
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal', color: '#072A6E'}}
                    >
                      {nilaiKomitmen.upsKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col> */}
              {/* <Col span={24}>
                <Row>
                  <Col span={6}>
                    <span className='text-header-finance'>
                      US
                    </span>
                  </Col>
                  <Col span={18} style={{textAlign: 'end'}}>
                    <span 
                      className='text-header-finance' 
                      style={{ fontWeight: 'normal', color: '#072A6E'}}
                    >
                      {nilaiKomitmen.usKontan.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                    </span>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Card>
        </Col>
      </div>
    )
  }

  return(
    <>
      <Row gutter={[40, 24]}>
        <Col span={24}>
          <Card className="app-card-info-user" style={{height: '100%', textAlign: 'center'}}>
            <span className='head-modal' style={{ position: 'absolute', left: '26px'}}>
              {headTitle ? headTitle === 'Negosiasi' ? headTitle.toUpperCase() : headTitle.toUpperCase() + ' PACKAGE' : ''}
            </span>
            <span className='head-modal' style={{ fontWeight: '600'}} >
              NILAI KOMITMEN UPS & US YANG DISETUJUI OLEH TIM FINANCE & SCHOLARSHIP
            </span>
          </Card>
        </Col>
      </Row>
      <Row gutter={[40, 24]}>
        {
          props.responsive && props.responsive.grid.xs ?
          <IsMobileView/> : <IsDesktopView/>
        }
        <Col span={24} style={props.responsive && props.responsive.grid.xs ? {textAlign: 'center', marginBottom: 20} : {}}>
          <Row>
            <Col xs={24} sm={12} style={props.responsive && props.responsive.grid.xs ? {textAlign: 'center', marginBottom: 20} : {}}>
              <Button
                type='text'
                className='app-btn text xl'
                onClick={() => props.hanldeBack()}
              >
                Kembali
              </Button>
            </Col>
            <Col xs={24} sm={12} style={props.responsive && props.responsive.grid.xs ? {textAlign: 'center'} : {}}>{nilaiKomitmen.setuju || nilaiKomitmen.batasWaktu ? (
              <Button
                className='app-btn primary xl'
                style={props.responsive && props.responsive.grid.xs ? {} : { float: 'right'}}
                disabled={true}
                onClick={ () =>
                  confirmDialog2({title: 'Peringatan !!!', subtitle: 'Membuat Transaksi Baru', text: createMessage, icon: 'warning'})
                  .then(_ => {props.handleSubmitNilai('Setuju')}).catch(_ => {})
                }
              >
                Setuju
              </Button>
              ) : (
                <Button
                  className='app-btn primary xl'
                  style={props.responsive && props.responsive.grid.xs ? {} : { float: 'right'}}
                  // disabled={props.disableButton}
                  onClick={ () =>
                    confirmDialog2({title: 'Peringatan !!!', subtitle: 'Membuat Transaksi Baru', text: createMessage, icon: 'warning'})
                    .then(_ => {props.handleSubmitNilai('Setuju')}).catch(_ => {})
                  }
                >
                  Setuju
                </Button>
              )}
            </Col>
          </Row>
        {/* <Button
          className='login-form-button'
          style={{ float: 'right', marginRight: '50px'}}
          // disabled={props.disableButton}
          onClick={ () =>
            confirmDialog2({title: 'Peringatan !!!', subtitle: 'Membuat Transaksi Baru', text: cancelMessage, icon: 'warning'})
            .then(_ => {props.handleSubmitNilai('MengundurkanDiri')}).catch(_ => {})
          }
        >
          Mengundurkan Diri
        </Button> */}
      </Col>
      </Row>
    </>
  )

}

export default NilaiKomit
