import { Input } from 'antd'
import React, { useState } from 'react'

function forceInputUppercase(e) {
  var start = e.target.selectionStart;
  var end = e.target.selectionEnd;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(start, end);
}

export default function UppercaseInput({onChange, value, ...props}) {
  const handleChange = (e) => {
    forceInputUppercase(e);
    // console.log('changeEvent: ', e.target.value);
    onChange(e.target.value);
  }

  return (
    <Input {...props} value={value} onChange={e => {handleChange(e)}} />
  )
}
