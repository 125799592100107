import React, {useEffect, useState} from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Row, PageHeader, Col, Card, Button, Spin } from 'antd';
import InfoCard from '../Pendaftaran/Cards/infoCard';
// import { AppLoading } from '../../component/loading';

import { dialog, confirmDialog2 } from '../../functions/alert';

import UICardFinance from '../../component/card-finance';
import UIModal from '../../component/pop-up'
import CONFIG from '../../service/config';
import Axios from 'axios';
import Offer from './offter'
import Downloader from 'js-file-downloader';
import Verifikasi from './verifikasi';
import NilaiKomit from './nilaiKomit'
import AdminConfig from '../../service/admin-config';
import Auth from '../../service/auth';


function Finance(props) {
  const history = useHistory();

  const negoMessage = 'Apakah Anda yakin untuk mengajukan negosiasi ?'

  const fileInput = React.createRef();
  const fileInputSeni = React.createRef();
  const fileInputYatim = React.createRef();
  const fileInputRekamMedis = React.createRef();
  const fileInputTempatTinggal = React.createRef();
  const fileInputSktm = React.createRef();
  const [dataInfo, setDataInfo] = useState({
    komitmenFlatOffer: undefined,
    komitmenFlatOfferPlus: undefined,
    komitmenNegosiasi: undefined
  })
  const [isVisible, setVisible] = useState(false)
  const [modalTextHead, setModalTextHead] = useState('')
  const [modalTextBody, setModalTextBody] = useState('')
  const [isOfferPlus, setIsOfferPlus] = useState(false)
  const [from, setFrom] = useState('')
  const [packageSelect, setPackage] = useState('')
  const [dataFlat, setDataFlat] = useState(undefined)
  const [loadingContainer, setLoadingContainer] = useState(true)
  const [komitId, setKomitId] = useState(undefined)
  const [beasiswa, setBeasiswa] = useState({
    umum: 0,
    tambahan: 0
  })
  const [nilaiSiswa, setNilai] = useState({
    semester1: undefined,
    semester2: undefined,
    semester3: undefined,
    semester4: undefined
  })
  const [diskon, setDiskon] = useState({
    rapor: null,
    seniOlahraga: [],
    ofon: null,
    kekl: null
  })
  const [LOV, setLOV] = useState({
    tingkat: [],
    kategori: [],
    juara: [],
    tahun: []
  })
  const [selectKejuaraan, setKejuaraan] = useState({
    tingkat: 1,
    kategori: 1,
    juara: 1,
    namaKejuaraan: '',
    tahunKejuaraan: '',
    namaPenyelenggara: ''
  })
  const [fileNameKekl, setFileNameKekl] = useState(null)
  const [filePathKekl, setFilePathKekl] = useState(null)
  
  const [fileNameSeni, setFileNameSeni] = useState(null)
  const [filePathSeni, setFilePathSeni] = useState(null)


  const [fileNameYatim, setFileNameYatim] = useState(null)
  const [filePathYatim, setFilePathYatim] = useState(null)

  const [fileNameMedis, setFileNameMedis] = useState(null)
  const [filePathMedis, setFilePathMedis] = useState(null)

  const [fileNameTinggal, setFileNameTinggal] = useState(null)
  const [filePathTinggal, setFilePathTinggal] = useState(null)

  const [fileNameSktm, setFileNameSktm] = useState(null)
  const [filePathSktm, setFilePathSktm] = useState(null)

  const [disableButton, setDisbled] = useState(false)

  const [loadingUpload, setLoadingUpload] = useState({
    kekl: false,
    seni: false,
    yatim: false,
    rekamMedis: false,
    tempatTinggal: false,
    sktm: false,
    isLoading: false
  })

  const [dataSimulasi, setDataSimulasi] = useState(undefined)
  const [dataKomit, setDataKomit] = useState({
    alreadyKomitmen: false
  })

  const configAuth = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`
    }
  }; 

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [nilaiKomitmen, setNilaiKomitmen] = useState({})

  useEffect(() => {
    document.title = props.name + " | Loyola"
    initialConfig()
  }, [])

  const initialConfig = () => {
    AdminConfig.getDataConfig().then((res)=>{
      if (Auth.isLogin()){
        getPengajuan()
      } else {
        history.push('/login')
      }
    }).catch(err => {
      if(err.response){
        console.log('AdminConfig Error : ',err.response.data.message)
      }
    })
  }

  useEffect(()=>{
    const isDisabled = JSON.parse(sessionStorage.getItem('adminConfig')) || {};
    const userData = JSON.parse(sessionStorage.getItem('user')) || {};
    console.log('jalur : ',props.jalurPenerimaan)
    if(userData.jalurPenerimaan == 'Prestasi' && !isDisabled.prestasiLogin){ //login dan sistem login ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.prestasiLoginInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      history.push("/login")
    }else if(userData.jalurPenerimaan != 'Prestasi' && !isDisabled.tesLogin){ //login dan sistem login ditutup
      dialog({title: 'Pemberitahuan', text: `${isDisabled.tesLoginInfo}`, icon: 'info', className: 'alert-justify'}).then(Auth.logout())
      history.push("/login")
    }
  },[sessionStorage.getItem('adminConfig')])

  const getPengajuan = () => {
    Axios.get(CONFIG.BASE_URL + `/api/komitmen?userId=${user.userId}`, configAuth).then(res => {
      if (res.data.Status === 'OK') {
        if (res.data.Data.alreadyKomitmen) {
          setDataKomit(res.data.Data)
          setLoadingContainer(false)
          setFrom('afterKomit')
          setIsOfferPlus(false)
          setBeasiswa({
            umum: 0,
            tambahan: 0
          })
        }else {
          getDataFinance()
        }
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getDataFinance = () => {
    Axios.get(CONFIG.BASE_URL + '/api/komitmenUps', configAuth).then(res => {
      if (res.data.Status === 'OK') {
        setDataInfo(res.data.Data)
        setLoadingContainer(false)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  useEffect(() => {
    if (!loadingUpload.isLoading) {
      generateDisable()
    }
  })

  const handleShow = (textHead, textBody) => {
    let head = textHead ? textHead : ''
    let body = textBody ? textBody : ''
    setModalTextHead(head)
    setModalTextBody(body.replace('\\n', '\r\n'))
    setVisible(true)
  }

  const handleClose = () => {
    setModalTextHead('')
    setModalTextBody('')
    setVisible(false)
  }

  const handleDirect = (name, id) => {
    setKomitId(id)
    if (name === 'offer') {
      setLoadingContainer(true)
      getDataOffer(id)
      setFrom(name)
    }else if (name === 'plus') {
      setLoadingContainer(true)
      getDataPlus()
      setFrom(name)
    }else {
      setFrom(name)
      confirmDialog2({title: 'Peringatan !!!', subtitle: 'Membuat Transaksi Baru', text: negoMessage, icon: 'warning'})
      .then(() => {
        handleAjukan(name, id)
      }).catch(_ => {})
    }
  }

  const hanldeBack = () => {
    setDisbled(false)
    if (packageSelect !== '') {
      if ((from === 'rapor' || from === 'seni') || (from === 'olahraga' || from === 'ofon') || from === 'tidak') {
        getDataOffer(komitId)
        setFrom('offer')
      }else if ( from === 'simulasi') {
        if (beasiswa.umum === 1) {
          setFrom('rapor')
        }else if (beasiswa.umum === 2) {
          setFrom('seni')
        }else if (beasiswa.umum === 3) {
          setFrom('olahraga')
        }else if (beasiswa.umum === 4) {
          setFrom('ofon')
        }else if (beasiswa.umum === 0) {
          setFrom('tidak')
        }
      }else {
        setFrom('plus')
        setPackage('')
        getDataPlus()
      }
    }else if ((from === 'rapor' || from === 'seni') || (from === 'olahraga' || from === 'ofon') || from === 'tidak') {
      getDataOffer(komitId)
      setFrom('offer')
    }else if ( from === 'simulasi') {
      if (beasiswa.umum === 1) {
        setFrom('rapor')
      }else if (beasiswa.umum === 2) {
        setFrom('seni')
      }else if (beasiswa.umum === 3) {
        setFrom('olahraga')
      }else if (beasiswa.umum === 4) {
        setFrom('ofon')
      }else if (beasiswa.umum === 0) {
        setFrom('tidak')
      }
    }else if (from === 'nilai') {
      setFrom('afterKomit')
    }
    else {
      setIsOfferPlus(false)
    }
  }

  const handlePackage = (name, id) => {
    setPackage(name)
    setKomitId(id)
    getDataOffer(id)
  }

  const getDataOffer = (id) => {
    Axios.get(CONFIG.BASE_URL + 
      `/api/komitmenUps/beasiswaKomitmen?komitmenId=${id}&jalurPenerimaan=${user.jalurPenerimaan}&userId=${user.userId}`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        setDataFlat(res.data.Data)
        setIsOfferPlus(true)
        setFrom('offer')
      }
      setLoadingContainer(false)
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
        setLoadingContainer(false)
      }
    })
  }

  const getDataDiskon = (id) => {
    const idUmum = id !== 0 ? id : ''
    const idTambahan = beasiswa.tambahan !== 0 ? true : false
    Axios.get(CONFIG.BASE_URL + 
      `/api/potensiDiskon?komitmenId=${komitId}&beasiswaId=${idUmum}&userId=${user.userId}&beasiswaTambahan=${idTambahan}`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        if (res.data.Data.nilaiSiswa) {
          setNilai({
            semester1: res.data.Data.nilaiSiswa.semester1,
            semester2: res.data.Data.nilaiSiswa.semester2,
            semester3: res.data.Data.nilaiSiswa.semester3,
            semester4: res.data.Data.nilaiSiswa.semester4
          })
          setFrom('rapor')
          setDiskon({
            rapor: res.data.Data.potensiDiskon,
            kekl: res.data.Data.potensiDiskonTambahan
          })
        }else if (id === 2 || id === 3) {
          setDiskon({
            seniOlahraga: res.data.Data.potensiDiskon,
            kekl: res.data.Data.potensiDiskonTambahan
          })
          setFrom('seni')
        }else if (id === 4) {
          setDiskon({
            ofon: res.data.Data.potensiDiskon,
            kekl: res.data.Data.potensiDiskonTambahan
          })
          setFrom('ofon')
        }else {
          setDiskon({
            ofon: res.data.Data.potensiDiskon,
            kekl: res.data.Data.potensiDiskonTambahan
          })
          setFrom('tidak')
        }
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getDataPlus = () => {
    Axios.get(CONFIG.BASE_URL + 
      `/api/komitmenUps/jenisFlatOfferPlus`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        setDataFlat(res.data.Data)
        setIsOfferPlus(true)
      }
      setLoadingContainer(false)
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
        setLoadingContainer(false)
      }
    })
  }

  const getLovTingkat = (name) => {
    Axios.get(CONFIG.BASE_URL + 
      `/api/lovTingkat`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        setLOV({
          tingkat: res.data.Data,
          juara: LOV.juara,
          kategori: LOV.kategori,
          tahun: LOV.tahun
        })
        getLovKategori(res.data.Data, name)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getLovKategori = (data, name) => {
    Axios.get(CONFIG.BASE_URL + 
      `/api/lovKategori`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        setLOV({
          tingkat: data,
          juara: LOV.juara,
          kategori: res.data.Data,
          tahun: LOV.tahun
        })
        getLovJuara(data, res.data.Data, name)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getLovJuara = (dataTingkat, dataKategori, name) => {
    Axios.get(CONFIG.BASE_URL + 
      `/api/lovJuara`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        setLOV({
          tingkat: dataTingkat,
          juara: res.data.Data,
          kategori: dataKategori,
          tahun: LOV.tahun
        })
        getLOVTahun(dataTingkat, dataKategori, res.data.Data, name)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getLOVTahun = (dataTingkat, dataKategori, dataJuara, name) => {
    Axios.get(CONFIG.BASE_URL + 
      `/api/lovTahun`, 
      configAuth)
    .then(res => {
      if (res.data.Status === 'OK') {
        setLOV({
          tingkat: dataTingkat,
          juara: dataJuara,
          kategori: dataKategori,
          tahun: res.data.Data
        })
        setFrom(name)
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getDataSimulasi = () => {
    const isTambahan = beasiswa.tambahan !== 0 ? true : false
    const idUmum = beasiswa.umum !== 0 ? beasiswa.umum : ''
    let body ={
    }
    if (beasiswa.umum === 1) {
      body = nilaiSiswa
    }else if (beasiswa.umum === 2 || beasiswa.umum === 3) {
      body = selectKejuaraan
    }else {
      body = {
        yatim: filePathYatim,
        rekamMedis: filePathMedis,
        tempatTinggal: filePathTinggal,
        sktm: filePathSktm
      }
    }

    Axios.post(CONFIG.BASE_URL + 
      `/api/simulasiKomitmen?komitmenId=${komitId}&beasiswaId=${idUmum}&beasiswaTambahan=${isTambahan}`, 
      body, configAuth)
      .then(res => {
        if (res.data.Status === 'OK') {
          setDataSimulasi(res.data.Data)
          setFrom('simulasi')
      }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const handleNext = (name, id) => {
    if (from === 'offer') {
      getDataDiskon(id)
      if (id === 2 || id === 3) {
        getLovTingkat(name)
      }
    }else if (from === 'simulasi') {
      handleAjukan(name, id)
    }else {
      getDataSimulasi()
    }
  }

  const handleRadio = (val, name) => {
    if (name === 'umum') {
      setBeasiswa({
        umum: val,
        tambahan: beasiswa.tambahan
      })
    }else {
      setBeasiswa({
        umum: beasiswa.umum,
        tambahan: val
      })
    }
  }

  const handleKejuaraan = (name, val) => {
    const tempSelect = selectKejuaraan
    if (name === 'tingkat') {
      setKejuaraan({
        ...tempSelect,
        tingkat: val,
      })
    }else if (name === 'kategori') {
      setKejuaraan({
        ...tempSelect,
        kategori: val,
      })
    }else if (name ==='juara') {
      setKejuaraan({
        ...tempSelect,
        juara: val
      })
    }else if (name === 'nama') {
      setKejuaraan({
        ...tempSelect,
        namaKejuaraan: val
      })
    }else if (name === 'tahun') {
      setKejuaraan({
        ...tempSelect,
        tahunKejuaraan: val
      })
    }else {
      setKejuaraan({
        ...tempSelect,
        namaPenyelenggara: val
      })
    }
  }

  const openFileBrowser = (name) => {
    if (name === 'kekl') {
      fileInput.current.click();
    } else if (name === 'sertifikat') {
      fileInputSeni.current.click();
    }else if (name === 'yatim') {
      fileInputYatim.current.click();
    }else if (name === 'rekamMedis') {
      fileInputRekamMedis.current.click();
    }else if (name === 'tempatTinggal') {
      fileInputTempatTinggal.current.click();
    }else if (name === 'sktm'){
      fileInputSktm.current.click();
    }
  };

  const fileHandler = (event, name) => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      const fileNameValue = file.name;
      if (name === 'kekl') {
        setFileNameKekl(fileNameValue);
      } else if (name === 'sertifikat') {
        setFileNameSeni(fileNameValue)
      }else if (name === 'yatim') {
        setFileNameYatim(fileNameValue)
      }else if (name === 'rekamMedis') {
        setFileNameMedis(fileNameValue)
      }else if (name === 'tempatTinggal') {
        setFileNameTinggal(fileNameValue)
      }else if (name === 'sktm') {
        setFileNameSktm(fileNameValue)
      }
      
      const formData = new FormData();
      formData.append('file', file);
      if (from === 'ofon') {
        formData.append('from', name)
      }
      formData.append('userId', user.userId)
      uploadAssignmentLetter(formData, name);
    }
  };

  const uploadAssignmentLetter = (data, name) => {
    let url = ''
    if (name === 'kekl') {
      url = 'keklKKL'
    }else if (name === 'sertifikat') {
      url = 'sertifikat'
    }else {
      url = 'berkasOfon'
    }
    setLoadingUpload({[name]: true, isLoading: true});
    Axios.post(CONFIG.BASE_URL+`/api/upload/` + url, data, configAuth)
    .then(res => {
      if ( res.data.Status === "OK" ) {
        let altName = name;
        if (name === 'kekl') {
          setFilePathKekl(res.data ? res.data.fileUpload : "")
        }else if (name === 'sertifikat') {
          setFilePathSeni(res.data ? res.data.fileUpload : "")
        }else if (name === 'yatim') {
          setFilePathYatim(res.data ? res.data.fileUpload : "")
        }else if (name === 'rekamMedis') {
          altName = 'rekam medis'
          setFilePathMedis(res.data ? res.data.fileUpload : "")
        }else if (name === 'tempatTinggal') {
          altName = 'tempat tinggal'
          setFilePathTinggal(res.data ? res.data.fileUpload : "")
        }else if (name === 'sktm') {
          setFilePathSktm(res.data ? res.data.fileUpload : "")
        }
        dialog({icon: 'success', text: 'Upload data ' + altName.toUpperCase() + ' Berhasil'})
      }else {
        dialog({icon: 'error', text: res.data.Message})
        if (name === 'kekl') {
          setFilePathKekl(null)
          setFileNameKekl(null)
        }else if (name ==='sertifikat') {
          setFilePathSeni(null)
          setFileNameSeni(null)
        }else if (name ==='yatim') {
          setFilePathYatim(null)
          setFileNameYatim(null)
        }else if (name ==='rekamMedis') {
          setFilePathMedis(null)
          setFileNameMedis(null)
        }else if (name ==='tempatTinggal') {
          setFilePathTinggal(null)
          setFileNameTinggal(null)
        }else if (name ==='sktm') {
          setFilePathSktm(null)
          setFileNameSktm(null)
        }
      }
      setLoadingUpload({[name]: false, isLoading: false});
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
      setLoadingUpload({[name]: false, isLoading: false});
    });
  }

  const generateDisable = () => {
    const { namaKejuaraan, tahunKejuaraan, namaPenyelenggara } = selectKejuaraan
    if (from === 'tidak' || from === 'rapor') {
      if (beasiswa.tambahan === 1) {
        if (fileNameKekl === null) {
          setDisbled(true)
        }else {
          setDisbled(false)
        }
      }
    }
    if (beasiswa.tambahan !== 0) {
      if (from === 'seni' || from === 'olahraga') {
        if (
            namaKejuaraan === '' || 
            tahunKejuaraan === '' || 
            namaPenyelenggara === '' || 
            fileNameSeni === null || 
            fileNameKekl === null ) {
          setDisbled(true)
        }else {
          setDisbled(false)
        }
      }else if (from === 'ofon') {
        if ( fileNameYatim === null && 
          fileNameMedis === null && 
          fileNameTinggal === null &&
          fileNameSktm === null || 
          fileNameKekl === null) {
          setDisbled(true)
        }else {
          setDisbled(false)
        }
      }
    }else {
      if (from === 'seni' || from === 'olahraga') {
        if ( namaKejuaraan === '' ||
          tahunKejuaraan === '' ||
          namaPenyelenggara === '' ||
          fileNameSeni === null ) {
          setDisbled(true)
        }else {
          setDisbled(false)
        }
      }else if (from === 'ofon') {
        if ( fileNameYatim === null && 
          fileNameMedis === null && 
          fileNameTinggal === null &&
          fileNameSktm === null) {
          setDisbled(true)
        }else {
          setDisbled(false)
        }
      }
    }
  }

  const deleteUpload = (name) => {
    if (name === 'kekl') {
      setFilePathKekl(null)
      setFileNameKekl(null)
    }else if (name ==='sertifikat') {
      setFilePathSeni(null)
      setFileNameSeni(null)
    }else if (name ==='yatim') {
      setFilePathYatim(null)
      setFileNameYatim(null)
    }else if (name ==='rekamMedis') {
      setFilePathMedis(null)
      setFileNameMedis(null)
    }else if (name ==='tempatTinggal') {
      setFilePathTinggal(null)
      setFileNameTinggal(null)
    }else if (name ==='sktm') {
      setFilePathSktm(null)
      setFileNameSktm(null)
    }
  }

  const handleAjukan = (name, id) => {
    const {umum, tambahan} = beasiswa
    const {namaKejuaraan, namaPenyelenggara, tahunKejuaraan} = selectKejuaraan
    let body = {
      komitmenId: komitId ? komitId : id,
      upsFinal: dataSimulasi ? dataSimulasi.upsFinal : 0,
      usFinal: dataSimulasi ? dataSimulasi.usFinal : 0,
    }
    
    if (tambahan === 0 && name !== 'nego') {
      body = {
        ...body,
        beasiswaTambahan: false
      }
    }else if (name !== 'nego') {
      body = {
        ...body,
        beasiswaTambahan: true,
        fileUpload: filePathKekl
      }
    }

    if (umum !== 0) {
      body = {
        ...body,
        diskonId: dataSimulasi.diskonId
      }
    }

    if (umum === 2 || umum === 3) {
      body = {
        ...body,
        namaKejuaraan: namaKejuaraan,
        tahunKejuaraan: tahunKejuaraan,
        namaPenyelenggara: namaPenyelenggara,
        fileUploadPrestasi: filePathSeni
      }
    }

    if (umum === 4) {
      body = {
        ...body,
        sktm: filePathSktm,
        yatim: filePathYatim,
        rekamMedis: filePathMedis,
        tempatTinggal: filePathTinggal
      }
    }

    if (name === 'nego') {
      body = {
        komitmenId: id
      }
    }
    Axios.post(CONFIG.BASE_URL + 
      `/api/ajukanBeasiswa?userId=${user.userId}`, 
      body, configAuth)
      .then(res => {
        if (res.data.Status === 'OK') {
          dialog({icon: 'success', text: res.data.Message}).then(() => getPengajuan())
        }else {
          dialog({icon: 'error', text: res.data.Message})
        }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const getNilaiKomit = () => {
    const statusUtama = dataKomit.beasiswaUtama === 'Tidak Mengajukan' ? false : true
    const statusTambahan = dataKomit.beasiswaTambahan === 'Tidak Mengajukan' ? false : true
    Axios.get(CONFIG.BASE_URL + 
      `/api/nilaiKomitmen?userId=${user.userId}&statusKomitmenUtama=${statusUtama}&statusKomitmenUtama=${statusTambahan}`, configAuth)
      .then(res => {
        if (res.data.Status === 'OK') {
          setNilaiKomitmen(res.data.Data)
          setFrom('nilai')
        }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  const handleSubmitNilai = (name) => {
      Axios.put(CONFIG.BASE_URL + 
      `/api/persetujuanKomitmen?userId=${user.userId}&persetujuan=${name}`, {}, configAuth)
      .then(res => {
        if (res.data.Status === 'OK') {
          dialog({icon: 'success', text: res.data.Message}).then(() => getPengajuan())
        }else {
          dialog({icon: 'error', text: res.data.Message})
        }
    }).catch(err => {
      if(err){
        dialog({icon: 'error', text: 'Terjadi Kesalahan pada server'})
      }
    })
  }

  return (
    <div className='container' style={{height: '100%'}}>
      <Spin spinning={loadingContainer} size='large'>
        <PageHeader
          className='site-page-header'
          title={`${user.jalurPenerimaan !== 'Prestasi' ? 'Jalur Tes' : 'Jalur Prestasi'}`}
        />
        <Row gutter={[0, 16]} style={{alignItems: 'stretch'}}>
          <Col span={24}>
            {user && <InfoCard className='body-card-info-siswa' user={user}/>}
          </Col>
          <Col>
          </Col>
          {isOfferPlus ? (
            <Col span={24}>
              <Offer 
                responsive={props.responsive}
                from={from}
                diskon={diskon}
                data={dataFlat}
                dataLOV={LOV}
                disableButton={disableButton}
                komitId={komitId}
                beasiswa={beasiswa}
                fileInput={fileInput}
                fileInputSeni={fileInputSeni}
                fileInputSktm={fileInputSktm}
                fileInputYatim={fileInputYatim}
                fileInputRekamMedis={fileInputRekamMedis}
                fileInputTempatTinggal={fileInputTempatTinggal}
                dataSimulasi={dataSimulasi}
                package={packageSelect}
                nilaiSiswa={nilaiSiswa}
                fileNameKekl={fileNameKekl}
                fileNameSeni={fileNameSeni}
                fileNameYatim={fileNameYatim}
                fileNameMedis={fileNameMedis}
                fileNameTinggal={fileNameTinggal}
                fileNameSktm={fileNameSktm}
                filePathKekl={filePathKekl}
                filePathSeni={filePathSeni}
                filePathYatim={filePathYatim}
                filePathMedis={filePathMedis}
                filePathTinggal={filePathTinggal}
                filePathSktm={filePathSktm}
                loadingUpload={loadingUpload}
                dataKejuaraan={selectKejuaraan}
                fileHandler={fileHandler}
                hanldeBack={() => hanldeBack()}
                openFileBrowser={openFileBrowser}
                deleteUpload={(name) => deleteUpload(name)}
                handleKejuaraan={(name, val) => handleKejuaraan(name, val)}
                handleNext={(name, id) => handleNext(name, id)}
                handlePackage={(name, id) => handlePackage(name, id)}
                handleRadio={(val, name) => handleRadio(val, name)}
                handleShow={(textHead, textBody) => handleShow(textHead, textBody)}
              />
            </Col>
          ) : 
          from === 'afterKomit' ? (
            <Col span={24}>
              <Verifikasi 
                dataKomit={dataKomit} 
                getNilaiKomit={() => getNilaiKomit()}
              />
            </Col>
          ) :
          from === 'nilai' ? (
            <Col span={24}>
              <NilaiKomit 
                responsive={props.responsive}
                nilaiKomitmen={nilaiKomitmen}
                dataKomit={dataKomit} 
                hanldeBack={() => hanldeBack()}
                handleSubmitNilai={(name) => handleSubmitNilai(name)}
              />
            </Col>
          ) : (
            <Col span={24}>
              <Card className='app-card-info-user' style={{height: '100%'}}>
                <Row gutter={[0, 16]}>
                  <Col span={24}>
                    <span className='text-header-finance'>
                      Silahkan pilih 1 dari 3 komitmen UPS yang ada :
                    </span>
                  </Col>
                  <Col span={24}>
                    <div className='divider-finance' />
                  </Col>
                  <Col span={24}>
                    <Row justify='space-around'>
                      <Col xs={24} sm={7} md={5} style={{ marginTop: '20px'}}>
                        <Col span={24}>
                          <Button 
                            block
                            size={'large'}
                            className='btn-finance btn-flat'
                            onClick={() => handleDirect('offer', dataInfo.komitmenFlatOffer && dataInfo.komitmenFlatOffer.komitmenId)}
                          >
                            FLAT OFFER
                          </Button>
                          <UICardFinance 
                            text={'Informasi Flat Offer'}
                            showPopUp={() => handleShow('Informasi Flat Offer', dataInfo.komitmenFlatOffer ? dataInfo.komitmenFlatOffer.komitmenInfo : '')}
                            image={dataInfo.komitmenFlatOffer ? dataInfo.komitmenFlatOffer.imageUrl : ''} 
                          />
                        </Col>
                      </Col>
                      <Col xs={24} sm={7} md={5} style={{ marginTop: '20px'}}>
                        <Col span={24}>
                          <Button 
                            block
                            size={'large'}
                            className='btn-finance btn-plus'
                            onClick={() => handleDirect('plus', dataInfo.komitmenFlatOfferPlus && dataInfo.komitmenFlatOfferPlus.komitmenId)}
                          >
                            FLAT OFFER PLUS
                          </Button>
                          <UICardFinance 
                            text={'Informasi Flat Offer Plus'} 
                            showPopUp={() => handleShow('Informasi Flat Offer Plus', dataInfo.komitmenFlatOfferPlus ? dataInfo.komitmenFlatOfferPlus.komitmenInfo : '')}
                            image={dataInfo.komitmenFlatOfferPlus ? dataInfo.komitmenFlatOfferPlus.imageUrl : ''} 
                          />
                        </Col>
                      </Col>
                      <Col xs={24} sm={7} md={5} style={{ marginTop: '20px'}}>
                        <Col span={24}>
                          <Button 
                            block
                            size={'large'}
                            className='btn-finance btn-nego'
                            onClick={() => handleDirect('nego', dataInfo.komitmenNegosiasi && dataInfo.komitmenNegosiasi.komitmenId)}
                            >
                            NEGOSIASI
                          </Button>
                          <UICardFinance 
                            text={'Informasi Negosiasi'} 
                            showPopUp={() => handleShow('Informasi Negosiasi', dataInfo.komitmenNegosiasi ? dataInfo.komitmenNegosiasi.komitmenInfo : '')}
                            image={dataInfo.komitmenNegosiasi ? dataInfo.komitmenNegosiasi.imageUrl : ''} 
                            />
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Spin>
      <UIModal 
        visible={isVisible}
        title={modalTextHead}
        bodyText={modalTextBody} 
        dataTabel={diskon.seniOlahraga}
        onCancel={() => handleClose()}
        responsive={props.responsive}
      />
    </div>
  )
}

export default withRouter(Finance)