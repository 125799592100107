import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Col, Typography } from 'antd';
import { AppLoading } from '../../../component/loading';
import CardUjianComponent from '../../../component/card-ujian';

const { Text } = Typography;
const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
};

const alfabet = ['A', 'B', 'C', 'D', 'E']

const PilihanGanda = props => {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
      console.log("dataSoal : ",props.dataSoal)
      if(props.actived){
        setData(props.data)
        setLoading(false)
      }
    }, [])

    return(
      <div className="container">
      {isLoading || !data ? (
        <div style={{padding: '5em 0em'}}>
          <AppLoading text="Memuat data siswa"/>  
        </div>
      ) : (
        <>
          <Row style={{borderBottom: '4px solid #ededed', paddingBottom: '1em'}}>
            <Col span={24}>
              <Text style={{fontSize: '1.4em', fontWeight: 'bold', color: '#072a6e'}}>{props.nomor} / {props.length}</Text>
            </Col>
          </Row>
          <Row className='paddingSoal'>
            {
              data && data.image && 
              <Col span={10} style={{marginRight: 10}}>
                <img style={{width: '100%', height: '100%'}}  src={data.image}/> 
              </Col>
            }
              <Col>
                <Text className='textSoal'>{ data.soal}</Text>
              </Col>
          </Row>
          <Row gutter={20}
            align='middle'
            justify='center'
          >
            {
              data && data.pilihan.map((res,index) => {
                  return <CardUjianComponent
                    index={index}
                    value={res.value}
                    keys={res.key}
                    onClick={()=>props.onJawaban(res.key)}
                    selected = {res.key == props.jawaban }
                    isText={res.isText ? true : false}
                 />
              })
            }
          </Row>
        </>
      )}
      </div>
    )
}

export default withRouter(PilihanGanda) 