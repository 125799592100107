import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Button, Col, Typography,Card } from 'antd';
import siswaServices from '../../../service/siswaServices';
import { AppLoading } from '../../../component/loading';
import moment from 'moment'
import { dialog } from '../../../functions/alert';
import Dummy from '../../../dummy';
import { reloadPage } from '../../../functions/reload';

const { Title, Text } = Typography;

const Peraturan = props => {
    const [data, setData] = useState(null)
    const [isMulai, setIsMulai] = useState(false)
    const [time, setTime] = useState(-1)
    const [isLoading, setLoading] = useState(true)
    const [isSubmit, setSubmit] = useState(false)
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
      }
    };
    
    const getData = () => {
      if(sessionStorage.getItem('token')){
        const user = JSON.parse(sessionStorage.getItem('user'));
        siswaServices.getAll('api/getRules?siswaId='+user.userId, config).then(res => {
          const tempData = res.data
          console.log('response rules : ',res)
          mappingDataResponse(tempData)
        }).catch(err => {
          reloadPage(err)
        })
      }
    }

    const mappingDataResponse = (tempData) => {
      if(tempData){
        var isTest = tempData.isTest
        var sekarang  = moment();
        var startAt = tempData.startAt; //res.startAt
        var endAt = tempData.endAt; //res.endAt
        var msStart = moment(moment(startAt),"DD/MM/YYYY HH:mm:ss").diff(moment(sekarang,"DD/MM/YYYY HH:mm:ss"));
        var msEnd = moment(moment(endAt),"DD/MM/YYYY HH:mm:ss").diff(moment(sekarang,"DD/MM/YYYY HH:mm:ss"));
        setData(tempData)
        // console.log('data temp : ',tempData)
        if(msEnd/1000 > 0 && !isTest){
          if(msStart > 0){
            setTime(msStart/1000)
            props.isDuration(msEnd/1000)
          }else{
            setIsMulai(true)
            props.isDuration(msEnd/1000)
          }
        }else{
          props.onFinish()
        }
        setLoading(false)
      }
    }

    const getDataDummy = () => {
      const tempData = Dummy.getRules
      mappingDataResponse(tempData)
    }

    useEffect(() => {
      getData()
      // getDataDummy()
    }, [])

    useEffect(() => {
      console.log('Pertanyaan')
      if(time >= 0){
        const intervalId = setInterval(() => {
          setTime(time-1)
          if(time < 1){
            setIsMulai(true)
          }
        },1000)
        return () => clearInterval(intervalId);
      }
      // getDataDummy()
    }, [time])

    const gotoSoal = () => {
      props.onMulai()
      // setSubmit(true)
    }

    return(
      <div className="container">
      {isLoading ? (
        <div style={{padding: '5em 0em'}}>
          <AppLoading text="Memuat data siswa"/>  
        </div>
      ) : (
        <>
        <Col xs={24} md={24} lg={12}>
          <Card className="app-card" style={{height: '100%'}}>
            <Row style={{borderBottom: '1px solid #d9d9d9'}}>
            <Col span={16}><Title level={4}>Peraturan Screen</Title></Col>
            </Row>
              {
                data && data.rules &&
                data.rules.map(res => {
                return (
                  <Row gutter={20} style={{textAlign: 'center', alignItems: 'center', margin: 10}}>
                    <Col span={1}>
                      <div style={{borderRadius: 20, borderWidth: 10, width:10, height:10, backgroundColor: '#072A6F'}}/>
                    </Col>
                    <Col>
                      <Text style={{fontSize: 18}}>{res.rule}</Text>
                    </Col>
                  </Row>
                )})
              }
              <Button style={{textAlign: 'center', alignItems: 'center', margin: 20}} disabled={!isMulai} loading={isSubmit} onClick={gotoSoal}>Mulai</Button>
            </Card>
          </Col>
        </>
      )}

      <div style={{color: '#f0f2f5'}}>{ 'duration mulai' + time}</div>
      </div>
    )
}

export default withRouter(Peraturan) 