/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom'
import { Row, Button, Col, Typography,Card, Progress } from 'antd';
import siswaServices from '../../../service/siswaServices';
import { AppLoading } from '../../../component/loading';
import { dialog, confirmDialog } from '../../../functions/alert';
import PilihanGanda from './pilihan-ganda';
import Essai from './essai';
import { update } from 'lodash';
import CONFIG from '../../../service/config'
import { GlobalFunction } from '../../../global/function';
import appCache from '../../../service/cache';

const { Text } = Typography;
const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
};
const Pertanyaan = props => {
  const [data, setData] = useState([])
  const [soalActived, setSoalActived] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const [timer, setTimer] = useState();
  const [time, setTime] = useState({hours: 0, minutes: 0, seconds: 0});

  useEffect(() => {
    const {data, startTime, duration,endTime} = props.dataSoal;
    let ujianCache = appCache.get('ujianAkademik');
    setData(ujianCache.jawaban ? ujianCache.jawaban : data);
    updateTimer(endTime, duration);

    setLoading(false)
  }, []);

  const updateTimer = (finishTime, duration) => {
    clearInterval(timer)
    const endTime = finishTime;
    let timeLeft = endTime - Date.now();
    console.log({endTime, duration})
    if (timeLeft <= 0) {
      clearInterval(timer)
      if(!isSubmit){
        setIsSubmit(true)
        handleTimeout()
      }
    } else {
      setTime(GlobalFunction.timeConversion(endTime - Date.now()));
      const newTimer = setInterval(() => {
        timeLeft = endTime - Date.now();
        if (timeLeft <= 0) {
          clearInterval(timer)
          clearInterval(newTimer) //hotfix big issue
          if(!isSubmit){
            setIsSubmit(true)
            handleTimeout();
          }
        }
        setTime(GlobalFunction.timeConversion(timeLeft));
      }, 1000)
      setTimer(newTimer);
    }
  }

  const handleTimeout = () => {
    clearInterval(timer);
    submitJawaban(true)
    // props.onFinish();
  }

  const updateListData = (event) => {
    let newData = [...data];
    let ujianCache = appCache.get('ujianAkademik');
    newData[soalActived].jawaban = event;
    ujianCache = {...ujianCache, jawaban: newData}
    console.log(newData)
    setData(newData)
    appCache.set('ujianAkademik', ujianCache)
  }

  const nextSoal = () => {
    console.log("Soal Actived : ",soalActived)
    if(data.length != soalActived+1){
      var active = soalActived+1;
      setSoalActived(active)
    }
  }
  const prevSoal = () => {
    console.log("Soal Actived : ",soalActived)
    if(data.length > 1){
      var active = soalActived-1;
      setSoalActived(active)
    }
  }

  const submitJawaban = (isTimeOut) => {
    console.log('props.isFinished : ', props.isFinished)
    const ujianCache = appCache.get('ujianAkademik');
    let dataTemp = isTimeOut ? ujianCache.jawaban : data
    var body = mappingJawaban(dataTemp ? dataTemp : [])
    var siswaId = sessionStorage.getItem('siswaId')
    console.log('data kirim : ',siswaId,body)
    siswaServices.create(`api/ujianAkademik/postJawaban?siswaId=${siswaId}&matpelId=${ujianCache.subjectId}`,body,config).then(res=>{
      dialog({icon: 'success', text: 'Selamat Anda Telah Menyelesaikan Ujian Akademik'}).then(()=>{
        props.onFinish()
      })
    }).catch(err =>{
      if(err){
        setIsSubmit(false)
        dialog({icon:'error', text:'Terjadi Kesalahan Pada Server, coba lagi'}).then(res => {
          if(res){
            submitJawaban(true);
          }
        })
      }
    })
  }

  const submitSelesai = () => {
    if (isAllAnswered()) {
      confirmDialog({text: 'Apakah Anda yakin ingin menyelesaikan ujian ?'}).then(() => {
        submitJawaban();
      }).catch(_ => { })
    } else {
      dialog({title: 'Jawaban belum lengkap', text: 'Anda belum menjawab semua pertanyaan, harap lengkapi semua jawaban terlebih dahulu!', icon: 'info', })
    }
  }

  const isAllAnswered = () => {
    const jawaban = data.filter(e => !!e.jawaban);
    return jawaban.length === data.length;
  }

  const mappingJawaban = (datas) => {
    let newData = []
    datas.map(res =>{
      newData.push({
        type: res.type,
        soalId: res.soalId,
        jawaban: res.jawaban
      })
    })
    return newData
  }

  return(
    <div className="container">
    {isLoading || !data ? (
      <div style={{padding: '5em 0em'}}>
        <AppLoading text="Memuat data siswa"/>  
      </div>
    ) : (
      <Row gutter={[0, 20]} style={{justifyContent: 'end'}}>
        <Col span={24} lg={8} md={12}>
          {/* {JSON.stringify(time, '', 2)} */}
          <Card  className="app-card">
            <div>Waktu tersisa : </div>
            <div style={{fontSize: '1.2em', fontWeight: 'bold', color: '#072a6f'}}>
              {/* {JSON.stringify(timer, '', 2)} */}
              {(time.hours < 0 ? 0 : + time.hours) +' Jam '}
              {(time.minutes < 0 ? 0 : time.minutes) + ' Menit '}
              {(time.seconds < 0 ? 0 : time.seconds) + ' Detik '}
            </div>
          </Card>
        </Col>
        <Col span={24}>
          <Card className="app-card" style={{height: '100%'}}>
            {
              data && data.map((res, index)=>{
                if(soalActived == index){
                  if(res.type == 1){
                    return <PilihanGanda 
                    key={index}
                    nomor={index+1}
                    data={res} 
                    length={data.length} 
                    actived={soalActived == index}
                    onNextSoal={nextSoal}
                    onJawaban={updateListData}
                    jawaban={res.jawaban}
                    />
                  } else {
                    return <Essai 
                    key={index}
                    nomor={index+1}
                    data={res} 
                    length={data.length} 
                    actived={soalActived == index}
                    onNextSoal={nextSoal}
                    onJawaban={updateListData}
                    jawaban={res.jawaban}
                    />
                  }
                }
              })
            }
          </Card>
        </Col>
        <Col span={24}>
          <pre>{JSON.stringify()}</pre>
        </Col>
        <Col style={{textAlign: 'end'}}>
          <Button style={{marginRight: '1em'}} onClick={prevSoal} disabled={soalActived == 0}> {'< Sebelumnya'} </Button>
          <Button style={{marginRight: '1em'}} onClick={nextSoal} disabled={data.length == soalActived+1 }> {'Berikutnya >'} </Button>
          <Button style={{marginRight: '1em'}} onClick={submitSelesai} disabled={data.length != soalActived+1 } >{'Selesai'} </Button>
        </Col>
      {/* <Row>
        <Text>{JSON.stringify(mappingJawaban(data))}</Text>
      </Row> */}
      </Row>
    )}
    </div>
  )
}

export default withRouter(Pertanyaan) 