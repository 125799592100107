/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Modal, Typography, Button, Tabs, Card} from 'antd';
import {CheckCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import axios from 'axios';
import  CONFIG  from '../../../../service/config';
import {confirmDialog, dialog} from '../../../../functions/alert';
import dummyDataAkademik from './dummy-data';
import AppCache from '../../../../service/cache';
import Text from 'antd/lib/typography/Text';
import {NilaiPengetahuan} from './nilai';
import {Ekstrakurikuler} from './ekstrakulikuler';
import {PenilaianPribadi} from './penilaian-pribadi';
import {Kehadiran} from './kehadiran';
import {compare} from '../../../../functions/object';
import { RedStar } from '../../../../component/common';

const {Title} = Typography;
const {TabPane} = Tabs;

const tabs = [
  {
    key: 'nilai',
    title: 'Nilai Pengetahuan',
    isRequired: true
  },
  {
    key: 'ekskul',
    title: 'Ekstrakurikuler',
    isRequired: false
  },
  {
    key: 'penilaianSikap',
    title: 'Penilaian Pribadi',
    isRequired: true
  },
  {
    key: 'kehadiran',
    title: 'Kehadiran',
    isRequired: true
  },
  // {
  //   key: 'prestasi',
  //   title: 'Nilai Pengetahuan',
  //   isRequired: false
  // }
]

const config = {
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`
  }
}

export function saveDataAkademik(data) {
  return new Promise((resolve, reject) => {
    const body = { ...data };
    const siswaId = sessionStorage.getItem('siswaId')
    axios.post(CONFIG.BASE_URL + '/api/calonSiswa/dataAkademik/'+siswaId, body, config).then(res => {
      resolve(res);
    }).catch(err => reject(err) );
  })
} 

export function revisiDataAkademik(data,isRevisiOther) {
  return new Promise((resolve, reject) => {
    const body = { nilai: data.nilai, kehadiran: data.kehadiran };
    const siswaId = sessionStorage.getItem('siswaId')
    axios.put(CONFIG.BASE_URL + '/api/calonSiswa/revisiDataAkademik/'+siswaId+'?isRevisiOther='+isRevisiOther, body, config).then(res => {
      resolve(res);
    }).catch(err => reject(err) );
    
  })
}

export function saveBerkasAkademik(data) {
  return new Promise((resolve, reject) => {
    const siswaId = sessionStorage.getItem('siswaId')
    axios.post(CONFIG.BASE_URL + '/api/calonSiswa/uploadPrestasi/dataBerkas/'+siswaId, data, config).then(res => {
      resolve(res);
    }).catch(err => reject(err) );
  })
}

function loadDataAkademik(userId) {
  return new Promise((resolve, reject) => {
    axios.get(CONFIG.BASE_URL + '/api/isiDataAkademik/nilai?siswaId=' + userId).then(res => {
      resolve(res);
    }).catch(err => reject(err))
  })
}


function mapDataPrestasi(data) {
  let newData = {
    prestasi: [
      ...data.prestasiAkademik,
      ...data.prestasiNonAkademik,
    ]
  }
  newData.prestasi = newData.prestasi.map(e => {
    const prestasi = {...e};
    delete prestasi.file;
    return prestasi;
  });
  return newData;
}

function mapLoadedData({nilai, penilaianSikap, prestasi, ekskul, kehadiran, catatan, ...rawData}, isRevisi) {
  let newData = {};
  newData.nilai = nilai.length > 0 ? {
    value: nilai.map(({...val}) => ({
      ...val,
      matpelId: { matpelId: val.matpelId },
    }))
  } : {};
  if (!!isRevisi) { 
    newData.nilai.valid = ((nilai).filter(e => !e.verifikasi).length === 0) || false;
  }
  newData.prestasi = {value: prestasi || []};
  newData.ekskul = {value: ekskul || []};
  newData.kehadiran = {value: kehadiran || []};
  newData.kehadiran = nilai.length > 0 ? {
    value: kehadiran.map(({...val}) => ({
      ...val,
      kehadiranId: { kehadiranId: val.kehadiranId },
    }))
  } : {};
  if (!!isRevisi) { 
    newData.kehadiran.valid = ((kehadiran).filter(e => !e.verifikasi).length === 0) || false;
  }
  newData.catatan = catatan
  return newData;
}

const MemoizedNilaiPengetahuan = React.memo(NilaiPengetahuan);
const MemoizedEkstrakurikuler = React.memo(Ekstrakurikuler);
const MemoizedPenilaianSikap = React.memo(PenilaianPribadi);
const MemoizedKehadiran = React.memo(Kehadiran);
// const MemoizedPrestasi = React.memo(Prestasi);

export function ModalDataAkademik(props) {
  // const [masterData, setDataList] = useState({});
  const { masterData = {}} = props;
  const [formValue, setFormValue] = useState({});
  const [initValue, setInitValue] = useState({
    nilai: {value: [], valid: false},
    ekskul: '',
    penilaianSikap: {value: [], valid: false},
    kehadiran: {value: [], valid: false},
    prestasi: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!props.visible) {
      loadData();
    }
  }, [props.isRead, props.isRevisi, props.visible])

  const loadData = async () => {
    // const newValue = {...initValue};
    let savedValue = {...initValue};
    if (props.isRead || props.isRevisi) {
      try {
        const siswaId = sessionStorage.getItem('siswaId');
        const dataAkademikRes = await loadDataAkademik(siswaId);
        delete savedValue.penilaianSikap.valid
        savedValue = {
          ...savedValue,
          ...mapLoadedData(dataAkademikRes.data, props.isRevisi)
        };
      } catch (err) {console.log('Failed to load Data Akademik!', err)}
    } else {
      savedValue = {
        ...savedValue,
        ...AppCache.get('dataAkademik')
      }
    }
    
    setFormValue(savedValue);
    setInitValue(savedValue);
  }

  const saveData = async () => {
    setIsLoading(true);
    let dataAkademikBody = {};
    const prestasiValue = {
      prestasiAkademik: formValue.prestasi.prestasiAkademik || [],
      prestasiNonAkademik: formValue.prestasi.prestasiNonAkademik || [],
    }
    
    dataAkademikBody.nilai = formValue.nilai.value || []; // => nilai:{value:[]}
    dataAkademikBody.ekskul = formValue.ekskul.value || [];
    dataAkademikBody.penilaianSikap = formValue.penilaianSikap.value || [];
    dataAkademikBody.kehadiran = formValue.kehadiran.value || [];

    dataAkademikBody = {
      ...dataAkademikBody,
      ...mapDataPrestasi(prestasiValue)
    };
    const fileAkademikBody = {
      berkasPrestasiAkademik: prestasiValue.prestasiAkademik.map(e => ({
        uploadBerkas: e.file.fileName
      })),
      berkasPrestasiNonAkademik: prestasiValue.prestasiNonAkademik.map(e => ({
        uploadBerkas: e.file.fileName
      }))
    };

    try{
      if (props.isRevisi) {
        await revisiDataAkademik(dataAkademikBody, props.isRevisiOther);
        await dialog({ title: 'Revisi Data Akademik berhasil disimpan', text: 'Data akan segera di verifikasi', icon: 'success' })
      } else {
        await saveDataAkademik(dataAkademikBody);
        await saveBerkasAkademik(fileAkademikBody);
        await dialog({ title: 'Data Akademik berhasil disimpan', icon: 'success' })
      }
      props.onFinish()
    } catch(err) {
      dialog({ title: 'Gagal menyimpan Data Akademik', text: err.response ? err.response.data.message : err.message, icon: 'error' })
    }
    setIsLoading(false);
  }

  const autofill = () => {
    const autofillData = {
      ...formValue,
      ...dummyDataAkademik
    }
    setFormValue(autofillData);
    setInitValue(autofillData);
  }
  
  const handleChange = (key, value) => {
    if (compare(value, formValue[key])) {
      return
    }
    let newFormValue = { ...formValue, };
    newFormValue[key] = value
    setFormValue(newFormValue);
    AppCache.set('dataAkademik', newFormValue);
  }

  const validatedForm = Object.keys(formValue).filter(key => {
    const value = formValue[key] || {};
    if(typeof value === 'object'){ return 'valid' in value } 
    else { return false }
  }).map(key => formValue[key].valid)

  const TabTitle = ({title, isRequired, key}) => {
    const {valid} = formValue[key] || {};
    return (
      <span>
        {valid !== undefined && !!valid && <CheckCircleOutlined style={{color: '#27ae60'}} title="Form sudah sesuai" />} 
        {title} {isRequired && <RedStar title="Form ini wajib diisi" />}
      </span>
    )
  }

  const percentage = Math.floor((validatedForm.filter(e => !!e).length / validatedForm.length) * 100) || 0

  return(
    <Modal
      destroyOnClose={true}
      visible={props.visible}
      className="form-modal data-akademik-modal"
      footer={null}
      closable={true}
      onCancel={e => props.onClose(percentage)}
      centered
      width="1000px"
    >
      <Title level={3}>
        {props.isRevisi && 'Revisi'} Data Akademik ({percentage}%)
        {/* {JSON.stringify(validatedForm)} */}
      </Title>
      <Tabs type="card" style={{marginTop: 25}}>
        <TabPane forceRender={true} tab={TabTitle(tabs[0])} key="0">
          <MemoizedNilaiPengetahuan initValue={initValue['nilai']} isRevisi={props.isRevisi} onChange={(value) => handleChange('nilai', value)} listMatpel={masterData.listMatpel}/>
        </TabPane>
        {!props.isRevisi && (
          <>
            <TabPane forceRender={true} tab={TabTitle(tabs[1])} key="1">
              <MemoizedEkstrakurikuler initValue={initValue['ekskul']} onChange={(value) => handleChange('ekskul', value)} listEkskul={masterData.listEkskul}/>
            </TabPane>
            <TabPane forceRender={true} tab={TabTitle(tabs[2])} key="2">
              <MemoizedPenilaianSikap initValue={initValue['penilaianSikap']} onChange={(value) => handleChange('penilaianSikap', value)} listPenilaian={masterData.listPenilaian}/>
            </TabPane>
          </>
        )}
        <TabPane forceRender={true} tab={TabTitle(tabs[3])} key="3">
          <MemoizedKehadiran initValue={initValue['kehadiran']} isRevisi={props.isRevisi} onChange={(value) => handleChange('kehadiran', value)} listKehadiran={masterData.listKehadiran}/>
        </TabPane>
      </Tabs>
      {(props.isRevisi && (
        <Card className="app-card paper">
          <Text strong>Catatan</Text>
          <div><Text>{initValue.catatan || 'Tidak ada catatan ...'}</Text></div>
        </Card>
      ))}
      <div className="steps-action" style={{paddingLeft: '1.25em', paddingRight: '1.25em'}}>
        {
          CONFIG.isServer !== 'Production' && percentage < 100  && <Button className="app-btn" style={{marginRight: '1em'}} onClick={autofill}>
          AUTO-FILL
        </Button>}
        { !masterData.isRead &&
          <Button disabled={percentage < 100 || isLoading} className="app-btn primary" onClick={() => {
            confirmDialog({title: 'Simpan Data Akademik?', text: 'Pastikan data yang Anda masukan sudah sesuai.'}).then(_ => {
              saveData();
            }).catch(_ => {})
          }}>
            {isLoading && <LoadingOutlined />}
            Simpan
          </Button>
        }
      </div>
      {/* <pre>{JSON.stringify(formValue, '', 2)}</pre> */}
    </Modal>
  )
    
}
      
export default ModalDataAkademik
