import getRules from "./getRules";
import getHasil from "./getHasil";
import getListPertanyaan from "./getListPertanyaan";
import getDataAuth from "./getAuth";

export default class Dummy {
  static getRules = getRules
  static getHasil = getHasil
  static getListPertanyaan = getListPertanyaan
  static getDataAuth = getDataAuth
}